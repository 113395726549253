import MicrosoftSQLConnection from "./MicrosoftSQLConnection.component";
import MYSQLConnection from "./MYSQL.component";
import OracleConnection from "./OracleConnection.component";
import PostgreSQLConnection from "./PostgreSQLConnection";

const InputsDatabaseConnections = (props) => {

 if(props.selectedConnectionstring && (props.selectedConnectionstring.Database_Type === "Microsoft SQL"|| props.selectedConnectionstring.Database_Type === "Microsoft SQL Local"))
 return <MicrosoftSQLConnection selectedConnectionstring = {props.selectedConnectionstring}     submitEditedDatabaseConnection = {props.submitEditedDatabaseConnection} />

else  if (props.selectedConnectionstring && props.selectedConnectionstring.Database_Type === "Oracle")
return <OracleConnection selectedConnectionstring = {props.selectedConnectionstring} submitEditedDatabaseConnection = {props.submitEditedDatabaseConnection}/>

else  if (props.selectedConnectionstring && props.selectedConnectionstring.Database_Type === "MYSQL")
return <MYSQLConnection selectedConnectionstring = {props.selectedConnectionstring} submitEditedDatabaseConnection = {props.submitEditedDatabaseConnection}/>

else  if (props.selectedConnectionstring && props.selectedConnectionstring.Database_Type === "PostgreSQL")
return <PostgreSQLConnection selectedConnectionstring = {props.selectedConnectionstring} submitEditedDatabaseConnection = {props.submitEditedDatabaseConnection}/>

 return (
  <>

  </>
 )
};

export default InputsDatabaseConnections;
