/**
 * @author Alexandros Andreou
 * @version 0.0.1
 * Navigation Component
 */

import MenuItem from "../Navigation/MenuItem.component";
import logoPng from '../../img/mainLogo_blackFont.png'
const LandingPageNavigation = (props) => {
  const MenuObj = [
    {
      order: 0,
      title: "Home",
      icon: "bx bx-home-circle",
      to: "/",
    },
    {
      order: 1,
      title: "Log In",
      icon: "bx bx-log-in",
      to: "/logIn",
    },
    {
      order: 2,
      title: "About Us",
      icon: "bx bx-info-circle",
      to: "/aboutUs",
    },
    {
      order: 3,
      title: "Pricing",
      icon: "bx bx-wallet",
      to: "/pricing",
    },
  ];
  return (
    <div className="mainLogoLogoutArea">
      <div className="mainLogoLogout">
        <a href="/" class="">
        <img src={logoPng} alt="" width="209" height="156" />
        </a>
      </div>
      <div style={{
    textAlign: 'center'}}>
        {MenuObj.map((menuItem) => {
          return (
            <MenuItem
              key={menuItem.order}
              label={menuItem.title}
              icon={menuItem.icon}
              to={menuItem.to}
              extraClass='logoutMenu'
              userLoggedIn={props.userLoggedIn}
            />
          );
        })}
      </div>
    </div>
  );
};

export default LandingPageNavigation;
