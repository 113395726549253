import { useState, createContext } from "react";

export const BussinesRulesContext = createContext(null);
export const BussinesRulesContextProvider = (props) => {
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [businessRulesItems, setBussinesRules] = useState([]);
  const [versionToDelete, setVersionToDelete] = useState(0);
  const [variablesOptions, setVariableOptions] = useState([
    { Column_Name: "alex" },
  ]);
  const [dataQualityRules, setDataQualityRules] = useState([]);
  const [dataQualityRuleToDelete, setDataQualityRuleToDelete] = useState(0);
  const [dataDomains, setdataDomains] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [businessMetadata, setBusinessMetadata] = useState([]);
  const [dataDimensionsResults, setDataDimensionsResults] = useState([]);
  const [dataDomainsResults, setDataDomainsResults] = useState([]);
  const [businessRuleVersions, setBusinessRuleVersions] = useState([]);
  const [refreshOnProgress, setRefreshOnProgress] = useState(false);

  const refreshList = (deleteBussinesRuleWithoutVersions) => {
    setRefreshOnProgress(true);
    const fetchReq1 = fetch(`/businessRules`).then((res) => res.json());

    const fetchReq2 = fetch(`/businessRuleVersions`).then((res) => res.json());

    const fetchReq3 = fetch(`/businessRuleVersionTags`).then((res) =>
      res.json()
    );

    const fetchReq4 = fetch(`/businessRuleVersionVariables`).then((res) =>
      res.json()
    );

    const fetchReq5 = fetch(`/dataQualityRules`).then((res) => res.json());

    const fetchReq6 = fetch(`/dataDomains`).then((res) => res.json());

    const fetchReq7 = fetch(`/department`).then((res) => res.json());

    const fetchReq8 = fetch(`/employee`).then((res) => res.json());

    const fetchReq9 = fetch(`/businessMetadata`).then((res) => res.json());

    const fetchReq10 = fetch(`/dataDimensionsResults`).then((res) =>
      res.json()
    );

    const fetchReq11 = fetch(`/dataDomainsResults`).then((res) => res.json());

    const allData = Promise.all([
      fetchReq1,
      fetchReq2,
      fetchReq3,
      fetchReq4,
      fetchReq5,
      fetchReq6,
      fetchReq7,
      fetchReq8,
      fetchReq9,
      fetchReq10,
      fetchReq11,
    ]);
    allData.then((res) => {
      var [
        businessRules,
        businessRuleVersions,
        businessRuleVersionTags,
        businessRuleVersionVariables,
        dataQualityRules,
        dataDomains,
        departments,
        employees,
        businessMetadata,
        dataDimensionsResults,
        dataDomainsResults,
      ] = res;

      let _businessRules = businessRules;
      let _businessRuleVersions = [...businessRuleVersions];
      for (let businessRuleVersionTag of businessRuleVersionTags) {
        for (let businessRuleVersion of _businessRuleVersions) {
          businessRuleVersion.Tags = [];
          if (
            businessRuleVersionTag.BusinessTag_Rule_Version_id ===
            businessRuleVersion.Id
          ) {
            businessRuleVersion.Tags.push(businessRuleVersionTag);
          }
        }
      }

      for (let businessRuleVersion of _businessRuleVersions) {
        businessRuleVersion.Variables = [];
        for (let businessRuleVersionVariable of businessRuleVersionVariables) {
          if (
            businessRuleVersionVariable.BusinessVariable_Rule_Version_id ===
            businessRuleVersion.Id
          ) {
            businessRuleVersion.Variables.push(businessRuleVersionVariable);
          }
        }
      }

      for (let bussineRule of _businessRules) {
        let bussineRuleId = bussineRule.Id;
        bussineRule.Versions = [];
        for (let businessRuleVersion of _businessRuleVersions) {
          if (bussineRuleId === businessRuleVersion.Business_Rule_id)
            bussineRule.Versions.push(businessRuleVersion);
        }
      }
      setBussinesRules(_businessRules);
      if (deleteBussinesRuleWithoutVersions) deleteBusinessRule(_businessRules);
      setDataQualityRules(dataQualityRules);
      setdataDomains(dataDomains);
      setDepartments(departments);
      setEmployees(employees);
      setBusinessMetadata(businessMetadata);
      setDataDimensionsResults(dataDimensionsResults);
      setDataDomainsResults(dataDomainsResults);
      setBusinessRuleVersions(businessRuleVersions);
      setRefreshOnProgress(false);
    });
  };

  const deleteBusinessRule = (_businessRulesItems) => {
    for (let businessRulesItem of _businessRulesItems) {
      if (!businessRulesItem.Versions?.length) {
        fetch("/businessRules/" + businessRulesItem.Id, {
          method: "DELETE",
          header: {
            Accept: "application/json",
            "Content-Type": "applciation/json",
          },
        }).then(() => {
          refreshList();
        });
      }
    }
  };

  const deleteVersion = () => {
    setConfirmationModal(false);
    fetch("/businessRuleVersions/" + versionToDelete, {
      method: "DELETE",
      header: {
        Accept: "application/json",
        "Content-Type": "applciation/json",
      },
    }).then(() => {
      refreshList(true);
    });
  };

  const onDeleteDataQuality = () => {
    setConfirmationModal(false);
    fetch("/dataQualityRules/" + dataQualityRuleToDelete, {
      method: "DELETE",
      header: {
        Accept: "application/json",
        "Content-Type": "applciation/json",
      },
    }).then(() => {
      refreshList(true);
    });
  };

  return (
    <BussinesRulesContext.Provider
      value={{
        businessRulesItems,
        setBussinesRules,
        refreshList,
        deleteBusinessRule,
        deleteVersion,
        confirmationModal,
        setConfirmationModal,
        versionToDelete,
        setVersionToDelete,
        variablesOptions,
        setVariableOptions,
        dataQualityRules,
        setDataQualityRules,
        setDataQualityRuleToDelete,
        dataDomains,
        onDeleteDataQuality,
        departments,
        employees,
        businessMetadata,
        dataDimensionsResults,
        dataDomainsResults,
        businessRuleVersions,
        refreshOnProgress,
      }}
    >
      {props.children}
    </BussinesRulesContext.Provider>
  );
};
