import OverviewRow from "../OverviewComponent/OverviewRow.component";
import NumericalGraphAMD from "../VariableGraphs/NumericalGraph.component";
const NumericType = (props) => {

  const { variableData, overviewData } = props;
  if (!variableData) {
    return (
      <>
        <div></div>
      </>
    );
  }
  // let variableStats;
  // if (props.statsData) {
  //   variableStats = props.statsData.filter(
  //     (x) => x.Variable_id === props.variableData.Id
  //   );
  // }

  let distinctPercantage = (
    (variableData.Num_Distinct / overviewData.Num_observations) *
    100
  ).toFixed(2);
  distinctPercantage = distinctPercantage + "%";

  let missingPercantage = (
    (variableData.Num_Missing / overviewData.Num_observations) *
    100
  ).toFixed(2);
  missingPercantage = missingPercantage + "%";

  let totalSizeMemory = variableData.Total_size_memory / 1000 + "KB";

  return (
    <div
      className="row"
      style={{ padding: "20px", border: "1px solid #D9DEE3" }}
    >
      <div className="col-12 col-md-2" style={{ padding: "5px" }}>
        <div className="variableTitle">
          <span>{variableData.Variable_Name}</span>
        </div>
        <div className="ccs" style={{ marginBottom: "15px" }}>
          {variableData.Type}
        </div>
        <div>
          {distinctPercantage === "100.00%" && (
            <span className="characteristics">UNIQUE</span>
          )}
        </div>
      </div>

      <div className="col-md-6 col-12" style={{ padding: "5px" }}>
        <OverviewRow
          isRed={true}
          title="Distinct"
          value={variableData.Num_Distinct}
        />
        <OverviewRow
          isRed={true}
          title="Distinct (%)"
          value={distinctPercantage}
        />
        <OverviewRow
          isRed={false}
          title="Missing"
          value={variableData.Num_Missing}
        />
        <OverviewRow
          isRed={false}
          title="Missing (%)"
          value={missingPercantage}
        />
        <OverviewRow
          isRed={false}
          title="Memory Size"
          value={totalSizeMemory}
        />
      </div>
      <div className="col-12 col-md-4" style={{ padding: "5px" }}>
        <NumericalGraphAMD
          graphId={variableData.Variable_Name.replace(/[^a-zA-Z ]/g, "")}
          variableStats={variableData.VariablesStats}
        />
      </div>
    </div>
  );
};

export default NumericType;
