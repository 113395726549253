import React from "react";

const LoadingSpinner = () => {
  return (
    <div id="BackDropModalLayout">
      <div class="spinner-inputdata">
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  );
};

export default LoadingSpinner;
