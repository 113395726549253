/**
 * @author Alexandros Andreou
 * @version 0.0.1
 * Deparments Page
 */

import { useState, useEffect } from "react";
import Header from "../../Header/Header.component";
import DataDomainsResults from "./DataDomainsResults.component";
import DataDimensionsResults from "./DimensionsResults.component";
import { useContext } from "react";
import { BussinesRulesContext } from "../../BussinesRulesContext/BussinesRulesContext.component";
import ConfirmationModal from "../../Modal/Confirmation.modal";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import LoadingSpinner from "../../isLoadingSpinner/LoadingSpinner";

const Dashboard = (props) => {
  
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    if (!bussinesCtx.dataDimensionsResults?.length) {
      bussinesCtx.refreshList();
    }
    let _selectedTab = "";
    if (location.state) {
      _selectedTab = location.state.activeTabLoc;
      setActiveTab(_selectedTab);
    }
  }, []);
  const bussinesCtx = useContext(BussinesRulesContext);
  const navigate = useNavigate();
  const location = useLocation();

  const [firstTimeComponentLoaded, setfirstTimeComponentLoaded] =
    useState(true);
  const [activeTab, setActiveTab] = useState("DataDomainsResults");

  const [
    confirmationModalImportDataDimensions,
    setConfirmationModalImportDataDimensions,
  ] = useState(false);

  const [
    confirmationModalImportDataDomains,
    setConfirmationModalImportDataDomains,
  ] = useState(false);

  useEffect(() => {
    if (
      firstTimeComponentLoaded &&
      !bussinesCtx.refreshOnProgress &&
      bussinesCtx.dataDimensionsResults?.length == 0
    ) {
      refreshGraph(activeTab);
      setfirstTimeComponentLoaded(false);
    }
    setLoading(false)
  }, [bussinesCtx.dataDimensionsResults, bussinesCtx.dataDimensionsResults]);

  const refreshGraph = (activeTab) => {
    setTimeout(() => {
      navigate("/info", {});
    }, 5);
    setTimeout(() => {
      navigate("/dashboard", {
        state: { activeTabLoc: activeTab },
      });
    }, 11);
  };

  const recalculateDataDimensions = () => {
    setLoading(true)
    setConfirmationModalImportDataDimensions(false);
    setfirstTimeComponentLoaded(false)
    fetch("/calculateDataQualityDataDimension")
      .then((response) => {})
      .then(() => {
        bussinesCtx.refreshList();
      });
  };

  const recalculateDataDomains = () => {
    setLoading(true)
    setConfirmationModalImportDataDomains(false);
    setfirstTimeComponentLoaded(false)
    fetch("/calculateDataQualityDataDomain")
    .then((response) => {})
    .then(() => {
      bussinesCtx.refreshList();
    });
  };

  if (firstTimeComponentLoaded && !bussinesCtx.dataDimensionsResults?.length)
    return <div>loading...</div>;

  return (
    <>
      <Header title={'Dashboard'} />

      <div className="container-xxl flex-grow-1 container-p-y">
        <div className="row">
          <div className="col-lg-12 mb-12 order-0">
            <div class="card">
              <div class="table-responsive text-nowrap layoutPadding">
                <div
                  className="row"
                  style={{ padding: "20px", border: "1px solid #D9DEE3" }}
                >
                  <div
                    className="col-12"
                    style={{ padding: "0px", marginBottom: "13px" }}
                  >
                    <span
                      className={
                        activeTab === "DataDomainsResults"
                          ? "tabButtonActive"
                          : "tabButton"
                      }
                      onClick={() => {
                        setActiveTab("DataDomainsResults");
                      }}
                    >
                      Data Domains
                    </span>

                    <span
                      className={
                        activeTab === "DimensionsResults"
                          ? "tabButtonActive"
                          : "tabButton"
                      }
                      onClick={() => {
                        setActiveTab("DimensionsResults");
                      }}
                    >
                      Dimensions
                    </span>
                  </div>

                  {activeTab === "DataDomainsResults" && (
                    <DataDomainsResults
                      bussinesCtx={bussinesCtx}
                      setConfirmationModalImportDataDomains={
                        setConfirmationModalImportDataDomains
                      }
                      refreshGraph={refreshGraph}
                      dashboardMainPagefirstTimeComponentLoaded={
                        firstTimeComponentLoaded
                      }
                    />
                  )}
                  {activeTab === "DimensionsResults" && (
                    <DataDimensionsResults
                      bussinesCtx={bussinesCtx}
                      setConfirmationModalImportDataDimensions={
                        setConfirmationModalImportDataDimensions
                      }
                      refreshGraph={refreshGraph}
                      dashboardMainPagefirstTimeComponentLoaded={
                        firstTimeComponentLoaded
                      }
                    />
                  )}
                  {confirmationModalImportDataDomains && (
                    <ConfirmationModal
                      confirmationModalMessage="Are you sure you wanna overwrite Data Domains data?"
                      onConfirm={recalculateDataDomains}
                      onClose={() => {
                        setConfirmationModalImportDataDomains(false);
                      }}
                    />
                  )}
                  {confirmationModalImportDataDimensions && (
                    <ConfirmationModal
                      confirmationModalMessage="Are you sure you wanna overwrite Data Dimensions data?"
                      onConfirm={recalculateDataDimensions}
                      onClose={() => {
                        setConfirmationModalImportDataDimensions(false);
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading && <LoadingSpinner />}
    </>
  );
};

export default Dashboard;
