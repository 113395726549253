import { version } from "react";
import { Link, useNavigate } from "react-router-dom";
const BussinesRuleVersion = (props) => {
  const { versionData } = props;
  const navigate = useNavigate();
  return (
    <div class="row padding20 borderBottom">    
      <div class="col-12 col-md-6 col-xl-8">
        <div class="row">
          <div class="col-12 marginLeft25">
            <div>
              <i
                class={`bx bxs-circle currentVersionIcon ${
                  versionData.Status === 'Active' ? "activeVersion" : versionData.Status === 'Inactive' ? "inactiveVersion" :  "disabledVersion"
                }`}
              ></i>

              <span class="versionTitle">{`Version ${versionData.Id}`}</span>
            </div>
            <span class="lastUpdated">{`Last updated: ${versionData.Last_Updated}`}</span>
          </div>
          {/* <div class="col-6">
            {versionData.Tags &&
              versionData.Tags.map((tag) => {
                return (
                  <span
                    key={tag.Id}
                    class="badge"
                    style={{ marginLeft: "10px", backgroundColor: 'green' }}
                  >
                    {tag.TagName}
                  </span>
                );
              })}

            <button
              type="button"
              class="btn btn-outline-primary bottom_right marginLeft"
            >
              Add Tag
            </button>
          </div> */}
        </div>
      </div>
      <div class="col-12 col-md-6 col-xl-4 textAlingRight">
        <div>
          <button
            type="button"
            class="btn btn-outline-primary"
            style={{ marginLeft: "10px" }}
            onClick={() => {
              navigate('/editBussineRule',{state:{bussinesRuleId: props.BussinesRuleId, versionId: versionData.Id}});
            }}
          >
            <span style={{ marginRight: "5px" }}>Edit</span>
            <i class="bx bx-copy-alt"></i>
          </button>

          <button
            type="button"
            class="btn btn-outline-primary"
            style={{ marginLeft: "10px" }}
            onClick={() => { props.onNewVersionButtonClick(versionData.Id) }}
          >
            <span style={{ marginRight: "5px" }}>New Version</span>
            <i class="bx bx-copy-alt"></i>
          </button>

          <button
            type="button"
            class="btn btn-outline-primary"
            style={{ marginLeft: "10px" }}
            onClick={() => { props.onDeleteVersionButtonClick(versionData.Id) }}
          >
            <span style={{ marginRight: "5px" }} >Delete</span>
            <i class="bx bx-copy-alt"></i>
          </button>
        </div>
      </div>
      <hr style={{ width: "98%", marginTop: "5px" }} />
    </div>
  );
};
export default BussinesRuleVersion;
