import HeaderWithoutUser from "../../Header/HeaderWithoutUser.component";
import logoPng from "../../../img/4.svg";
const DataPolicies = (props) => {
  return (
    <>
      <HeaderWithoutUser title="Data Policies" left="40%" />
      <div className="container-xxl flex-grow-1 container-p-y">
        <div className="row">
          <div className="col-lg-12 mb-12 order-0">
            <div className="card">
              {/* <h5 className="card-header">Data Policies</h5> */}
              <div className="card-body">
                <div className="row">
                  <div
                    className="col-12 col-lg-6"
                    style={{ textAlign: "justify" }}
                  >
                    What exactly is data governance policy? A data governance
                    policy is a written set of standards that ensures an
                    organization's data and information assets are managed
                    consistently and correctly. Individual policies for data
                    quality, access, security, privacy, and usage are generally
                    included in such guidelines, as are varied roles and
                    responsibilities for implementing those policies and
                    monitoring compliance with them. A well-crafted policy
                    serves as the core of a company's data governance program.
                    The data governance policy should describe the ideas,
                    policies, and standards that senior business and IT leaders
                    have concluded are required to ensure that the
                    organization's data assets are protected from both internal
                    misuse and external threats. The policy-making group, known
                    as a data governance committee or data governance council,
                    should ideally be composed mostly of corporate leaders and
                    other data owners. In a process coordinated by data
                    governance managers, this group creates a policy document
                    that clearly defines the organization's data governance
                    structure as well as a set of governance rules and
                    procedures for the executive team, business managers, data
                    analysts, and operational workers to follow. A data
                    governance policy clearly defines how data processing and
                    data management should be carried out to ensure that data is
                    accurate, consistent, and accessible across all systems
                    within an organization. The policy also specifies who is
                    responsible for data in specific situations. It can combine
                    risk management and data ethics concepts to mitigate
                    potential business difficulties caused by incorrect use of
                    the data.
                  </div>
                  <div className="col-12 col-lg-6">
                    <img
                      className="infoImg"
                      src={logoPng}
                      alt="Data Policies image"
                    ></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          type="button"
          class="btn btn-outline-primary addDeparmentButton"
          onClick={props.backButton}
        >
          Back
        </button>
      </div>
    </>
  );
};

export default DataPolicies;
