/**
 * @author Alexandros Andreou
 * @version 0.0.1
 * MenuItem Component
 */

import { NavLink } from "react-router-dom";
const MenuItem = (props) => {
  const { label, icon, to } = props;
  let extraClass = props.extraClass ? props.extraClass : 'menu-item'
  return (
    <NavLink to={to} className={extraClass}>
      <span className="menu-link">
        <i className={`menu-icon tf-icons ${icon}`}></i>
        <div>{label}</div>
      </span>
    </NavLink>
  );
};

export default MenuItem;
