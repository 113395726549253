import { useState } from "react";
import { useEffect } from "react";
import Header from "../../Header/Header.component";
import Correlations from "./CorrelationsComponent/Correlations.component";
import DuplicateRows from "./DuplicateRowsComponent/DuplicateRows.component";
import Overview from "./OverviewComponent/Overview.component";
import Variables from "./VariableComponents/Variables.component";
import LoadingSpinner from "../../isLoadingSpinner/LoadingSpinner";

const DataProfiling = (props) => {
  const [tables, setTables] = useState([]);
  const [isLoading, setLoading] = useState(false)
  const [selectedTable, setSelectedTable] = useState({ Id: 0 });

  useEffect(() => {
    setLoading(true)
    refreshList();
  }, []);

  const refreshList = () => {
    const fetchReq1 = fetch(`/tableCatalog`).then((res) =>
      res.json()
    );

    const fetchReq2 = fetch(`/dataProfilingTables`).then(
      (res) => res.json()
    );

    const fetchReq3 = fetch(
      `/dataProfilingVariables`
    ).then((res) => res.json());

    const fetchReq4 = fetch(
      `/dataProfilingVariablesStats`
    ).then((res) => res.json());
    const allData = Promise.all([fetchReq1, fetchReq2, fetchReq3, fetchReq4]);
    allData.then((res) => {
      var [_tables, _overviewData, _variablesData, _variablesStats] = res;
      for (let variableData of _variablesData) {
        variableData.VariablesStats = _variablesStats.filter(
          (variablesStat) => variablesStat.Variable_id === variableData.Id
        );
      }
      for (let table of _tables) {
        table.overviewData = _overviewData.filter(
          (singleOverviewData) => singleOverviewData.Table_id === table.Id
        )[0];
        table.variablesData = _variablesData.filter(
          (variableData) => variableData.Table_id === table.Id
        );
      }
      setTables(_tables);
      setLoading(false)
    });
  };

  const changeSelectedTable = (tableId) => {
    if (+tableId === 0) {
      setSelectedTable({ Id: 0 });
    } else {
      setSelectedTable({ Id: 0 });
      setTimeout(() => {
        let _selectedTableTable = tables.filter((table) => table.Id == tableId);
        if (_selectedTableTable?.length) {
          _selectedTableTable = _selectedTableTable[0];
          setSelectedTable(_selectedTableTable);
        }
      }, 1);
    }
  };

  return (
    <>
      <Header title="Data Profiling" left="40%" />
      <div className="container-xxl flex-grow-1 container-p-y">
        <Overview
          tables={tables}
          selectedTable={selectedTable}
          changeSelectedTable={changeSelectedTable}
          overviewData={selectedTable.overviewData}
        />
        {selectedTable.Id === 0 && (
          <div class="container-xxl container-p-y centerWrapper">
            <div class="misc-wrapper ">
              <h2 className="mb-2 mx-2 centerText">Select a table from the dropdown above!</h2>
              <p class="mb-4 mx-2 centerText">
                You need to select a table in order to view its Data Profiling!
              </p>
              <div class="mt-4">
                <img
                  src="../static/assets/img/illustrations/Data_profiling.png"
                  alt="girl-doing-yoga-light"
                  width="500"
                  class="img-fluid centerImage"
                  data-app-dark-img="illustrations/girl-doing-yoga-dark.png"
                  data-app-light-img="illustrations/girl-doing-yoga-light.png"
                />
              </div>
            </div>
          </div>
        )}

        {selectedTable.Id !== 0 && (
          <Variables
            variablesData={selectedTable.variablesData}
            overviewData={selectedTable.overviewData}
            variablesStatsData={selectedTable.variablesData.VariablesStats}
            setVariablesStatsData={() => {}}
          />
        )}
        {/* {selectedTable.Id !== 0 && <Correlations />}
        {selectedTable.Id !== 0 && <DuplicateRows />} */}
      </div>
      {isLoading && <LoadingSpinner />}
    </>
  );
};

export default DataProfiling;
