import { AuthContext } from '../UserContenx/UserContext.component';
import { useContext } from "react";
const HeaderWithoutUser = (props) => {
  const authCtx = useContext(AuthContext)
  return (
    <nav
      class="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme "
      id="layout-navbar"
      style={{maxWidth: props.maxWidth ? props.maxWidth : '', zIndex:0}}
    >

      <div
        class="navbar-nav-right d-flex align-items-center"
        id="navbar-collapse"
      >
        <div class="navbar-nav align-items-center">
          <div class="nav-item d-flex align-items-center">
            <h5 class="card-header pageTitle" style={{left: props.left ?  props.left : '43%' }}>{props.title}</h5>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default HeaderWithoutUser;
