import { useState } from "react";
const DataQualityRulesFiltering = (props) => {
  const [showFilter, setShowFilter] = useState("ALL");
  const [sortBy, setSortBy] = useState("Name");
  //let activeCounter = props.businessRulesItems && props.businessRulesItems.Versions.filter( (v) => v.Status == 'Active'  ).length || 0

  return (
    <div class="row">
      <div class="col-lg-12 mb-4 order-0">
        <div class="card">
          <div class="d-flex align-items-end row marginBottom">
            <div class="col-md-4 col-12 col-sm-12 textAllignResponsiveStatus">
              <div class="card-body bussinesType">
                <h5 class="card-title text-primary noMarginTitle">
                  Data Quality Rules <i class="bx bx-code dataQualityRulesNum"></i>
                </h5>
                <span class="bussinesCounter">{props.dataQualityRules.length} </span>
              </div>
              
            </div>

            <div class="col-md-8 col-12 col-sm-12 textAlingRight textAllignResponsiveStatus">
              <div class="card-body-new bussinesType buttonMargin">
                <button
                  type="button"
                  class="btn btn-outline-primary bottom_right marginLeft"
                  onClick={() => {props.setAddNewModal(true)}}
                >
                  Create a rule
                </button>
              </div>
              <div class="card-body-new bussinesType buttonMargin">
                <button
                  type="button"
                  class="btn btn-outline-primary bottom_right marginLeft"
                >
                  Upload rules
                </button>
              </div>
              <div class="card-body-new bussinesType buttonMargin">
                <button
                  type="button"
                  class="btn btn-outline-primary bottom_right marginLeft"
                >
                  Export rules
                </button>
              </div>
            </div>

            <div class="col-12 col-md-6 col-lg-7 col-xl-8 ">
              <div class="nav-item d-flex align-items-center searchBussinesLayout">
                <i class="bx bx-search fs-4 lh-0"></i>
                <input
                  type="text"
                  class="form-control border-0 shadow-none searchInputLayout"
                  placeholder="Search..."
                  aria-label="Search..."
                  style={{ backgroundColor: "#f5f5f9" }}
                />
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-5 col-xl-4">
              <div class="sortByMainArea">
                <div className="row">
                  <div class="col-3" style={{ textAlign: "right" }}>
                    Show:
                  </div>
                  <div class="col-9" style={{paddingRight: '20px'}}>
                    <div
                      class="btn-group"
                      role="group"
                      aria-label="Basic radio toggle button group"
                      style={{ width: "100%" }}
                    >
                      <input
                        type="radio"
                        class="btn-check"
                        name="btnradio"
                        id="btnradio1"
                        autocomplete="off"
                      />
                      <label
                        class={`btn btn-outline-primary ${
                          showFilter === "ALL" ? "radioGroupChecked" : "radioButtonUnchecked"
                        }`}
                        for="btnradio1"
                        onClick={() => {
                          setShowFilter('ALL')
                        }}
                      >
                        All
                      </label>
                      <input
                        type="radio"
                        class="btn-check"
                        name="btnradio"
                        id="btnradio2"
                        autocomplete="off"
                      />
                      <label
                        class={`btn btn-outline-primary  ${
                          showFilter === "ACTIVE" ? "radioGroupChecked" : "radioButtonUnchecked"
                        }`}
                        for="btnradio2"
                        onClick={() => {
                          setShowFilter('ACTIVE')
                        }}
                      >
                        Active
                      </label>
                      <input
                        type="radio"
                        class="btn-check"
                        name="btnradio"
                        id="btnradio3"
                        autocomplete="off"
                      />
                      <label
                        class={`btn btn-outline-primary  ${
                          showFilter === "INACTIVE" ? "radioGroupChecked" : "radioButtonUnchecked"
                        }`}
                        for="btnradio3"
                        onClick={() => {
                          setShowFilter('INACTIVE')
                        }}
                      >
                        Inactive
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="sortByMainArea">
                <div className="row">
                  <div class="col-3" style={{ textAlign: "right" }}>
                    Sort By:
                  </div>
                  <div class="col-9" style={{paddingRight: '20px'}}>
                    <div
                      class="btn-group"
                      role="group"
                      aria-label="Basic radio toggle button group"
                      style={{ width: "100%" }}
                    >
                      <input
                        type="radio"
                        class="btn-check"
                        name="btnradio"
                        id="btnradio1"
                        autocomplete="off"
                      />
                      <label
                        class={`btn btn-outline-primary  ${
                          sortBy === "Name" ? "radioGroupChecked" : "radioButtonUnchecked"
                        }`}
                        for="btnradio1"
                        onClick={() => {
                          setSortBy('Name')
                        }}
                      >
                        Name
                      </label>
                      <input
                        type="radio"
                        class="btn-check"
                        name="btnradio"
                        id="btnradio2"
                        autocomplete="off"
                      />
                      <label
                        class={`btn btn-outline-primary  ${
                          sortBy === "LastUpdated" ? "radioGroupChecked" : "radioButtonUnchecked"
                        }`}
                        for="btnradio2"
                        onClick={() => {
                          setSortBy('LastUpdated')
                        }}
                      >
                        Last Updated
                      </label>
                     
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataQualityRulesFiltering;
