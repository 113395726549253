/**
 * @author Alexandros Andreou
 * @version 0.0.1
 * Deparments Modal
 */

 const DataDomainModal = (props) => {
    return (
      <>
        <div id="BackDropModalLayout" />
        <div
          className="modal fade show"
          id="backDropModal"
          data-bs-backdrop="static"
          tabindex="-1"
          style={{ display: "block" }}
          aria-modal="true"
          role="dialog"
        >
          <div class="modal-dialog">
            <form class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="backDropModalTitle">
                  {props.title} Data Domain
                </h5>
                <button
                  onClick={props.onClose}
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <div class="row">
                  <div class="col mb-3">
                    <label for="nameBackdrop" class="form-label">
                      Name
                    </label>
                    <input
                      onChange={props.onChangeName}
                      value={props.name}
                      type="text"
                      id="nameBackdrop"
                      class="form-control"
                      placeholder="Enter Name"
                    />
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-outline-secondary"
                  data-bs-dismiss="modal"
                  onClick={props.onClose}
                >
                  Close
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  onClick={props.onSave}
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  };
  
  export default DataDomainModal;
  