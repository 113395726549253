import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { BussinesRulesContext } from "../../BussinesRulesContext/BussinesRulesContext.component";
const AddNewBussinesRuleModal = (props) => {
  const bussinesCtx = useContext(BussinesRulesContext);
  const [name, setName] = useState("");
  const [dataDomain, setDataDomain] = useState("0");
  const [dataQualityDimensions, setDataQualityDimensions] = useState("");
  const navigate = useNavigate();
  const addNewBussinesRule = () => {
    props.setAddNewModal(false)
    props.setLoading(true)
    fetch("/businessRules", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        RuleName: name,
        Dimension: dataQualityDimensions,
        Domain: dataDomain,
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          addNewBussinesRuleVersion(result);
        },
        (error) => {
          // Server Error
          alert("Problem with the Server");
        }
      );
    //navigate('/editBussineRule',{state:{dataQualityDimensions ,name, mode: 'Add'}});
  };

  const addNewBussinesRuleVersion = (id) => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    today = yyyy + "-" + mm + "-" + dd;

    fetch("/businessRuleVersions", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Business_Rule_id: id,
        Description: null,
        Example: null,
        IsActiveVersion: true,
        Status: "Active",
        Created: today,
        Last_Updated: today,
      }),
})
      .then((res) => res.json())
      .then(
        (result) => {
          bussinesCtx.refreshList();
          setTimeout(() => {
            navigate("/editBussineRule", {
              state: { bussinesRuleId: +result[0], versionId: +result[1] },
            });
          }, 7000);
          //navigate('/editBussineRule', {state:{bussinesRuleId: props.BussinesRuleId, versionId: versionData.Id}});
        },
        (error) => {
          // Server Error
          alert("Problem with the Server");
        }
      );
  };

  return (
    <>
      <div id="BackDropModalLayout" />
      <div
        className="modal fade show"
        id="backDropModal"
        data-bs-backdrop="static"
        tabindex="-1"
        style={{ display: "block" }}
        aria-modal="true"
        role="dialog"
      >
        <div class="modal-dialog">
          <form class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="backDropModalTitle">
                Add new Bussines Rule
              </h5>
              <button
                onClick={() => {
                  props.setAddNewModal(false);
                }}
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col mb-3">
                  <label for="nameBackdrop" class="form-label">
                    Name
                  </label>
                  <input
                    value={name}
                    type="text"
                    id="nameBackdrop"
                    class="form-control"
                    placeholder="Enter Name"
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div class="row">
                <div class="col mb-3">
                  <label for="exampleFormControlSelect1" class="form-label">
                    Data domain
                  </label>
                  <div>
                    <select
                      class="form-select form-select-display"
                      id="exampleFormControlSelect1"
                      aria-label="Default select example"
                      onChange={(e) => {
                        setDataDomain(e.target.value);
                      }}
                      style={{ width: "100%" }}
                    >
                      <option selected={"0"} value="0">
                        Select an option
                      </option>
                      {bussinesCtx.dataDomains &&
                        bussinesCtx.dataDomains.map((dd) => {
                          return (
                            <option
                              selected={dataDomain == dd.DataDomainName}
                              value={dd.DataDomainName}
                            >
                              {dd.DataDomainName}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col mb-3">
                  <label for="exampleFormControlSelect1" class="form-label">
                    Dimensions
                  </label>
                  <div>
                    <select
                      class="form-select form-select-display"
                      id="exampleFormControlSelect1"
                      aria-label="Default select example"
                      onChange={(e) => {
                        setDataQualityDimensions(e.target.value);
                      }}
                      style={{ width: "50%" }}
                    >
                      <option selected>Select an option</option>
                      <option value="Completeness">Completeness</option>
                      <option value="Validity">Validity</option>
                      <option value="Consistency">Consistency</option>
                      <option value="Accuracy">Accuracy</option>
                      <option value="Uniqueness">Uniqueness</option>
                      <option value="Timeliness">Timeliness</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-outline-secondary"
                data-bs-dismiss="modal"
                onClick={() => {
                  props.setAddNewModal(false);
                }}
              >
                Close
              </button>
              <button
                type="button"
                class="btn btn-primary"
                onClick={addNewBussinesRule}
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddNewBussinesRuleModal;
