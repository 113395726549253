import HeaderWithoutUser from "../../Header/HeaderWithoutUser.component";
import logoPng from "../../../img/1.svg";
const DataStewardship = (props) => {
  return (
    <>
      <HeaderWithoutUser title="Data Stewardship" left="40%" />
      <div className="container-xxl flex-grow-1 container-p-y">
        <div className="row">
          <div className="col-lg-12 mb-12 order-0">
            <div className="card">
              {/* <h5 className="card-header">Data Stewardship</h5> */}
              <div className="card-body">
                <div className="row">
                  <div
                    className="col-12 col-lg-6"
                    style={{ textAlign: "justify" }}
                  >
                    Companies have begun to recognize the significance of data
                    governance in the last several years. Terms like "data
                    governance" and "data stewardship" are frequently bandied
                    around without precise definitions, leading to
                    misunderstanding. It is impossible to respond to
                    difficulties in those areas without a clear understanding of
                    what these terms genuinely entail. The policies, methods,
                    and rules that control your data are referred to as data
                    governance. The application of certain policies, processes,
                    and guidelines is known as data stewardship. It is critical
                    to note that the implementation does not only refer to the
                    tools. People, procedures, and technology are all impacted
                    by data stewardship. The Data Stewardship Council is made up
                    of a group of Data Stakeholders who meet to make
                    data-related decisions. They may establish policy and
                    standards, or they may make suggestions that are implemented
                    by a higher-level Data Governance Board. Sometimes,
                    especially in large organizations, a single level of
                    stewardship is insufficient. In this instance, a stewardship
                    hierarchy may exist. The Data Stewardship Council may divide
                    large or small businesses into teams or working groups to
                    solve specific data issues or decisions. Data Quality
                    Stewards may be included in Data Governance initiatives with
                    an emphasis on data quality. These jobs often report to a
                    business unit or Data Quality team, with Data Governance
                    holding dotted-line accountability. These stewards compare
                    data collections to criteria for completeness, accuracy, and
                    integrity. They make appropriate adjustments and refer other
                    issues to the DGO.
                  </div>
                  <div className="col-12 col-lg-6">
                    <img
                      className="infoImg"
                      src={logoPng}
                      alt="Data Stewardship image"
                    ></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          type="button"
          class="btn btn-outline-primary addDeparmentButton"
          onClick={props.backButton}
        >
          Back
        </button>
      </div>
    </>
  );
};

export default DataStewardship;
