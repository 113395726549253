const OverviewRow = (props) => {
  let extraClass = "";
  if (props.isRed == true) extraClass = "redText";
  return (
    
    <div className={`rowCustom ${extraClass}`} style={{ padding: "10px" }}>
      <div className="col-10" style={{ borderBottom: "1px solid #D9DEE3" }}>
        {props.title}
      </div>
      <div
        className="col-2"
        style={{ textAlign: "right", borderBottom: "1px solid #D9DEE3" }}
      >
        {props.value}
      </div>
    </div>
  );
};

export default OverviewRow;
