/**
 * @author Alexandros Andreou
 * @version 0.0.1
 * Data Quality Issues Page
 */

import { useState, useEffect } from "react";
import Header from "../../Header/Header.component";
import ConfirmationModal from "../../Modal/Confirmation.modal";
import AddNewBussinesRuleModal from "./AddNewBussinesRuleModal.component";
import BusinessRulesItem from "./BussinesRuleItem.component";
import BussinesRulesFiltering from "./BussinesRulesFiltering.component";
import { useContext } from "react";
import { BussinesRulesContext } from "../../BussinesRulesContext/BussinesRulesContext.component";
import LoadingSpinner from "../../isLoadingSpinner/LoadingSpinner";

const BusinessRules = (props) => {
  const bussinesCtx = useContext(BussinesRulesContext);
  const [isLoading, setLoading] = useState(false)
  const [firstTimeComponentLoaded, setfirstTimeComponentLoaded] =
    useState(true);

  useEffect(() => {
    if(firstTimeComponentLoaded){
      setLoading(true)
      bussinesCtx.refreshList();
      setfirstTimeComponentLoaded(false)
    }
  },[]);


  useEffect(() => {
    if (
      !firstTimeComponentLoaded &&
      !bussinesCtx.refreshOnProgress
    ) {
      setLoading(false)
    }
  }, [bussinesCtx.refreshOnProgress]);


  const onDeleteVersionButtonClick = (id) => {
    bussinesCtx.setConfirmationModal(true);
    bussinesCtx.setVersionToDelete(id);
  };

  const onNewVersionButtonClick = (id) => {
    fetch("/addNewVersion/" + id, {
      method: "POST",
      header: {
        Accept: "application/json",
        "Content-Type": "applciation/json",
      },
    }).then(
      (result) => {
        bussinesCtx.refreshList();
      },
      (error) => {
        alert("Failed");
      }
    );
  };

  const [addNewModal, setAddNewModal] = useState(false);

  return (
    <>
      <Header title="Bussines Rules" left="40%" />
      <div className="container-xxl flex-grow-1 container-p-y">
        <BussinesRulesFiltering
          setAddNewModal={setAddNewModal}
          businessRulesItems={bussinesCtx.businessRulesItems}
          bussinesCtx={bussinesCtx}
        />
        {bussinesCtx.businessRulesItems.length === 0 && (
          <div class="container-xxl container-p-y centerWrapper">
            <div class="misc-wrapper ">
              <h2 class="mb-2 mx-2 centerText">No Business Rules found!</h2>
              <p class="mb-4 mx-2 centerText">
                Create one using the "Create rule" button above!
              </p>
              <div class="mt-4">
                <img
                  src="../static/assets/img/illustrations/Business_Rules.png"
                  alt="girl-doing-yoga-light"
                  width="500"
                  class="img-fluid centerImage"
                  data-app-dark-img="illustrations/girl-doing-yoga-dark.png"
                  data-app-light-img="illustrations/girl-doing-yoga-light.png"
                />
              </div>
            </div>
          </div>
        )}
        {bussinesCtx.businessRulesItems.map((businessRulesItemData) => {
          return (
            <BusinessRulesItem
              businessRulesItemData={businessRulesItemData}
              onDeleteVersionButtonClick={onDeleteVersionButtonClick}
              onNewVersionButtonClick={onNewVersionButtonClick}
            />
          );
        })}
      </div>
      {isLoading && <LoadingSpinner />}
      {addNewModal && (
        <AddNewBussinesRuleModal setAddNewModal={setAddNewModal} setLoading={setLoading} />
      )}
      {bussinesCtx.confirmationModal && (
        <ConfirmationModal
          onConfirm= {() =>{
            bussinesCtx.deleteVersion();
            setLoading(true)
          }}
          onClose={() => {
            bussinesCtx.setConfirmationModal(false);
          }}
        />
      )}
    </>
  );
};

export default BusinessRules;
