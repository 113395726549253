/**
 * @author Alexandros Andreou
 * @version 0.0.1
 * BusinessMetadata Page
 */

import { useState, useEffect } from "react";
import ConfirmationModal from "../../Modal/Confirmation.modal";
import BusinessMetadataModal from "../BusinessMetadata/BusinessMetadata.modal";
import MainGrid from "../../Grid/MainGrid.component";
import Header from "../../Header/Header.component";
import LoadingSpinner from "../../isLoadingSpinner/LoadingSpinner";

const BusinessMetadata = (props) => {
  
  const [bmetaData, setBmetadata] = useState([]);
  const [dataDomains, setdataDomains] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [isLoading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState("");
  const [confirmationModal, setConfirmationModal] = useState(false);

  const [selectedBmetadata, setSelectedBmetadata] = useState({
    Id: "",
    Table: "",
    Data_Domain: "",
    Criticality: "",
    Retention_Policy: "",
    Security_Classification: "",
    Data_Steward: "",
  });

  useEffect(() => {
    setLoading(true)
    refreshList();
  }, []);

  const refreshList = () => {
    setLoading(true)
    const fetchReq1 = fetch(`/businessMetadata`).then((res) =>
      res.json()
    );

    const fetchReq2 = fetch(`/dataDomains`).then(
      (res) => res.json()
    );

    const fetchReq3 = fetch(`/employee`).then(
      (res) => res.json()
    );

    const allData = Promise.all([fetchReq1, fetchReq2, fetchReq3]);
    allData.then((res) => {
      var [
        bmetaDatas,
        dataDomains,
        employees
      ] = res;
    setBmetadata(bmetaDatas);
    setdataDomains(dataDomains);
    setEmployees(employees);
    setLoading(false)
     });
  };

  const onChangeDataDomain = (e) => {
    setSelectedBmetadata((prevState) => {
      return {
        Id: prevState.Id,
        Table: prevState.Table,
        Data_Domain: e.target.value,
        Criticality: prevState.Criticality,
        Retention_Policy: prevState.Retention_Policy,
        Security_Classification: prevState.Security_Classification,
        Data_Steward: prevState.Data_Steward
      };
    });
  };

  const onChangeDataSteward = (e) => {
    setSelectedBmetadata((prevState) => {
      return {
        Id: prevState.Id,
        Table: prevState.Table,
        Data_Domain: prevState.Data_Domain,
        Criticality: prevState.Criticality,
        Retention_Policy: prevState.Retention_Policy,
        Security_Classification: prevState.Security_Classification,
        Data_Steward: e.target.value
      };
    });
  };

  const onChangeSecurityClassification = (e) => {
    setSelectedBmetadata((prevState) => {
      return {
        Id: prevState.Id,
        Table: prevState.Table,
        Data_Domain: prevState.Data_Domain,
        Criticality: prevState.Criticality,
        Retention_Policy: prevState.Retention_Policy,
        Security_Classification: e.target.value,
        Data_Steward: prevState.Data_Steward
      };
    });
  };

  const onChangeRetentionPolicy = (e) => {
    setSelectedBmetadata((prevState) => {
      return {
        Id: prevState.Id,
        Table: prevState.Table,
        Data_Domain: prevState.Data_Domain,
        Criticality: prevState.Criticality,
        Retention_Policy: e.target.value,
        Security_Classification: prevState.Security_Classification,
        Data_Steward: prevState.Data_Steward
      };
    });
  };

  const onChangeCriticality = (e) => {
    setSelectedBmetadata((prevState) => {
      return {
        Id: prevState.Id,
        Table: prevState.Table,
        Data_Domain: prevState.Data_Domain,
        Criticality: e.target.value,
        Retention_Policy: prevState.Retention_Policy,
        Security_Classification: prevState.Security_Classification,
        Data_Steward: prevState.Data_Steward
      };
    });
  };

  const editBmetadata = (Id) => {
    setShowModal(true);
    const bmetadata = bmetaData.filter((b) => b.Id === Id);
    setModalMode("Edit");
    setSelectedBmetadata({
      Id: bmetadata[0].Id,
      Table: bmetadata[0].Table,
      Data_Domain: bmetadata[0].Data_Domain,
      Criticality: bmetadata[0].Criticality,
      Retention_Policy: bmetadata[0].Retention_Policy,
      Security_Classification: bmetadata[0].Security_Classification,
      Data_Steward: bmetadata[0].Data_Steward,
    });
  };

  const deleteBmetadataMessage = (Id) => {
    setConfirmationModal(true);
    setSelectedBmetadata(() => {
      return {
        Id: Id,
        Table: "",
        Data_Domain: "",
        Criticality: "",
        Retention_Policy: "",
        Security_Classification: "",
        Data_Steward: "",
      };
    });
  };

  const modalSaveButtonHandler = (newBmetadata) => {
    setShowModal(false);
    console.log(newBmetadata);
    setSelectedBmetadata(newBmetadata);
    console.log(setSelectedBmetadata);
    if (modalMode === "Edit") submitEditedBmetadata(newBmetadata);
    else submitNewBmetadata(newBmetadata);
  };

  const deleteBmetadata = () => {
    setConfirmationModal(false);
    fetch("/businessMetadata/" + selectedBmetadata.Id, {
      method: "DELETE",
      header: {
        Accept: "application/json",
        "Content-Type": "applciation/json",
      },
    }).then(() => {
      refreshList();
    });
  };

  const submitEditedBmetadata = (newBmetadata) => {
    fetch("/businessMetadata", {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Id: newBmetadata.Id,
        Table: (newBmetadata.Table || null),
        Data_Domain: (newBmetadata.Data_Domain || null),
        Criticality: (newBmetadata.Criticality || null) ,
        Retention_Policy: (newBmetadata.Retention_Policy || null),
        Security_Classification: (newBmetadata.Security_Classification || null),
        Data_Steward: (newBmetadata.Data_Steward || null),
      }),
    })
      .then((res) => res.json())
      .then(
        (res) => {
          refreshList();
        },
        (error) => {
          alert("Failed");
        }
      );
  };

  const submitNewBmetadata = (newBmetadata) => {
    fetch("/businessMetadata", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Id: null,
        Table: newBmetadata.Table,
        Data_Domain: newBmetadata.Data_Domain,
        Criticality: newBmetadata.Criticality,
        Retention_Policy: newBmetadata.Retention_Policy,
        Security_Classification: newBmetadata.Security_Classification,
        Data_Steward: newBmetadata.Data_Steward,
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result === "Added Successfully!") {
            refreshList();
          } else {
            //error
          }
        },
        (error) => {
          alert("Failed");
        }
      );
  };

  const columnsList = [
    {
      field: "Id",
      headerText: "ID",
      width: "60",
      visible: false,
      enableFilterSearch: true
    },
    {
      field: "Table",
      headerText: "Table dasdas",
      width: "150",
      visible: true,
      enableFilterSearch: true
    },
    {
      field: "Data_Domain",
      headerText: "Data Domain",
      width: "150",
      visible: true,
      enableFilterSearch: true
    },
    {
      field: "Criticality",
      headerText: "Data Criticality",
      width: "150",
      visible: true,
      enableFilterSearch: true
    },
    {
      field: "Data_Steward",
      headerText: "Data Steward",
      width: "150",
      visible: true,
      enableFilterSearch: true
    },
    {
      field: "Retention_Policy",
      headerText: "Retention Policy",
      width: "150",
      visible: true,
      enableFilterSearch: true
    },
    {
      field: "Security_Classification",
      headerText: "Security Classification",
      width: "150",
      visible: true,
      enableFilterSearch: true
    },
  ]

  return (
    <>
       <Header title='Business Metadata' left='40%'/>
      <div className="container-xxl flex-grow-1 container-p-y">
        <div className="row">
          <div className="col-lg-12 mb-12 order-0">
            <div class="card">
              {/* <h5 class="card-header">Business Metadata </h5> */}
   
              <div class="table-responsive text-nowrap">
                <MainGrid
                  dataScr={bmetaData}
                  onEdit={editBmetadata}
                  onDelete={deleteBmetadataMessage}
                  columnsList={columnsList}
                />
              </div>
            </div>
          </div>
        </div>
        {showModal && (
          <BusinessMetadataModal
            bmetadata={selectedBmetadata}
            title={modalMode}
            Table={selectedBmetadata.Table}
            Id={selectedBmetadata.Id}
            onClose={() => {
              setShowModal(false);
            }}
            onSave={modalSaveButtonHandler}
            dataDomains = {dataDomains}
            onChangeDataDomain = {onChangeDataDomain}
            employees = {employees}
            onChangeDataSteward = {onChangeDataSteward}
            onChangeSecurityClassification = {onChangeSecurityClassification}
            onChangeRetentionPolicy = {onChangeRetentionPolicy}
            onChangeCriticality = {onChangeCriticality}
          />
        )}
        {confirmationModal && (
          <ConfirmationModal
            onConfirm={deleteBmetadata}
            onClose={() => {
              setConfirmationModal(false);
            }}
          />
        )}
      </div>
      {isLoading && <LoadingSpinner />}
    </>
  );
};

export default BusinessMetadata;
