import InputsDatabaseConnections from "./InputsDatatabaseConnections";
import ConnectionStringSideBar from "./ConnectionStringSideBar.component";
import { useEffect, useState, useCallback } from "react";
import Header from "../../Header/Header.component";
import LoadingSpinner from "../../isLoadingSpinner/LoadingSpinner";

const DatabaseConnection = (props) => {
  const [connectionsStringEditAreaClass, setConnectionsStringEditAreaClass] =
    useState("col-xl-8");
  const [connectionsStringColClass, setConnectionsStringColClass] =
    useState("col-xl-4");

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 1500) {
        setConnectionsStringEditAreaClass("col-xl-7");
        setConnectionsStringColClass("col-xl-5");
      } else {
        setConnectionsStringEditAreaClass("col-xl-8");
        setConnectionsStringColClass("col-xl-4");
      }
    }
    handleResize();
    window.addEventListener("resize", handleResize);
  }, []);

  const [connectionsStrings, setConnectionStrings] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true)
    retrieveConnectionStrings();
  }, []);

  const retrieveConnectionStrings = (selectedConnectionstringId) => {
    setIsLoading(true)
    fetch("/databaseConnections")
      .then((response) => response.json())
      .then((data) => {
        let convertedData = [];
        for (let d of data) {
          if (!d.Active_Db) {
            convertedData.push(d);
            convertedData[convertedData.length - 1].selected = false;
          }
        }
        for (let d of data) {
          if (d.Active_Db) convertedData = [d, ...convertedData];
          convertedData[0].selected = true;
        }
        if (selectedConnectionstringId) {
          for (let d of convertedData) {
            d.selected = false;
            if (d.Id === selectedConnectionstringId) d.selected = true;
          }
        }
        setConnectionStrings(convertedData);
        setIsLoading(false)
      });
  };

  const submitEditedDatabaseConnection = (connectionsString) => {
    console.log(connectionsString);
    fetch("/databaseConnections", {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(connectionsString),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          retrieveConnectionStrings(connectionsString.Id);
        },
        (error) => {
          alert("Failed");
        }
      );
  };

  const changeSelectedConnectionString = useCallback((Id) => {
    setConnectionStrings((prevData) => {
      let data = [...prevData];
      for (let d of data) {
        if (d.Id === Id) d.selected = true;
        else d.selected = false;
      }
      return data;
    });
  }, []);

  const selectedConnectionstring = connectionsStrings.filter(
    (connectionsString) => connectionsString.selected === true
  )[0];

  return (
    <>
      <Header title="Database Connection" left="40%" maxWidth="100%" />
      <div className="row">
        <div
          className={`col-12 col-md-12 col-lg-12 ${connectionsStringEditAreaClass}`}
        >
          <InputsDatabaseConnections
            selectedConnectionstring={selectedConnectionstring}
            submitEditedDatabaseConnection={submitEditedDatabaseConnection}
          />
        </div>
        <div
          className={`col-12 col-md-12 col-lg-12 ${connectionsStringColClass}`}
        >
          <ConnectionStringSideBar
            connectionsStrings={connectionsStrings}
            changeSelectedConnectionString={changeSelectedConnectionString}
            selectedConnectionstring={selectedConnectionstring}
            refreshList={retrieveConnectionStrings}
            setIsLoading={setIsLoading}
          />
        </div>
      </div>
      {isLoading && <LoadingSpinner />}
    </>
  );
};

export default DatabaseConnection;
