import * as React from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Filter,
  Inject,
  Edit,
  Sort,
  ForeignKey,
  Toolbar,
  Group,
  LazyLoadGroup,
  Reorder,
  Resize,
  CommandColumn,
  ColumnChooser,
} from "@syncfusion/ej2-react-grids";
import { SampleBase } from "../../sample-base";
import '../../../node_modules/@syncfusion/ej2-base/styles/tailwind-dark.css'
import '../../../node_modules/@syncfusion/ej2-buttons/styles/tailwind-dark.css'
import '../../../node_modules/@syncfusion/ej2-calendars/styles/tailwind-dark.css'
import '../../../node_modules/@syncfusion/ej2-dropdowns/styles/tailwind-dark.css'
import '../../../node_modules/@syncfusion/ej2-inputs/styles/tailwind-dark.css'
import '../../../node_modules/@syncfusion/ej2-navigations/styles/tailwind-dark.css'
import '../../../node_modules/@syncfusion/ej2-popups/styles/tailwind-dark.css'
import '../../../node_modules/@syncfusion/ej2-splitbuttons/styles/tailwind-dark.css'
import '../../../node_modules/@syncfusion/ej2-react-grids/styles/tailwind-dark.css'

export class MainGrid extends SampleBase {
  gridInstance;
  toolbarOptions = ["Search"];
  validationRules = { required: true };
  groupOptions = { enableLazyLoading: true, columns: [] };
  toolbarClick(args) {
    console.log(args);
  }
  filterType = "Excel";
  filterOptions = {
    type: this.filterType,
  };
  begin(args) {
    console.log(args);
  }
  commands = [
    {
      type: "Edit",
      buttonOption: { cssClass: "e-flat", iconCss: "e-edit e-icons" },
    },
    {
      type: "Delete",
      buttonOption: { cssClass: "e-flat", iconCss: "e-delete e-icons" },
    },
  ];
  commandClick(args) {
    if (args.commandColumn.type === "Edit") this.props.onEdit(args.rowData[this.props.columnsList[0].field]);
    else if (args.commandColumn.type === "Delete")
      this.props.onDelete(args.rowData[this.props.columnsList[0].field]);
  }
  render() {
    this.commandClick = this.commandClick.bind(this);
    if (this.props.dataScr.length == 0) return <></>;
    if (!this.props.columnsList) return <></>;
    return (
      <div className="control-pane">
        <div className="control-section">
          <GridComponent
            filterSettings={this.filterOptions}
            showColumnChooser={true}
            commandClick={this.commandClick}
            dataSource={this.props.dataScr}
            allowPaging={true}
            ref={(grid) => (this.gridInstance = grid)}
            allowFiltering={true}
            allowSorting={true}
            allowReordering={true}
            allowResizing={true}
            editSettings={{
              allowEditing: true,
              allowDeleting: true,
              allowAdding: true,
            }}
            //filterSettings={{ type: "Menu" }}
            toolbar={this.toolbarOptions}
            allowGrouping={true}
            groupSettings={this.groupOptions}
            toolbarClick={this.toolbarClick.bind(this)}
            actionBegin={this.begin.bind(this)}
            gridLines="Both"
          >
            <ColumnsDirective>
              {this.props.columnsList.map((column) => {
               
                return (
                  <ColumnDirective
                    field={column.field}
                    headerText={column.headerText}
                    width={column.width}
                    textAlign="left"
                    enableFilterSearch={column.enableFilterSearch}
                    visible={column.visible}
                    filter ={ column.columnFilter ? column.columnFilter  : { type: 'Menu' } }
                  ></ColumnDirective>
                );
              })}
              <ColumnDirective
                headerText="Actions"
                width="120"
                commands={this.commands}
                columns={this.stackedCols2}
                allowReordering={false}
                allowResizing={false}
              />
            </ColumnsDirective>
            <Inject
              services={[
                Filter,
                Page,
                Sort,
                ForeignKey,
                Toolbar,
                Group,
                LazyLoadGroup,
                Reorder,
                Resize,
                CommandColumn,
                ColumnChooser,
              ]}
            />
          </GridComponent>
        </div>
      </div>
    );
  }
}

export default MainGrid;
