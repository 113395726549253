import { AuthContext } from '../../components/UserContenx/UserContext.component';
import { useContext } from "react";
import jQuery from 'jquery';
const Header = (props) => {
  const authCtx = useContext(AuthContext)

  const resetData = () => {
    fetch(
      "/resetDatabase"
    ).then((response) => {
    });
  };
  


  const openMenu  = () => {
    jQuery('html').addClass('layout-menu-expanded')
  }

  return (
    <nav
      class="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme"
      id="layout-navbar"
      style={{maxWidth: props.maxWidth ? props.maxWidth : ''}}
    >
      <div class="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
        <a class="nav-item nav-link px-0 me-xl-4" onClick={openMenu}>
          <i class="bx bx-menu bx-sm"></i>
        </a>
      </div>

      <div
        class="navbar-nav-right d-flex align-items-center"
        id="navbar-collapse"
      >
        <div class="navbar-nav align-items-center">
          <div class="nav-item d-flex align-items-center">
            <h5 class="card-header pageTitle" style={{left: props.left ?  props.left : '45%' }}>{props.title}</h5>
          </div>
        </div>

        <ul class="navbar-nav flex-row align-items-center ms-auto">
          <li class="nav-item lh-1 me-3">
            <span></span>
          </li>

          <li class="nav-item navbar-dropdown dropdown-user dropdown">
            <a
              class="nav-link dropdown-toggle hide-arrow"
              href="javascript:void(0);"
              data-bs-toggle="dropdown"
            >
              <div class="avatar avatar-online">
                <img
                  src="../static/assets/img/avatars/rodeo.png"
                  alt=""
                  class="w-px-40 h-auto rounded-circle"
                />
              </div>
            </a>
            <ul class="dropdown-menu dropdown-menu-end">
              <li>
                <a class="dropdown-item" href="#">
                  <div class="d-flex">
                    <div class="flex-shrink-0 me-3">
                      <div class="avatar avatar-online">
                        <img
                          src="../static/assets/img/avatars/rodeo.png"
                          alt=""
                          class="w-px-40 h-auto rounded-circle"
                        />
                      </div>
                    </div>
                    <div class="flex-grow-1">
                      <span class="fw-semibold d-block">{authCtx.user ?authCtx.user : '' }</span>
                      <small class="text-muted">Admin</small>
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <div class="dropdown-divider"></div>
              </li>
              <li>
                <a class="dropdown-item" href="#">
                  <i class="bx bx-user me-2"></i>
                  <span class="align-middle">My Profile</span>
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="#">
                  <i class="bx bx-cog me-2"></i>
                  <span class="align-middle">Settings</span>
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="#" onClick={() => {resetData()}}>
                  <i class="bx bx-reset me-2"></i>
                  <span class="align-middle">Reset Data</span>
                </a>
              </li>
              {/* <li>
                <a class="dropdown-item" href="#">
                  <span class="d-flex align-items-center align-middle">
                    <i class="flex-shrink-0 bx bx-credit-card me-2"></i>
                    <span class="flex-grow-1 align-middle">Billing</span>
                    <span class="flex-shrink-0 badge badge-center rounded-pill bg-danger w-px-20 h-px-20">
                      4
                    </span>
                  </span>
                </a>
              </li> */}
              <li>
                <div class="dropdown-divider"></div>
              </li>
              <li>
                <a class="dropdown-item" onClick={() => {authCtx.userLoggedIn(false)}}>
                  <i class="bx bx-power-off me-2"></i>
                  <span style = {{cursor: 'pointer'}}class="align-middle" >Log Out</span>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Header;
