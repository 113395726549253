import { useState, useEffect, useRef } from "react";
import PasswordInput from "../../SmallComponents/PasswordInput.component";
const MicrosoftSQLConnection = (props) => {
  const passwordRef = useRef(null);
  const [mode, setMode] = useState("readOnly");
  const [isLocal, setIslocal] = useState(false);
  const [currentSettings, setCurrentSettings] = useState({
    Database_Type: "",
    Database_Name: "",
    Username: "",
    TrustServerCertificate: false,
    Encrypt: false,
    Driver: "",
    Connection_Timeout: "",
    Server: "",
    Password: "",
  });



  const { selectedConnectionstring } = props;
  useEffect(() => {
    if (props.selectedConnectionstring) setCurrentSettingsAsProps();

    if (
      props.selectedConnectionstring.Database_Type === "Microsoft SQL Local"
    ) {
      setIslocal(true);
    } else {
      setIslocal(false);
    }
    setMode("readOnly");
  }, [selectedConnectionstring]);

  const cancelEdit = () => {
    setCurrentSettingsAsProps();
    setMode("readOnly");
    passwordRef.current.forceHidePassword();
  };

  const changeDatabaseType = (isLocalDatabase) => {
    setCurrentSettings((prevState) => {
      return {
        ...prevState,
        Database_Type: isLocalDatabase
          ? "Microsoft SQL Local"
          : "Microsoft SQL",
      };
    });
    setIslocal(isLocalDatabase);
  };

  const setCurrentSettingsAsProps = () => {
    setCurrentSettings({
      Id: selectedConnectionstring.Id,
      Database_Type: selectedConnectionstring.Database_Type,
      Database_Name: selectedConnectionstring.Database_Name,
      Username: selectedConnectionstring.Username,
      TrustServerCertificate: selectedConnectionstring.TrustServerCertificate,
      Encrypt:
        selectedConnectionstring.Encrypt == "1" ||
        selectedConnectionstring.Encrypt == 1 ||
        selectedConnectionstring.Encrypt == true
          ? true
          : false,
      Driver: selectedConnectionstring.Driver,
      Connection_Timeout: selectedConnectionstring.Connection_Timeout,
      Server: selectedConnectionstring.Server,
      Password: selectedConnectionstring.Password,
    });
  };

const saveEditedDatabase = () => {  
  setMode("readOnly");
  props.submitEditedDatabaseConnection(currentSettings)
  passwordRef.current.forceHidePassword();
}

  return (
    <div class="row inputLayout">
      <div class="col-12">
        <div class="card col-12">
          <div class="row card-body demo-vertical-spacing2 demo-only-element">
            <div className="col-6">
              <div class="form-password-toggle">
                <label class="form-label" for="basic-default-password12">
                  Database Type
                </label>

                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    id="basic-default-password12"
                    aria-describedby="basic-default-password2"
                    disabled
                    value={currentSettings.Database_Type}
                    onChange={(e) => {
                      setCurrentSettings((prevState) => {
                        return {
                          ...prevState,
                          Database_Type: e.target.value,
                        };
                      });
                    }}
                  />
                  <i class="bx bx-chevron-down dropdownDatabaseTypeIcon"></i>
                </div>
              </div>

              <div class="form-password-toggle">
                <div class="input-group">
                  <input
                    checked={isLocal}
                    type="checkbox"
                    className="form-check-input"
                    disabled={mode == "readOnly"}
                    onChange={(e) => {
                      changeDatabaseType(e.target.checked);
                    }}
                  />
                  <label class="form-check-label">Local</label>
                </div>
              </div>

              <div class="form-password-toggle">
                <label class="form-label" for="basic-default-password12">
                  Driver
                </label>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    id="basic-default-password12"
                    aria-describedby="basic-default-password2"
                    disabled={mode == "readOnly"}
                    value={currentSettings.Driver}
                    onChange={(e) => {
                      setCurrentSettings((prevState) => {
                        return {
                          ...prevState,
                          Driver: e.target.value,
                        };
                      });
                    }}
                  />
                </div>
              </div>

              <div class="form-password-toggle">
                <label class="form-label" for="basic-default-password12">
                  Server
                </label>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    id="basic-default-password12"
                    aria-describedby="basic-default-password2"
                    disabled={mode == "readOnly"}
                    value={currentSettings.Server}
                    onChange={(e) => {
                      setCurrentSettings((prevState) => {
                        return {
                          ...prevState,
                          Server: e.target.value,
                        };
                      });
                    }}
                  />
                </div>
              </div>

              <div class="form-password-toggle">
                <label class="form-label" for="basic-default-password12">
                  Database
                </label>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    id="basic-default-password12"
                    aria-describedby="basic-default-password2"
                    disabled={mode == "readOnly"}
                    value={currentSettings.Database_Name}
                    onChange={(e) => {
                      setCurrentSettings((prevState) => {
                        return {
                          ...prevState,
                          Database_Name: e.target.value,
                        };
                      });
                    }}
                  />
                </div>
              </div>

              <div class="form-password-toggle">
                <label class="form-label" for="basic-default-password12">
                  Connection Timeout
                </label>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    id="basic-default-password12"
                    aria-describedby="basic-default-password2"
                    disabled={mode == "readOnly" || isLocal == true}
                    value={currentSettings.Connection_Timeout}
                    onChange={(e) => {
                      setCurrentSettings((prevState) => {
                        return {
                          ...prevState,
                          Connection_Timeout: e.target.value,
                        };
                      });
                    }}
                  />
                </div>
              </div>

              <div class="form-password-toggle">
                <div class="input-group">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    disabled={mode == "readOnly" || isLocal == true}
                    checked={currentSettings.Encrypt}
                    onClick={(e) => {
                      setCurrentSettings((prevState) => {
                        return {
                          ...prevState,
                          Encrypt: e.target.checked,
                        };
                      });
                    }}
                  />
                  <label class="form-check-label">Encryption</label>
                </div>
              </div>

              <div class="form-password-toggle">
                <div class="input-group">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    disabled={mode == "readOnly" || isLocal == true}
                    checked={currentSettings.TrustServerCertificate}
                    onClick={(e) => {
                      setCurrentSettings((prevState) => {
                        return {
                          ...prevState,
                          TrustServerCertificate: e.target.checked,
                        };
                      });
                    }}
                  />
                  <label class="form-check-label">
                    Trust Server Certificate
                  </label>
                </div>
              </div>
            </div>

            <div className="col-6">
              <div class="form-password-toggle">
                <label class="form-label" for="basic-default-password12">
                  Username
                </label>
                <div class="input-group">
                  <input
                    disabled={mode == "readOnly" || isLocal == true}
                    type="text"
                    class="form-control"
                    id="basic-default-password12"
                    aria-describedby="basic-default-password2"
                    value={currentSettings.Username}
                    onChange={(e) => {
                      setCurrentSettings((prevState) => {
                        return {
                          ...prevState,
                          Username: e.target.value,
                        };
                      });
                    }}
                  />
                </div>
              </div>

              <div class="form-password-toggle" style={{marginTop: '50px'}}>
                <label class="form-label" for="basic-default-password12">
                  Password
                </label>
                <div class="input-group">
                <PasswordInput
                    ref={passwordRef}
                    value={currentSettings.Password }
                    disabled={mode === "readOnly" || isLocal == true}
                    canChange={mode == "edit"}
                    onChange={(e) => {
                      setCurrentSettings((prevState) => {
                        return {
                          ...prevState,
                          Password: e.target.value,
                        };
                      });
                    }}
                  />
                </div>
              </div>

              <div className="actionsButtons">
                {mode == "readOnly" && (
                  <button
                    type="button"
                    class="btn btn-outline-primary bottom_right marginLeft"
                    onClick={() => setMode("edit")}
                  >
                    Edit
                  </button>
                )}
                {mode == "edit" && (
                  <button
                    type="button"
                    class="btn btn-outline-primary bottom_right marginLeft"
                    onClick={cancelEdit}
                  >
                    Cancel
                  </button>
                )}
                {mode == "edit" && (
                  <button
                    type="button"
                    class="btn btn-outline-primary bottom_right marginLeft"
                    onClick={saveEditedDatabase}
                  >
                    Save
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MicrosoftSQLConnection;
