/**
 * @author Alexandros Andreou
 * @version 0.0.1
 * Data Dictionary Page
 */

import { useState, useEffect } from "react";
import ConfirmationModal from "../../Modal/Confirmation.modal";
import DataDictionaryModal from "../DataDictionary/DataDictionary.modal";
import MainGrid from "../../Grid/MainGrid.component";
import Header from "../../Header/Header.component";
import LoadingSpinner from "../../isLoadingSpinner/LoadingSpinner";

const DataDictionary = (props) => {
  const [datadictionaries, setdatadictionaries] = useState([]);
  const [isLoading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState("");
  const [confirmationModal, setConfirmationModal] = useState(false);
  

  const [selectedDataDictionary, setSelectedDataDictionary] = useState({
    Id: "",
    Entity: "",
    Source_System: "",
    Table_Name: "",
    Column_Name: "",
    Data_Type: "",
    Required: "",
    Unique: "",
    Description: "",
    Value_Example: "",
    Primary_Key: "",
    Foreign_Key: "",
    Foreign_Key_Table: "",
    Foreign_Key_Column: "",
  });

  useEffect(() => {
    setLoading(true);
    refreshList();
  }, []);


  const refreshList = () => {
    setLoading(true)
    fetch("/dataDictionary")
      .then((response) => response.json())
      .then((data) => {
        debugger;
        setdatadictionaries(data);
        setLoading(false);
      });
  };

  // const searchStringChanged = (e) => {
  //   setSearchString(e.target.value);
  // };

  // const addDataDictionary = () => {
  //   $("#TableId").DataTable().page.len(100).draw();
  //   setShowModal(true);
  //   setModalMode("Add");
  //   setSelectedDataDictionary({
  //     Id: 0,
  //     Entity: "",
  //     Source_System: "",
  //     Table_Name: "",
  //     Column_Name: "",
  //     Data_Type: "",
  //     Required: "",
  //     Unique: "",
  //     Description: "",
  //     Value_Example: "",
  //     Primary_Key: "",
  //     Foreign_Key: "",
  //     Foreign_Key_Table: "",
  //     Foreign_Key_Column: "",
  //   });
  // };

  const editDataDictionary = (Id) => {
    setShowModal(true);
    const datadictionary = datadictionaries.filter((d) => d.Id === Id);
    setModalMode("Edit");
    setSelectedDataDictionary({
      Id: datadictionary[0].Id,
      // Entity: datadictionary[0].Entity,
      // Source_System: datadictionary[0].Source_System,
      Table_Name: datadictionary[0].Table_Name,
      Column_Name: datadictionary[0].Column_Name,
      // Data_Type: datadictionary[0].Data_Type,
      Required: datadictionary[0].Required,
      Unique: datadictionary[0].Unique,
      Description: datadictionary[0].Description,
      Value_Example: datadictionary[0].Value_Example,
      // Primary_Key: datadictionary[0].Primary_Key,
      // Foreign_Key: datadictionary[0].Foreign_Key,
      // Foreign_Key_Table: datadictionary[0].Foreign_Key_Table,
      // Foreign_Key_Column: datadictionary[0].Foreign_Key_Column,
    });
  };

  const deleteDataDictionaryMessage = (id) => {
    setConfirmationModal(true);
    setSelectedDataDictionary(() => {
      return {
        Id: id,
        // Entity: "",
        // Source_System: "",
        Table_Name: "",
        Column_Name: "",
        // Data_Type: "",
        Required: "",
        Unique: "",
        Description: "",
        Value_Example: "",
        // Primary_Key: "",
        // Foreign_Key: "",
        // Foreign_Key_Table: "",
        // Foreign_Key_Column: "",
      };
    });
  };

  const modalSaveButtonHandler = (newDataDictionary) => {
    setShowModal(false);
    console.log(newDataDictionary);
    setSelectedDataDictionary(newDataDictionary);
    console.log(setSelectedDataDictionary);
    if (modalMode === "Edit") submitEditedDataDictionary(newDataDictionary);
    else submitNewDataDictionary(newDataDictionary);
  };

  const deleteDataDictionary = () => {
    setConfirmationModal(false);
    fetch("/dataDictionary/" + selectedDataDictionary.Id, {
      method: "DELETE",
      header: {
        Accept: "application/json",
        "Content-Type": "applciation/json",
      },
    }).then(() => {
      refreshList();
    });
  };

  const submitEditedDataDictionary = (newDataDictionary) => {
    fetch("/dataDictionary", {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Id: newDataDictionary.Id,
        // Entity: newDataDictionary.Entity,
        // Source_System: newDataDictionary.Source_System,
        Table_Name: (newDataDictionary.Table_Name|| null),
        Column_Name: (newDataDictionary.Column_Name|| null),
        // Data_Type: newDataDictionary.Data_Type,
        Required: (newDataDictionary.Required|| null),
        Unique: (newDataDictionary.Unique|| null),
        Description: (newDataDictionary.Description|| null),
        Value_Example: (newDataDictionary.Value_Example|| null),
        // Primary_Key: newDataDictionary.Primary_Key,
        // Foreign_Key: newDataDictionary.Foreign_Key,
        // Foreign_Key_Table: newDataDictionary.Foreign_Key_Table,
        // Foreign_Key_Column: newDataDictionary.Foreign_Key_Column,
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          refreshList();
        },
        (error) => {
          alert("Failed");
        }
      );
  };

  const submitNewDataDictionary = (newDataDictionary) => {
    fetch("/dataDictionary", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Id: null,
        // Entity: newDataDictionary.Entity,
        // Source_System: newDataDictionary.Source_System,
        Table_Name: newDataDictionary.Table_Name,
        Column_Name: newDataDictionary.Column_Name,
        // Data_Type: newDataDictionary.Data_Type,
        Required: newDataDictionary.Required,
        Unique: newDataDictionary.Unique,
        Description: newDataDictionary.Description,
        Value_Example: newDataDictionary.Value_Example,
        // Primary_Key: newDataDictionary.Primary_Key,
        // Foreign_Key: newDataDictionary.Foreign_Key,
        // Foreign_Key_Table: newDataDictionary.Foreign_Key_Table,
        // Foreign_Key_Column: newDataDictionary.Foreign_Key_Column,
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result === "Added Successfully!") {
            refreshList();
          } else {
            //error
          }
        },
        (error) => {
          alert("Failed");
        }
      );
  };

  // const filteredDataDictionaries = datadictionaries.filter((datadictionary) =>
  //   datadictionary.Column_Name.toLowerCase().includes(
  //     searchString.toLowerCase()
  //   )
  // );

  const columnsList = [
    {
      field: "Id",
      headerText: "ID",
      width: "60",
      visible: false,
      enableFilterSearch: true
    },
    // {
    //   field: "Entity",
    //   headerText: "Entity",
    //   width: "150",
    //   visible: true,
    //   enableFilterSearch: true
    // },
    // {
    //   field: "Source_System",
    //   headerText: "Source_System",
    //   width: "150",
    //   visible: true,
    //   enableFilterSearch: true
    // },
    {
      field: "Table_Name",
      headerText: "Table_Name",
      width: "150",
      visible: true,
      enableFilterSearch: true
    },
    {
      field: "Column_Name",
      headerText: "Column_Name",
      width: "150",
      visible: true,
      enableFilterSearch: true
    },
    // {
    //   field: "Data_Type",
    //   headerText: "Data_Type",
    //   width: "150",
    //   visible: true,
    //   enableFilterSearch: true
    // },
    {
      field: "Required",
      headerText: "Required",
      width: "150",
      visible: true,
      enableFilterSearch: true,
      columnFilter: { type : 'CheckBox' }
    },
    {
      field: "Unique",
      headerText: "Unique",
      width: "150",
      visible: true,
      enableFilterSearch: true,
      columnFilter: { type : 'CheckBox' }
    },
    {
      field: "Description",
      headerText: "Description",
      width: "150",
      visible: true,
      enableFilterSearch: true
    },
    {
      field: "Value_Example",
      headerText: "Value_Example",
      width: "150",
      visible: true,
      enableFilterSearch: true
    },
    // {
    //   field: "Primary_Key",
    //   headerText: "Primary_Key",
    //   width: "150",
    //   visible: true,
    //   enableFilterSearch: true
    // },
    // {
    //   field: "Foreign_Key",
    //   headerText: "Foreign_Key",
    //   width: "150",
    //   visible: true,
    //   enableFilterSearch: true
    // },
    // {
    //   field: "Foreign_Key_Table",
    //   headerText: "Foreign_Key_Table",
    //   width: "150",
    //   visible: true,
    //   enableFilterSearch: true
    // },
    // {
    //   field: "Foreign_Key_Column",
    //   headerText: "Foreign_Key_Column",
    //   width: "150",
    //   visible: true,
    //   enableFilterSearch: true
    // }
  ]

  return (
    <>
  <Header title='Data Dictonary' left='40%'/>
      <div className="container-xxl flex-grow-1 container-p-y">
        <div className="row">
          <div className="col-lg-12 mb-12 order-0">
            <div class="card">
              {/* <h5 class="card-header">Data Dictionary</h5> */}
        
              <div class="table-responsive text-nowrap">
              <MainGrid
                  dataScr={datadictionaries}
                  onEdit={editDataDictionary}
                  onDelete={deleteDataDictionaryMessage}
                  columnsList={columnsList}
                />

              </div>
            </div>
          </div>
        </div>
        {showModal && (
          <DataDictionaryModal
            dataDictionary={selectedDataDictionary}
            title={modalMode}
            name={selectedDataDictionary.Column}
            id={selectedDataDictionary.Id}
            onClose={() => {
              setShowModal(false);
            }}
            onSave={modalSaveButtonHandler}
          />
        )}
        {confirmationModal && (
          <ConfirmationModal
            onConfirm={deleteDataDictionary}
            onClose={() => {
              setConfirmationModal(false);
            }}
          />
        )}
      </div>
      {isLoading && <LoadingSpinner />}
    </>
  );
};

export default DataDictionary;
