import { memo, useState, useEffect } from "react";
import DatabaseConnectionModal from "./DatabaseConnection.modal";
import ConfirmationModal from "../../Modal/Confirmation.modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ConnectionStringSideBar = (props) => {
  let selected = props.connectionsStrings.filter(
    (connectionsString) => connectionsString.selected
  )[0];
  const [showModal, setShowModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [confirmationModalImportData, setConfirmationModalImportData] =
    useState(false);

  const deleteConnectionString = () => {
    let selectedContectionString = props.connectionsStrings.filter(
      (connectionsString) => connectionsString.selected
    )[0];
    setConfirmationModal(false);
    fetch("/databaseConnections/" + selectedContectionString.Id, {
      method: "DELETE",
      header: {
        Accept: "application/json",
        "Content-Type": "applciation/json",
      },
    }).then(() => {
      props.refreshList();
    });
  };

  const activeConectionString = (event, id) => {
    if (event.detail == 2) {
      let currentActive = props.connectionsStrings.filter(
        (connectionsString) => connectionsString.Active_Db
      )[0];
      if (currentActive.Id === id) return;
      alert(id);
    }
  };

  const importData = () => {
    setConfirmationModalImportData(false);
    props.setIsLoading(true);
    let selectedContectionString = props.connectionsStrings.filter(
      (connectionsString) => connectionsString.selected
    )[0];
    fetch("/databaseConnectionsImport/" + selectedContectionString.Id).then(
      (response) => {
        props.setIsLoading(false);
      }
    );
  };

  return (
    <div className="inputLayout">
      <div className="card fullHeight">
        <div className="card-body demo-vertical-spacing2 demo-only-element">
          <div className="titleConnection">Connections</div>
          <div className="ScrollViewDatabaseConnection">
            {props.connectionsStrings.map((connectionsString) => {
              // Setting the color of the circle of a connectionString element
              let selectedColor = connectionsString.Label_Color;
              // Setting the initials inside of the circle
              let initials = "";
              if (connectionsString.Database_Name !== null) {
                let pairs = connectionsString.Database_Name.split(" ");
                if (pairs.length === 1)
                  pairs = connectionsString.Database_Name.split("-");
                if (pairs.length > 1) {
                  initials += (pairs[0][0] + pairs[1][0]).toLocaleUpperCase();
                } else {
                  initials =
                    connectionsString.Database_Name[0] +
                    connectionsString.Database_Name[1];
                  initials = initials.toLocaleUpperCase();
                }
              } else initials = "NN";
              let activeDB = connectionsString.Active_Db
                ? "dbConnectedIcon"
                : "dbDisconnectedIcon";
              let selectedDB = connectionsString.selected
                ? "selectedConnectionstring"
                : "";
              return (
                <div
                  className={`connectionString row ${selectedDB}`}
                  key={connectionsString.Id}
                >
                  <div className="col-2">
                    <div
                      className="layoutCircle"
                      style={{ background: selectedColor }}
                    >
                      <div
                        className="circleText"
                        onClick={() => {
                          props.changeSelectedConnectionString(
                            connectionsString.Id
                          );
                        }}
                      >
                        {initials}
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-8"
                    onClick={() => {
                      props.changeSelectedConnectionString(
                        connectionsString.Id
                      );
                    }}
                  >
                    <div>{connectionsString.Database_Name}</div>
                    <div>{connectionsString.Database_Type}</div>
                  </div>
                  <div className="col-2">
                    <i
                      class={`bx bxs-data ${activeDB}`}
                      onClick={(e) =>
                        activeConectionString(e, connectionsString.Id)
                      }
                    ></i>
                  </div>
                </div>
              );
            })}
          </div>

          {showModal && (
            <DatabaseConnectionModal
              setShowModal={setShowModal}
              refreshList={props.refreshList}
              title="New"
              setIsLoading={props.setIsLoading}
            />
          )}
          {confirmationModal && (
            <ConfirmationModal
              onConfirm={deleteConnectionString}
              onClose={() => {
                setConfirmationModal(false);
              }}
            />
          )}
          {confirmationModalImportData && (
            <ConfirmationModal
              confirmationModalMessage="You are about to overwrite existing data. Are you sure?"
              onConfirm={importData}
              onClose={() => {
                setConfirmationModalImportData(false);
              }}
            />
          )}
          <div
            className="sideBarButtons col-12"
            style={{ paddingLeft: "35px" }}
          >
            <div>
              <button
                onClick={() => {
                  setConfirmationModalImportData(true);
                }}
                type="button"
                class="btn btn-outline-primary bottom_right marginLeft importButton"
              >
                Input Data From this Database
              </button>
            </div>
            <div>
              <button
                type="button"
                class="btn btn-outline-primary bottom_right marginLeft"
                onClick={() => {
                  setShowModal(true);
                }}
              >
                Add New
              </button>
              <button
                onClick={() => {
                  setConfirmationModal(true);
                }}
                type="button"
                class="btn btn-outline-primary bottom_right marginLeft deleteConectionButton"
              >
                Delete
              </button>
            </div>
            <button
              onClick={() => {
                let result = fetch("/testDatabase/" + selected.Id, {
                  method: "POST",
                  header: {
                    Accept: "application/json",
                    "Content-Type": "applciation/json",
                  },
                })
                  .then((res) => res.json())
                  .then(
                    (result) => {
                      if (result === "Success") {
                        toast.success("Succesful Connection", {
                          position: toast.POSITION.BOTTOM_RIGHT,
                        });
                      } else {
                        toast.error(
                          "Something went wrong, please check your inputs",
                          {
                            position: toast.POSITION.BOTTOM_RIGHT,
                          }
                        );
                      }
                    },
                    (error) => {
                      // Server Error
                      alert("Problem with the Server");
                      console.log(error);
                    }
                  );
              }}
              type="button"
              class="btn btn-outline-primary bottom_right marginLeft testConnectionButton"
            >
              Test Connection
            </button>
            <ToastContainer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(ConnectionStringSideBar);
