import CategoricalType from "./Categorical.component";
import NumericType from "./Numeric.component";

const Variables = (props) => {
console.log(props)


  return (
    <div className="row" style={{ marginTop: "20px" }}>
      <div className="col-lg-12 mb-12 order-0">
        <div class="card">
          <h5 class="card-header">Variables</h5>
          <div class="table-responsive text-nowrap layoutPadding40">

            {!props.variablesData?.length && (<div>No data</div>)}

            {props.variablesData.map((variable) => {
              if (variable.Type === "Categorical")
                return <CategoricalType variableData={variable} overviewData={props.overviewData} statsData ={props.variablesStatsData} setVariablesStatsData = {props.setVariablesStatsData}/>;
              else if (variable.Type === "Numeric")
                return <NumericType variableData={variable} overviewData={props.overviewData} statsData ={props.variablesStatsData} setVariablesStatsData = {props.setVariablesStatsData}/>;

              return <div></div>;
            })}

          </div>
        </div>
      </div>
    </div>
  );
};

export default Variables;
