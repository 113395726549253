/**
 * @author Alexandros Andreou
 * @version 0.0.2
 * Database Connection Modal
 */

import { useState } from "react";
import MicrosoftSQLConnectionModal from "./InputsModals/MicrosoftSQLConnection.AddNew";
import MYSQLAddNew from "./InputsModals/MYSQL.AddNew";
import OracleConnectionAddNew from "./InputsModals/OracleConnection.AddNew";
import PostgreSQLConnectionAddNew from "./InputsModals/PostgreSQLConnectionAddNew";

const DatabaseConnectionModal = (props) => {
  const [selectedType, setSellectedType] = useState(0);
  const [isLocal, setIsLocal] = useState(true);

  const onSubmit = (databaseConnection) => {
    props.setShowModal(false);
    let submitedConnectonString = {};
    submitedConnectonString.Database_Type = databaseConnection.Database_Type ? databaseConnection.Database_Type : null;
    submitedConnectonString.Database_Name = databaseConnection.Database_Name ? databaseConnection.Database_Name : null;
    submitedConnectonString.Username = databaseConnection.Username ? databaseConnection.Username : null;
    submitedConnectonString.TrustServerCertificate = databaseConnection.TrustServerCertificate ? databaseConnection.TrustServerCertificate : null;
    submitedConnectonString.Encrypt = databaseConnection.Encrypt ? databaseConnection.Encrypt : null;
    submitedConnectonString.Driver = databaseConnection.Driver ? databaseConnection.Driver : null;
    submitedConnectonString.Connection_Timeout = databaseConnection.Connection_Timeout ? databaseConnection.Connection_Timeout : null;
    submitedConnectonString.Server = databaseConnection.Server ? databaseConnection.Server : null;
    submitedConnectonString.Password = databaseConnection.Password ? databaseConnection.Password : null;
    submitedConnectonString.Host = databaseConnection.Host ? databaseConnection.Host : null;
    submitedConnectonString.Database = databaseConnection.Database ? databaseConnection.Database : null;
    submitedConnectonString.Raise_on_warnings = databaseConnection.Raise_on_warnings ? databaseConnection.Raise_on_warnings : null;
    submitedConnectonString.DSN = databaseConnection.DSN ? databaseConnection.DSN : null;
    submitedConnectonString.Port = databaseConnection.Port ? databaseConnection.Port : null;
    submitedConnectonString.Encoding = databaseConnection.Encoding ? databaseConnection.Encoding : null;

    fetch("/databaseConnections", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(submitedConnectonString),
    })
      .then((res) => res.json())
      .then(
        (result) => {         
          if (result === "Added Successfully!") {
            props.refreshList();
          } else {
            //error
          }
        },
        (error) => {
          alert("Failed");
        }
      );
  };
  return (
    <>
      <div id="BackDropModalLayout" />
      <div
        className="modal fade show"
        id="backDropModal"
        data-bs-backdrop="static"
        tabindex="-1"
        style={{ display: "block" }}
        aria-modal="true"
        role="dialog"
      >
        <div class="modal-dialog">
          <form class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="backDropModalTitle">
                {props.title} Database Connection String
              </h5>

              <button
                onClick={() => {
                  props.setShowModal(false);
                }}
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col mb-3">
                  <label for="exampleFormControlSelect1" class="form-label">
                    Connection Type
                  </label>
                  <div>
                    <select
                      class="form-select form-select-display"
                      id="exampleFormControlSelect1"
                      aria-label="Default select example"
                      onChange={(e) => {
                        setSellectedType(e.target.value);
                      }}
                      style={{ width: "50%" }}
                    >
                      <option selected>Select an option</option>
                      <option value="microsoftSQL">Microsoft SQL </option>
                      <option value="Oracle">Oracle</option>
                      <option value="MYSQL">MYSQL</option>
                      <option value="PostgreSQL">PostgreSQL</option>
                    </select>
                    {selectedType === "microsoftSQL" && (
                      <>
                        <input
                          style={{ marginTop: "10px", marginLeft: "15px" }}
                          className="form-check-input"
                          type="checkbox"
                          checked={isLocal}
                          onChange={(e) => {
                            setIsLocal(e.target.checked);
                          }}
                        />
                        <label class="form-check-label">Local</label>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {selectedType === "microsoftSQL" && (
              <MicrosoftSQLConnectionModal
                isLocal={isLocal}
                onSubmit={onSubmit}
                setShowModal={props.setShowModal}
                setIsLoading={props.setIsLoading}
              />
            )}
            {selectedType === "Oracle" && (
              <OracleConnectionAddNew
                onSubmit={onSubmit}
                setShowModal={props.setShowModal}
                setIsLoading={props.setIsLoading}
              />
            )}
            {selectedType === "MYSQL" && (
              <MYSQLAddNew
                onSubmit={onSubmit}
                setShowModal={props.setShowModal}
                setIsLoading={props.setIsLoading}
              />
            )}
            {selectedType === "PostgreSQL" && (
              <PostgreSQLConnectionAddNew
                onSubmit={onSubmit}
                setShowModal={props.setShowModal}
                setIsLoading={props.setIsLoading}
                
              />
            )}
          </form>
        </div>
      </div>
    </>
  );
};

export default DatabaseConnectionModal;
