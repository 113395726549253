import {useState, forwardRef, useImperativeHandle} from 'react';
const PasswordInput = forwardRef((props, ref) => {
  const [hidePassword, setHidePassword] = useState(true);
  useImperativeHandle(ref, () => ({
    forceHidePassword() {
        setHidePassword(true);
    }
  }));
  return (
    <>  
      <input
        type={hidePassword ? "password" : "text"}
        class="form-control"
        id="basic-default-password12"
        placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
        aria-describedby="basic-default-password2"
        value={props.value}
        disabled={props.disabled}
        onChange={props.onChange}
      />
      <span
        id="basic-default-password2"
        class="input-group-text cursor-pointer"
        onClick={() => {
          if (props.canChange == undefined) setHidePassword(!hidePassword);
          else if (props.canChange) setHidePassword(!hidePassword);
        }}
      >
        <i class={hidePassword ? "bx bx-compass" : "bx bxs-compass"}></i>
      </span>
    </>
  );
});
export default PasswordInput;
