import HeaderWithoutUser from "../../Header/HeaderWithoutUser.component";
import logoPng from "../../../img/2.svg";

const DataOwnership = (props) => {
  return (
    <>
      <HeaderWithoutUser title="Data Ownership" left="40%" />
      <div className="container-xxl flex-grow-1 container-p-y">
        <div className="row">
          <div className="col-lg-12 mb-12 order-0">
            <div className="card">
              {/* <h5 className="card-header">Data Ownership</h5> */}
              <div className="card-body">
                <div className="row">
                  <div
                    className="col-12 col-lg-6"
                    style={{ textAlign: "justify" }}
                  >
                    The act of holding legal rights and total control over a
                    single piece or set of data pieces is referred to as data
                    ownership. It establishes and gives information on the
                    legitimate owner of data assets, as well as the data owner's
                    acquisition, use, and distribution policies. Data ownership
                    describes the organization's legal ownership of all
                    enterprise-wide data and is largely a data governance
                    procedure. The capacity to produce, edit, alter, share, and
                    restrict access to the data belongs to a certain
                    organization or the data owner. The capacity of the data
                    owner to delegate, distribute, or forego each of these
                    rights to a third party is also referred to as data
                    ownership. Usually, medium-sized to big businesses with
                    sizable repositories of centralized or dispersed data pieces
                    adopt this approach. The data owner asserts ownership and
                    copyrights over the data in order to maintain control over
                    it and to be able to take legal action if an internal or
                    external party violates their ownership of it without
                    authorization.+
                  </div>
                  <div className="col-12 col-lg-6">
                    <img
                      className="infoImg"
                      src={logoPng}
                      alt="Data Ownership image"
                    ></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          type="button"
          class="btn btn-outline-primary addDeparmentButton"
          onClick={props.backButton}
        >
          Back
        </button>
      </div>
    </>
  );
};

export default DataOwnership;
