const HomeScreenLP = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <h1 className="text-center mb-5">Welcome to Data Governance Tool</h1>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <img src={('../static/assets/img/avatars/8.png')} alt="data-governance" className="img-homepage" />
        </div>
        <div className="col-md-1">
        </div>
        <div className="col-md-5">
          <p>Our data governance tool helps companies effectively manage and govern their data as well as their employees and departments. With our tool, you can ensure compliance with industry regulations and protect sensitive information.</p>
          <br />
          <p>Our tool includes features such as:</p>
          <ul>
            <li>Data classification and tagging</li>
            <li>Access control and authorization</li>
            <li>Auditing and reporting</li>
            <li>Data discovery and lineage</li>
          </ul>
          <br />
          <div className="text-center mb-5">
          <button className="btn btn-primary">Learn More</button>

          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeScreenLP;
