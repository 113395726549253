import OverviewRow from "./OverviewRow.component"

const OverviewTab = (props) => {
  const {overviewData } = props;
  console.log(overviewData)
  let missingCellsPercantage = (overviewData.Num_missing_cells / overviewData.Num_observations * 100).toFixed(2);
  missingCellsPercantage = missingCellsPercantage + '%'


  let duplicateRowsPercantage = (overviewData.Num_duplicate_rows / overviewData.Num_observations * 100).toFixed(2);
  duplicateRowsPercantage = duplicateRowsPercantage + '%'

  let totalSizeMemory = (overviewData.Total_size_memory/1000 + ' KB')

    return (
      
        <div className="OverViewTab row" style={{ padding: "0px" }}>
        <div className="col-12 col-md-6" style={{ padding: "5px" }}>
          <div className="mainTitledataProfiling">
            Dataset Statistics
          </div>
          <OverviewRow title="Number of variables" value={overviewData.Num_variables} isRed = {false} />
          <OverviewRow title="Number of observations" value={overviewData.Num_observations} isRed = {false} />
          <OverviewRow title="Missing cells" value={overviewData.Num_missing_cells}  isRed = {false} />
          <OverviewRow title="Missing cells (%)" value={missingCellsPercantage} isRed = {false} />
          <OverviewRow title="Duplicate rows" value={overviewData.Num_duplicate_rows} isRed = {false} />
          <OverviewRow title="Duplicate rows (%)" value={duplicateRowsPercantage} isRed = {false} />
          <OverviewRow title="Total size in memory" value={totalSizeMemory} isRed = {false} />
        </div>

        <div className="col-12 col-md-6" style={{ padding: "5px" }}>
          <div className="mainTitledataProfiling">
            Variable Tables
          </div>          
          {overviewData.Categorical_types !== 0 && <OverviewRow title="Categorical Types" value={overviewData.Categorical_types} isRed = {false} /> }
          {overviewData.Numeric_types !== 0 && <OverviewRow title="Numeric Types" value={overviewData.Numeric_types} isRed = {false} /> }
          {overviewData.Boolean_types !== 0 && <OverviewRow title="Boolean Types " value={overviewData.Boolean_types} isRed = {false} /> }
          {overviewData.Date_types !== 0 && <OverviewRow title="Date Types" value={overviewData.Date_types} isRed = {false} /> }
        </div>
      </div>
    )
}

export default OverviewTab;