import React, { Component } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5hierarchy from "@amcharts/amcharts5/hierarchy";
class HomeGraph extends Component {
  componentDidMount() {
    var root = am5.Root.new("chartdiv");

    root.setThemes([am5themes_Animated.new(root)]);

    // Create wrapper container
    var container = root.container.children.push(
      am5.Container.new(root, {
        width: am5.percent(100),
        height: am5.percent(100),
        layout: root.verticalLayout,
      })
    );

    // Create series
    // https://www.amcharts.com/docs/v5/charts/hierarchy/#Adding
    var series = container.children.push(
      am5hierarchy.ForceDirected.new(root, {
        downDepth: 1,
        initialDepth: 1,
        topDepth: 0,
        valueField: "value",
        categoryField: "name",
        childDataField: "children",
        xField: "x",
        yField: "y",
        minRadius: 80,
        manyBodyStrength: -40
      })
    );

    // Set up labels
    series.labels.template.setAll({
      fill: am5.color('#ffffff'),
      oversizedBehavior: "fit"
    });

    const handleClickLeaf = (name) => {
      if (name !== "Data Governance") this.props.handleLeafClick(name);
    };

    series.nodes.template.events.on("click", function (ev) {
      handleClickLeaf(ev.target._dataItem.dataContext.name);
    });

    var data = {
      name: "Data Governance",
      nodeSettings: {
        fill: am5.color("#000"),
        fontSize: 30
      },
      children: [
        {
          name: "Data Stewardship",
          value:1,
          nodeSettings: {
            fill: am5.color("#ffff"),
          },
          children: [],
        },
        {
          name: "Data Ownership",
          value:1,
          nodeSettings: {
            fill: am5.color("#000"),
            fontSize: 30,
            minRadius: 10,
          },
        },
        {
          name: "Data Policies",
          value:1,
          nodeSettings: {
            fill: am5.color("#000"),
            fontSize: 30,
          },
        },
        {
          name: "MetaData Management",
          value:1,
          nodeSettings: {
            fill: am5.color("#ffff"),
            fontSize: 10,
          },
        },
      ],
    };

    series.data.setAll([data]);
    series.set("selectedDataItem", series.dataItems[0]);
    series.appear(1000, 100);
  }

  componentWillUnmount() {
    if (this.root) {
      this.root.dispose();
    }
  }

  render() {
    return <div id="chartdiv" style={{ width: "100%", height: "800px" }}></div>;
  }
}

export default HomeGraph;
