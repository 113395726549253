import { useEffect } from "react";
import { useState } from "react";
const MicrosoftSQLConnectionModal = (props) => {
  const [mode, setMode] = useState("");
  const [currentSettings, setCurrentSettings] = useState({
    Database_Type: "Microsoft SQL",
    Database_Name: "",
    Username: "",
    TrustServerCertificate: false,
    Encrypt: false,
    Driver: "",
    Connection_Timeout: "",
    Server: "",
    Password: "",
  });
  const { isLocal } = props;
  const [hidePassword, setHidePassword] = useState(true)
useEffect(() => {  
  setCurrentSettings((prevState) => {
    return {
      ...prevState,
      Database_Type: isLocal ? 'Microsoft SQL Local' : 'Microsoft SQL'
    };
  });
}, [isLocal] )

  return (
    <div class="row">
      <div class="col-12">
        <div class="card col-12">
          <div class="row card-body demo-vertical-spacing2 demo-only-element">
            <div className="col-6">
              <div class="form-password-toggle">
                <label class="form-label" for="basic-default-password12">
                  Driver
                </label>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    id="basic-default-password12"
                    aria-describedby="basic-default-password2"
                    value={currentSettings.Driver}
                    onChange={(e) => {
                      setCurrentSettings((prevState) => {
                        return {
                          ...prevState,
                          Driver: e.target.value,
                        };
                      });
                    }}
                  />
                </div>
              </div>

              <div class="form-password-toggle">
                <label class="form-label" for="basic-default-password12">
                  Server
                </label>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    id="basic-default-password12"
                    aria-describedby="basic-default-password2"
                    value={currentSettings.Server}
                    onChange={(e) => {
                      setCurrentSettings((prevState) => {
                        return {
                          ...prevState,
                          Server: e.target.value,
                        };
                      });
                    }}
                  />
                </div>
              </div>

              <div class="form-password-toggle">
                <label class="form-label" for="basic-default-password12">
                  Database
                </label>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    id="basic-default-password12"
                    aria-describedby="basic-default-password2"
                    value={currentSettings.Database_Name}
                    onChange={(e) => {
                      setCurrentSettings((prevState) => {
                        return {
                          ...prevState,
                          Database_Name: e.target.value,
                        };
                      });
                    }}
                  />
                </div>
              </div>

              <div class="form-password-toggle">
                <label class="form-label" for="basic-default-password12">
                  Connection Timeout
                </label>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    id="basic-default-password12"
                    aria-describedby="basic-default-password2"
                    disabled={isLocal == true}
                    value={currentSettings.Connection_Timeout}
                    onChange={(e) => {
                      setCurrentSettings((prevState) => {
                        return {
                          ...prevState,
                          Connection_Timeout: e.target.value,
                        };
                      });
                    }}
                  />
                </div>
              </div>

              <div class="form-password-toggle">
                <div class="input-group">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    disabled={isLocal == true}
                    checked={currentSettings.Encrypt}
                    onClick={(e) => {
                      setCurrentSettings((prevState) => {
                        return {
                          ...prevState,
                          Encrypt: e.target.checked,
                        };
                      });
                    }}
                  />
                  <label class="form-check-label">Encryption</label>
                </div>
              </div>

              <div class="form-password-toggle">
                <div class="input-group">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    disabled={isLocal == true}
                    checked={currentSettings.TrustServerCertificate}
                    onClick={(e) => {
                      setCurrentSettings((prevState) => {
                        return {
                          ...prevState,
                          TrustServerCertificate: e.target.checked,
                        };
                      });
                    }}
                  />
                  <label class="form-check-label">
                    Trust Server Certificate
                  </label>
                </div>
              </div>
            </div>

            <div className="col-6">
              <div class="form-password-toggle">
                <label class="form-label" for="basic-default-password12">
                Username
                </label>
                <div class="input-group">
                  <input
                    disabled={isLocal == true}
                    type="text"
                    class="form-control"
                    id="basic-default-password12"
                    aria-describedby="basic-default-password2"
                    value={currentSettings.Username}
                    onChange={(e) => {
                      setCurrentSettings((prevState) => {
                        return {
                          ...prevState,
                          Username: e.target.value,
                        };
                      });
                    }}
                  />
                </div>
              </div>

              <div class="form-password-toggle" style={{ marginTop: "46px" }}>
                <label class="form-label" for="basic-default-password12">
                  Password
                </label>
                <div class="input-group">
                  <input
                    disabled={isLocal === true}
                    type= {hidePassword? "password" : "text"}
                    class="form-control"
                    id="basic-default-password12"
                    placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                    aria-describedby="basic-default-password2"
                    value={currentSettings.Password}
                    onChange={(e) => {
                      setCurrentSettings((prevState) => {
                        return {
                          ...prevState,
                          Password: e.target.value,
                        };
                      });
                    }}
                  />
                  <span
                    id="basic-default-password2"
                    class="input-group-text cursor-pointer"
                    onClick={()=>{
                      setHidePassword(!hidePassword)
                    }}
                  >
                    <i class="bx bx-hide"></i>
                  </span>
                </div>
              </div>
              <div className="actionsButtons"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer" style={{ marginTop: "20px" }}>
        <button
          type="button"
          class="btn btn-outline-secondary"
          data-bs-dismiss="modal"
          onClick={() => { props.setShowModal(false)}}
        >
          Close
        </button>
        <button
          type="button"
          class="btn btn-primary"
          onClick={() => {
            props.onSubmit(currentSettings);
          }}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default MicrosoftSQLConnectionModal;
