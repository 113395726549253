/**
 * @author Alexandros Andreou
 * @version 0.0.1
 * Dashboard Page
 */

import CanvasJSReact from "../../../canvasjs.react";

import HeaderWithoutUser from "../../Header/HeaderWithoutUser.component";
import { useNavigate } from "react-router-dom";
import DataDimensionsGraph from "./DataDimensionsGraph.component";
import { useEffect } from "react";
import { useState } from "react";

var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const DataDimensionsResults = (props) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (props.dashboardMainPagefirstTimeComponentLoaded == false) {
      props.refreshGraph("DimensionsResults");
    }
  }, [props.bussinesCtx.dataDimensionsResults]);

  let result =
    props.bussinesCtx.dataDimensionsResults
      .map((d) => d.Result)
      .reduce((partialSum, a) => partialSum + a, 0) /
    props.bussinesCtx.dataDimensionsResults.filter((d) => d.Result != 0).length;
  const options2 = {
    animationEnabled: true,
    title: {
      text: "Data Quality per Customer Area",
    },
    data: [
      {
        type: "column",
        dataPoints: props.bussinesCtx.dataDimensionsResults.map((d) => ({
          label: d.Dimension,
          y: d.Result,
        })),
      },
    ],
  };

  return (
    <>
      <HeaderWithoutUser title="Data Dimensions" />
      <div>
        <button
          type="button"
          class="btn btn-outline-primary"
          style={{ marginTop: "20px", marginLeft: "12px" }}
          // onClick={() => {
          //   props.onDeleteDataQualityRuleButtonClick(
          //     props.dataQualityRuleItem.Id
          //   );
          // }}
          onClick={() => {
            props.setConfirmationModalImportDataDimensions(true);
          }}
        >
          <span style={{ marginRight: "5px" }}>Re-Calculate</span>
          <i class="bx bxs-calculator"></i>
        </button>
      </div>
      <div class="d-flex flex-column align-items-center gap-1">
        <h2 class="mb-2">
          {!isNaN((Math.round(result * 100) / 100).toFixed(2))
            ? (Math.round(result * 100) / 100).toFixed(2) + "%"
            : "Create Some Data Quality Rules and Re-Calculate"}
        </h2>
        <span>Data Quality Score</span>
        {/* <h3 class="mb-2">Data Domains</h3> */}
      </div>
      <div className="container-xxl flex-grow-1 container-p-y">
        <div class="row">
          <div class="col-lg-10 mb-4 order-0">
            <div class="card">
              <div class="card-body">
                <div class="card-title d-flex align-items-start justify-content-between">
                  <div class="avatar flex-shrink-0">
                    <img
                      src="../static/assets/img/icons/unicons/document.png"
                      alt="chart success"
                      class="rounded"
                    />
                  </div>
                  <div class="dropdown">
                    <button
                      class="btn p-0"
                      type="button"
                      id="cardOpt3"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    ></button>
                  </div>
                </div>
                {/* <CanvasJSChart options={options2} /> */}
                <DataDimensionsGraph
                  data={props.bussinesCtx.dataDimensionsResults.map((d) => ({
                    label: d.Dimension,
                    y: d.Result,
                  }))}
                  GraphId="DataDimensionGraph"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-2 mb-4 order-1">
            <div class="row">
              <div class="col-lg-12 col-4 mb-2">
                <div class="card">
                  <div class="card-body">
                    <div class="card-title d-flex align-items-start justify-content-between">
                      <div class="avatar flex-shrink-0">
                        <img
                          src="../static/assets/img/icons/unicons/cc-primary.png"
                          alt="chart success"
                          class="rounded"
                        />
                      </div>
                    </div>
                    <span class="fw-semibold d-block mb-1">
                      Data Dimensions
                    </span>
                    <h3 class="card-title mb-2">6</h3>
                  </div>
                </div>
              </div>
              <div class="col-lg-12 col-4 mb-2">
                <div class="card">
                  <div class="card-body">
                    <div class="card-title d-flex align-items-start justify-content-between">
                      <div class="avatar flex-shrink-0">
                        <img
                          src="../static/assets/img/icons/unicons/cc-success.png"
                          alt="chart success"
                          class="rounded"
                        />
                      </div>
                      <div class="dropdown">
                        <button
                          class="btn p-0"
                          type="button"
                          id="cardOpt3"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i class="bx bx-dots-vertical-rounded"></i>
                        </button>
                        <div
                          class="dropdown-menu dropdown-menu-end"
                          aria-labelledby="cardOpt3"
                        >
                          <a
                            onClick={() => {
                              setTimeout(() => {
                                navigate("/employee");
                              }, 100);
                            }}
                            class="dropdown-item"
                            href="javascript:void(0);"
                          >
                            View More
                          </a>
                        </div>
                      </div>
                    </div>
                    <span class="fw-semibold d-block mb-1">Employees</span>
                    <h3 class="card-title mb-2">
                      {props.bussinesCtx.employees.length}
                    </h3>
                  </div>
                </div>
              </div>
              <div class="col-lg-12 col-4 mb-2">
                <div class="card">
                  <div class="card-body">
                    <div class="card-title d-flex align-items-start justify-content-between">
                      <div class="avatar flex-shrink-0">
                        <img
                          src="../static/assets/img/icons/unicons/cc-warning.png"
                          alt="chart success"
                          class="rounded"
                        />
                      </div>
                      <div class="dropdown">
                        <button
                          class="btn p-0"
                          type="button"
                          id="cardOpt3"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i class="bx bx-dots-vertical-rounded"></i>
                        </button>
                        <div
                          class="dropdown-menu dropdown-menu-end"
                          aria-labelledby="cardOpt3"
                        >
                          <a
                            onClick={() => {
                              setTimeout(() => {
                                navigate("/businessMetadata");
                              }, 100);
                            }}
                            class="dropdown-item"
                            href="javascript:void(0);"
                          >
                            View More
                          </a>
                        </div>
                      </div>
                    </div>
                    <span class="fw-semibold d-block mb-1">Data Stewards</span>
                    <h3 class="card-title mb-2">
                      {
                        new Set(
                          props.bussinesCtx.businessMetadata
                            .map((b) => b.Data_Steward)
                            .filter((d) => d != "" && d !== null)
                        ).size
                      }
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-4 mb-4 order-0">
            <div class="card">
              <div class="card-body">
                <div class="card-title d-flex align-items-start justify-content-between">
                  <div class="avatar flex-shrink-0">
                    <img
                      src="../static/assets/img/icons/unicons/chart.png"
                      alt="chart success"
                      class="rounded"
                    />
                  </div>
                  <div class="dropdown">
                    <button
                      class="btn p-0"
                      type="button"
                      id="cardOpt3"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i class="bx bx-dots-vertical-rounded"></i>
                    </button>
                    <div
                      class="dropdown-menu dropdown-menu-end"
                      aria-labelledby="cardOpt3"
                    >
                      <a
                        onClick={() => {
                          setTimeout(() => {
                            navigate("/businessRules");
                          }, 100);
                        }}
                        class="dropdown-item"
                        href="javascript:void(0);"
                      >
                        View More
                      </a>
                    </div>
                  </div>
                </div>
                <span class="fw-semibold d-block mb-1">Business Rules</span>
                <h3 class="card-title mb-2">
                  {props.bussinesCtx.businessRulesItems.length}
                </h3>
              </div>
            </div>
          </div>
          <div class="col-lg-4 mb-4 order-0">
            <div class="card">
              <div class="card-body">
                <div class="card-title d-flex align-items-start justify-content-between">
                  <div class="avatar flex-shrink-0">
                    <img
                      src="../static/assets/img/icons/unicons/chart.png"
                      alt="chart success"
                      class="rounded"
                    />
                  </div>
                  <div class="dropdown">
                    <button
                      class="btn p-0"
                      type="button"
                      id="cardOpt3"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i class="bx bx-dots-vertical-rounded"></i>
                    </button>
                    <div
                      class="dropdown-menu dropdown-menu-end"
                      aria-labelledby="cardOpt3"
                    >
                      <a
                        onClick={() => {
                          setTimeout(() => {
                            navigate("/dataQualityRules");
                          }, 100);
                        }}
                        class="dropdown-item"
                        href="javascript:void(0);"
                      >
                        View More
                      </a>
                    </div>
                  </div>
                </div>
                <span class="fw-semibold d-block mb-1">Data Quality Rules</span>
                <h3 class="card-title mb-2">
                  {props.bussinesCtx.dataQualityRules.length}
                </h3>
              </div>
            </div>
          </div>
          <div class="col-lg-4 mb-4 order-0">
            <div class="card">
              <div class="card-body">
                <div class="card-title d-flex align-items-start justify-content-between">
                  <div class="avatar flex-shrink-0">
                    <img
                      src="../static/assets/img/icons/unicons/chart.png"
                      alt="chart success"
                      class="rounded"
                    />
                  </div>
                  <div class="dropdown">
                    <button
                      class="btn p-0"
                      type="button"
                      id="cardOpt3"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i class="bx bx-dots-vertical-rounded"></i>
                    </button>
                    <div
                      class="dropdown-menu dropdown-menu-end"
                      aria-labelledby="cardOpt3"
                    >
                      <a
                        onClick={() => {
                          setTimeout(() => {
                            navigate("/businessMetadata");
                          }, 100);
                        }}
                        class="dropdown-item"
                        href="javascript:void(0);"
                      >
                        View More
                      </a>
                    </div>
                  </div>
                </div>
                <span class="fw-semibold d-block mb-1">Tables</span>
                <h3 class="card-title mb-2">
                  {props.bussinesCtx.businessMetadata.length}
                </h3>
              </div>
            </div>
          </div>
        </div>
        {/* <div class="row">
           <div class="col-lg-12 mb-4 order-0">
             <div class="card">
               <div class="card-body">
                 <div class="card-title d-flex align-items-top justify-content-between">
                   <div class="avatar flex-shrink-0">
                     <img
                       src="../static/assets/img/icons/unicons/clipboard.png"
                       alt="chart success"
                       class="rounded"
                     />
                   </div>
                   <div class="d-flex flex-column align-items-center gap-1">
                     <h2 class="mb-2">78.25%</h2>
                     <span>Data Quality Score</span>
                     <h3 class="mb-2">Customer</h3>
                   </div>
                   <div class="dropdown">
                     <button
                       class="btn p-0"
                       type="button"
                       id="cardOpt3"
                       data-bs-toggle="dropdown"
                       aria-haspopup="true"
                       aria-expanded="false"
                     >
                       <i class="bx bx-dots-vertical-rounded"></i>
                     </button>
                     <div
                       class="dropdown-menu dropdown-menu-end"
                       aria-labelledby="cardOpt3"
                     >
                       <a class="dropdown-item" href="javascript:void(0);">
                         View More
                       </a>
                     </div>
                   </div>
                 </div>
                 <div class="card-title d-flex align-items-start justify-content-between">
                   <div class="avatar flex-shrink-0"></div>
                 </div>
                 <CanvasJSChart options={options} />
               </div>
             </div>
           </div>
         </div> */}
      </div>
    </>
  );
};

export default DataDimensionsResults;
