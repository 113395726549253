import { useState, useEffect, useRef } from "react";
import PasswordInput from "../../SmallComponents/PasswordInput.component";

const PostgreSQLConnection = (props) => {
  const passwordRef = useRef(null);
  const [mode, setMode] = useState("readOnly");
  //const [isLocal, setIslocal] = useState(false);
  const [currentSettings, setCurrentSettings] = useState({
    Database_Type: "Oracle",
    Database_Name: "",
    Username: "",
    Password: "",
    DSN: "",
    Port: "",
    Encoding: "",
  });

  const { selectedConnectionstring } = props;
  useEffect(() => {
    if (props.selectedConnectionstring) setCurrentSettingsAsProps();

    setMode("readOnly");
  }, [selectedConnectionstring]);

  const cancelEdit = () => {
    setCurrentSettingsAsProps();
    setMode("readOnly");
    passwordRef.current.forceHidePassword();
  };

  const setCurrentSettingsAsProps = () => {
    setCurrentSettings({
      Id: selectedConnectionstring.Id,
      Database_Name: selectedConnectionstring.Database_Name,
      Database_Type: selectedConnectionstring.Database_Type,
      Username: selectedConnectionstring.Username,
      Password: selectedConnectionstring.Password,
      Host: selectedConnectionstring.Host,
      Port: selectedConnectionstring.Port,
    });
  };

  const saveEditedDatabase = () => {
    setMode("readOnly");
    props.submitEditedDatabaseConnection(currentSettings);
    passwordRef.current.forceHidePassword();
  };

  return (
    <div class="row inputLayout">
      <div class="col-12">
        <div class="card col-12">
          <div class="row card-body demo-vertical-spacing2 demo-only-element">
            <div className="col-6">
              <div class="form-password-toggle">
                <label class="form-label" for="basic-default-password12">
                  Database Type
                </label>

                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    id="basic-default-password12"
                    aria-describedby="basic-default-password2"
                    disabled
                    value={currentSettings.Database_Type}
                    onChange={(e) => {
                      setCurrentSettings((prevState) => {
                        return {
                          ...prevState,
                          Database_Type: e.target.value,
                        };
                      });
                    }}
                  />
                  <i class="bx bx-chevron-down dropdownDatabaseTypeIcon"></i>
                </div>
              </div>

              <div class="form-password-toggle">
                <label class="form-label" for="basic-default-password12">
                  Database Name
                </label>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    id="basic-default-password12"
                    aria-describedby="basic-default-password2"
                    value={currentSettings.Database_Name}
                    disabled={mode === "readOnly"}
                    onChange={(e) => {
                      setCurrentSettings((prevState) => {
                        return {
                          ...prevState,
                          Database_Name: e.target.value,
                        };
                      });
                    }}
                  />
                </div>
              </div>

              <div class="form-password-toggle">
                <label class="form-label" for="basic-default-password12">
                  Host
                </label>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    id="basic-default-password12"
                    aria-describedby="basic-default-password2"
                    value={currentSettings.Host}
                    disabled={mode === "readOnly"}
                    onChange={(e) => {
                      setCurrentSettings((prevState) => {
                        return {
                          ...prevState,
                          Host: e.target.value,
                        };
                      });
                    }}
                  />
                </div>
              </div>

              <div class="form-password-toggle">
                <label class="form-label" for="basic-default-password12">
                  Port
                </label>
                <div class="input-group">
                  <input
                    type="number"
                    class="form-control"
                    id="basic-default-password12"
                    aria-describedby="basic-default-password2"
                    value={currentSettings.Port}
                    disabled={mode === "readOnly"}
                    onChange={(e) => {
                      setCurrentSettings((prevState) => {
                        return {
                          ...prevState,
                          Port: e.target.value,
                        };
                      });
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="col-6">
              <div class="form-password-toggle">
                <label class="form-label" for="basic-default-password12">
                  Username
                </label>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    id="basic-default-password12"
                    aria-describedby="basic-default-password2"
                    value={currentSettings.Username}
                    disabled={mode === "readOnly"}
                    onChange={(e) => {
                      setCurrentSettings((prevState) => {
                        return {
                          ...prevState,
                          Username: e.target.value,
                        };
                      });
                    }}
                  />
                </div>
              </div>

              <div class="form-password-toggle">
                <label class="form-label" for="basic-default-password12">
                  Password
                </label>
                <div class="input-group">
                  <PasswordInput
                    ref={passwordRef}
                    value={currentSettings.Password}
                    disabled={mode === "readOnly"}
                    canChange={mode == "edit"}
                    onChange={(e) => {
                      setCurrentSettings((prevState) => {
                        return {
                          ...prevState,
                          Password: e.target.value,
                        };
                      });
                    }}
                  />
                </div>
              </div>

              <div className="actionsButtons">
                {mode === "readOnly" && (
                  <button
                    type="button"
                    class="btn btn-outline-primary bottom_right marginLeft"
                    onClick={() => setMode("edit")}
                  >
                    Edit
                  </button>
                )}
                {mode === "edit" && (
                  <button
                    type="button"
                    class="btn btn-outline-primary bottom_right marginLeft"
                    onClick={cancelEdit}
                  >
                    Cancel
                  </button>
                )}
                {mode === "edit" && (
                  <button
                    type="button"
                    class="btn btn-outline-primary bottom_right marginLeft"
                    //onClick={saveEditedDatabase}
                    onClick={() => {
                      saveEditedDatabase()
                      //props.setIsLoading(true)

                    }}
                  >
                    Save
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostgreSQLConnection;
