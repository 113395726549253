/**
 * @author Alexandros Andreou
 * @version 0.0.1
 * BusinessMetadata Modal
 */

import { useEffect, useState } from "react";

const BusinessMetadataModal = (props) => {
  const [bmetadata, setbmetadata] = useState({
    Id: "",
    Table: "",
    Data_Domain: "",
    Criticality: "",
    Retention_Policy: "",
    Security_Classification: "",
    Data_Steward: "",
  });
  useEffect(() => {
    setbmetadata(props.bmetadata);
  }, [props.bmetadata]);

  return (
    <>
      <div id="BackDropModalLayout" />
      <div
        className="modal fade show"
        id="backDropModal"
        data-bs-backdrop="static"
        tabindex="-1"
        style={{ display: "block" }}
        aria-modal="true"
        role="dialog"
      >
        <div class="modal-dialog">
          <form class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="backDropModalTitle">
                {props.title} Business Metadata
              </h5>
              <button
                onClick={props.onClose}
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col mb-3">
                  <label for="nameBackdrop" class="form-label">
                    Table
                  </label>
                  <input
                    onChange={(e) => {
                      setbmetadata((prevState) => {
                        return {
                          ...prevState,
                          Table: e.target.value,
                        };
                      });
                    }}
                    value={bmetadata.Table}
                    type="text"
                    id="nameBackdrop"
                    class="form-control"
                    placeholder="Enter Table"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col mb-3">
                  <div>
                    <label for="nameBackdrop" class="form-label">
                      Data_Domain
                    </label>
                  </div>
                  <div>
                    <select
                      class="form-select form-select-display"
                      id="exampleFormControlSelect1"
                      aria-label="Default select example"
                      style={{ width: "100%" }}
                      onChange={props.onChangeDataDomain}
                    >
                      <option selected>Select Data Domain</option>
                      {props.dataDomains &&
                        props.dataDomains.map((dd) => {
                          return (
                            <option
                              selected={
                                props.bmetadata.Data_Domain ===
                                dd.DataDomainName
                              }
                              value={dd.DataDomainName}
                            >
                              {dd.DataDomainName}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col mb-3">
                  <div>
                    <label for="nameBackdrop" class="form-label">
                      Criticality
                    </label>
                  </div>
                  <div>
                    <select
                      class="form-select form-select-display"
                      id="exampleFormControlSelect1"
                      aria-label="Default select example"
                      onChange={props.onChangeCriticality}
                      style={{ width: "100%" }}
                    >
                      <option selected>Select an option</option>
                      <option value="Low" selected={props.bmetadata.Criticality === 'Low'}>Low</option>
                      <option value="Medium" selected={props.bmetadata.Criticality === 'Medium'}>Medium</option>
                      <option value="High" selected={props.bmetadata.Criticality === 'High'}>High</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col mb-3">
                  <div>
                    <label for="nameBackdrop" class="form-label">
                      Retention_Policy
                    </label>
                  </div>
                  <div>
                    <select
                      class="form-select form-select-display"
                      id="exampleFormControlSelect1"
                      aria-label="Default select example"
                      onChange={props.onChangeRetentionPolicy}
                      style={{ width: "100%" }}
                    >
                      <option selected>Select an option</option>
                      <option value="5 Years" selected={props.bmetadata.Retention_Policy === '5 Years'}>5 Years</option>
                      <option value="7 Years" selected={props.bmetadata.Retention_Policy === '7 Years'}>7 Years</option>
                      <option value="Until Erasure Is Requested" selected={props.bmetadata.Retention_Policy === 'Until Erasure Is Requested'}>
                        Until Erasure Is Requested
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col mb-3">
                  <div>
                    <label for="nameBackdrop" class="form-label">
                      Security_Classification
                    </label>
                  </div>
                  <div>
                    <select
                      class="form-select form-select-display"
                      id="exampleFormControlSelect1"
                      aria-label="Default select example"
                      onChange={props.onChangeSecurityClassification}
                      style={{ width: "100%" }}
                    >
                      <option selected>Select an option</option>
                      <option value="Public" selected={props.bmetadata.Security_Classification === 'Public'}>Public</option>
                      <option value="Internal" selected={props.bmetadata.Security_Classification === 'Internal'}>Internal</option>
                      <option value="Highly Confidential" selected={props.bmetadata.Security_Classification === 'Highly Confidential'}>Highly Confidential</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col mb-3">
                  <div>
                    <label for="nameBackdrop" class="form-label">
                      Data_Steward
                    </label>
                  </div>
                  <select
                    class="form-select form-select-display"
                    id="exampleFormControlSelect1"
                    aria-label="Default select example"
                    style={{ width: "100%" }}
                    onChange={props.onChangeDataSteward}
                  >
                    <option value="" selected>Select Data Steward</option>
                    {props.employees &&
                      props.employees.map((ee) => {
                        return (
                          <option
                            selected={
                              props.bmetadata.Data_Steward === ee.EmployeeName
                            }
                            value={ee.EmployeeName}
                          >
                            {ee.EmployeeName}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-outline-secondary"
                data-bs-dismiss="modal"
                onClick={props.onClose}
              >
                Close
              </button>
              <button
                type="button"
                class="btn btn-primary"
                onClick={() => {
                  props.onSave(bmetadata);
                }}
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default BusinessMetadataModal;
