import { useEffect, useState } from "react";
import BussinesRuleVersion from "./BussinesRuleVersion.component";

const BusinessRulesItem = (props) => {
  const [seeAll, setSeeAll] = useState(false);
  const [hasActiveVersion, setHasActiveVersion] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      if (props.businessRulesItemData.Versions) {
        let _activeVersions = props.businessRulesItemData.Versions.filter(
          (version) => version.IsActiveVersion == true
        );
        if (_activeVersions.length > 0) setHasActiveVersion(true);
      }
    }, 100);
  }, [props]);

  return (
    <div class="row ">
      <div class="col-lg-12 mb-4 order-0">
        <div class="card">
          <div class="row padding20">
            <div class="col-8" style={{ marginBottom: "55px" }}>
              <div class="displayInlineBlock" style={{ marginTop: "10px" }}>
                <i class="bx bxs-book bookIcon"></i>
              </div>
              <div class="displayInlineBlock">
                <div
                  style={{
                    position: "absolute",
                    top: "0px",
                    marginLeft: "5px",
                    marginTop: "10px",
                  }}
                >
                  {props.businessRulesItemData.RuleName}
                </div>
              </div>

              <div
                class="displayInlineBlock"
                style={{
                  position: "absolute",
                  top: "40px",
                  left: "56px",
                  fontSize: "13px",
                }}
              >
                Data Domain: {props.businessRulesItemData.Domain}{" "}
              </div>

              <div
                class="displayInlineBlock"
                style={{
                  position: "absolute",
                  top: "60px",
                  left: "56px",
                  fontSize: "13px",
                }}
              >
                Dimension: {props.businessRulesItemData.Dimension}{" "}
              </div>
            </div>
            <div class="col-4 textAlingRight" style={{ marginBottom: "10px" }}>
              {/* <div>
                {props.businessRulesItemData.Versions?.length && (
                  <span class="versionsCounter">{`${
                    props.businessRulesItemData.Versions.length
                  } ${
                    props.businessRulesItemData.Versions?.length === 1
                      ? "version"
                      : "versions"
                  }`}</span>
                )}

                <i
                  class={`bx bxs-star ${
                    props.businessRulesItemData.Favourite ? "isFavorite" : ""
                  }`}
                ></i>
              </div> */}
            </div>
          </div>
          <hr />
          {hasActiveVersion &&
            props.businessRulesItemData.Versions &&
            props.businessRulesItemData.Versions.map((version) => {
              if (seeAll)
                return (
                  <BussinesRuleVersion
                    versionData={version}
                    onDeleteVersionButtonClick={
                      props.onDeleteVersionButtonClick
                    }
                    BussinesRuleId={props.businessRulesItemData.Id}
                    onNewVersionButtonClick={props.onNewVersionButtonClick}
                  />
                );
              else if (!seeAll && version.IsActiveVersion)
                return (
                  <BussinesRuleVersion
                    versionData={version}
                    onDeleteVersionButtonClick={
                      props.onDeleteVersionButtonClick
                    }
                    BussinesRuleId={props.businessRulesItemData.Id}
                    onNewVersionButtonClick={props.onNewVersionButtonClick}
                  />
                );
              else return <></>;
            })}

          {!hasActiveVersion &&
            props.businessRulesItemData.Versions?.length && (
              <BussinesRuleVersion
                versionData={props.businessRulesItemData.Versions[0]}
                onDeleteVersionButtonClick={props.onDeleteVersionButtonClick}
                BussinesRuleId={props.businessRulesItemData.Id}
                onNewVersionButtonClick={props.onNewVersionButtonClick}
              />
            )}

          {!seeAll && props.businessRulesItemData.Versions?.length > 1 && (
            <div class="row padding20 borderBottom">
              <div class="col-12 textAlingCenter marginBottom">
                <div class="row">
                  <div class="col-12">
                    <div class="displayInlineBlock">
                      <span
                        class="subText"
                        style={{ cursor: "pointer" }}
                        onClick={() => setSeeAll(true)}
                      >
                        Show more versions
                      </span>
                    </div>
                    <div class="displayInlineBlock">
                      <span class="subText" style={{ cursor: "pointer" }}>
                        Delete All
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {seeAll && props.businessRulesItemData.Versions?.length > 1 && (
            <div class="row padding20 borderBottom">
              <div class="col-12 textAlingCenter marginBottom">
                <div class="row">
                  <div class="col-12">
                    <div class="displayInlineBlock">
                      <span
                        class="subText"
                        style={{ cursor: "pointer" }}
                        onClick={() => setSeeAll(false)}
                      >
                        Hide inactive versions
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BusinessRulesItem;
