
import { useState } from "react";
const PostgreSQLConnectionAddNew = (props) => {
  const [currentSettings, setCurrentSettings] = useState({
    Database_Type: "PostgreSQL",
    Host: "",
    Database_Name: "",
    Port: "",
    Username: "",
    TrustServerCertificate: false,
    Encrypt: false,
    Password: "",
  });
  const { isLocal } = props;
  const [hidePassword, setHidePassword] = useState(true)

  return (
    <div class="row">
      <div class="col-12">
        <div class="card col-12">
          <div class="row card-body demo-vertical-spacing2 demo-only-element">
            <div className="col-6">
              <div class="form-password-toggle">
                <label class="form-label" for="basic-default-password12">
                  Host
                </label>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    id="basic-default-password12"
                    aria-describedby="basic-default-password2"
                    value={currentSettings.Host}
                    onChange={(e) => {
                      setCurrentSettings((prevState) => {
                        return {
                          ...prevState,
                          Host: e.target.value,
                        };
                      });
                    }}
                  />
                </div>
              </div>

              <div class="form-password-toggle">
                <label class="form-label" for="basic-default-password12">
                  Database
                </label>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    id="basic-default-password12"
                    aria-describedby="basic-default-password2"
                    value={currentSettings.Database_Name}
                    onChange={(e) => {
                      setCurrentSettings((prevState) => {
                        return {
                          ...prevState,
                          Database_Name: e.target.value,
                        };
                      });
                    }}
                  />
                </div>
              </div>

              <div class="form-password-toggle">
                <label class="form-label" for="basic-default-password12">
                  Port
                </label>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    id="basic-default-password12"
                    aria-describedby="basic-default-password2"
                    value={currentSettings.Port}
                    onChange={(e) => {
                      setCurrentSettings((prevState) => {
                        return {
                          ...prevState,
                          Port: e.target.value,
                        };
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-6">
              <div class="form-password-toggle">
                <label class="form-label" for="basic-default-password12">
                  User
                </label>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    id="basic-default-password12"
                    aria-describedby="basic-default-password2"
                    disabled={isLocal === true}
                    value={currentSettings.Username}
                    onChange={(e) => {
                      setCurrentSettings((prevState) => {
                        return {
                          ...prevState,
                          Username: e.target.value,
                        };
                      });
                    }}
                  />
                </div>
              </div>

              <div class="form-password-toggle" style={{ marginTop: "46px" }}>
                <label class="form-label" for="basic-default-password12">
                  Password
                </label>
                <div class="input-group">
                  <input
                    disabled={isLocal === true}
                    type= {hidePassword? "password" : "text"}
                    class="form-control"
                    id="basic-default-password12"
                    placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                    aria-describedby="basic-default-password2"
                    value={currentSettings.Password}
                    onChange={(e) => {
                      setCurrentSettings((prevState) => {
                        return {
                          ...prevState,
                          Password: e.target.value,
                        };
                      });
                    }}
                  />
                  <span
                    id="basic-default-password2"
                    class="input-group-text cursor-pointer"
                    onClick={()=>{
                      setHidePassword(!hidePassword)
                    }}
                  >
                    <i class="bx bx-hide"></i>
                  </span>
                </div>
              </div>

              <div className="actionsButtons"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer" style={{ marginTop: "20px" }}>
        <button
          type="button"
          class="btn btn-outline-secondary"
          data-bs-dismiss="modal"
          onClick={() => {
            props.setShowModal(false);
          }}
        >
          Close
        </button>
        <button
          type="button"
          class="btn btn-primary"
          onClick={() => {
            props.onSubmit(currentSettings);
          }}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default PostgreSQLConnectionAddNew;
