/**
 * @author Alexandros Andreou
 * @version 0.0.1
 * Employees Modal
 */

import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const EmployeeModal = (props) => {
  const [employee, setEmployee] = useState({
    EmployeeId: "",
    EmployeeName: "",
    Department_id: "",
    DateOfJoining: "",
  });
  useEffect(() => {
    setEmployee(props.employee);
  }, [props.employee]);
  const [startDate, setStartDate] = useState(new Date());
  return (
    <>
      <div id="BackDropModalLayout" />
      <div
        className="modal fade show"
        id="backDropModal"
        data-bs-backdrop="static"
        tabindex="-1"
        style={{ display: "block" }}
        aria-modal="true"
        role="dialog"
      >
        <div class="modal-dialog">
          <form class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="backDropModalTitle">
                {props.title} Employee
              </h5>
              <button
                onClick={props.onClose}
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col mb-3">
                  <label for="nameBackdrop" class="form-label">
                    Name
                  </label>
                  <input
                    onChange={(e) => {
                      setEmployee((prevState) => {
                        return {
                          ...prevState,
                          EmployeeName: e.target.value,
                        };
                      });
                    }}
                    value={employee.EmployeeName}
                    type="text"
                    id="nameBackdrop"
                    class="form-control"
                    placeholder="Enter Name"
                  />
                </div>
              </div>
              <div class="row">
                <label for="nameBackdrop" class="form-label">
                  Data Domains
                </label>
                <select
                  class="form-select form-select-display data_domains_select"
                  id="exampleFormControlSelect1"
                  aria-label="Default select example"
                  style={{ width: "50%" }}
                  onChange={(e) => {
                    setEmployee((prevState) => {
                      return {
                        EmployeeId: prevState.EmployeeId,
                        EmployeeName: prevState.EmployeeName,
                        Department_id: +e.target.value,
                        DateOfJoining: prevState.DateOfJoining,
                      };
                    });
                  }}
                >
                  <option selected>Select Department</option>
                  {props.departments &&
                    props.departments.map((dep) => {
                      return (
                        <option
                          selected={
                            props.selectedEmployee.Department_id ===
                            dep.DepartmentId
                          }
                          value={dep.DepartmentId}
                        >
                          {dep.DepartmentName}
                        </option>
                      );
                    })}
                </select>
                </div>

                <div class="col mb-3">
                  <label for="dobBasic" class="form-label">
                    DOJ
                  </label>
                  <DatePicker
                    className="form-control"
                    selected={Date.parse(employee.DateOfJoining)}
                    onChange={(date) => {
                      const newDate = date
                        .toLocaleDateString("ko-KR")
                        .replaceAll(". ", "-")
                        .replace(".", "");
                      setEmployee((prevState) => {
                        return {
                          ...prevState,
                          DateOfJoining: newDate,
                        };
                      });
                    }}
                  />
                  {/* <input
                    type="text"
                    id="dobBasic"
                    class="form-control"
                    placeholder="YY-MM-DD"
                    value={employee.doj}
                    onChange={(e) => {
                      setEmployee((prevState) => {
                        return {
                          ...prevState,
                          doj: e.target.value,
                        };
                      });
                    }}
                  /> */}
                </div>
              </div>
            
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-outline-secondary"
                data-bs-dismiss="modal"
                onClick={props.onClose}
              >
                Close
              </button>
              <button
                type="button"
                class="btn btn-primary"
                onClick={() => {
                  props.onSave(employee);
                }}
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default EmployeeModal;
