import HeaderWithoutUser from "../../Header/HeaderWithoutUser.component";
import logoPng from "../../../img/3.svg";
const MetadataManagement = (props) => {
  return (
    <>
      <HeaderWithoutUser title="Metadata Management" left="40%" />
      <div className="container-xxl flex-grow-1 container-p-y">
        <div className="row">
          <div className="col-lg-12 mb-12 order-0">
            <div className="card">
              {/* <h5 className="card-header">Metadata Management</h5> */}
              <div className="card-body">
                <div className="row">
                  <div
                    className="col-12 col-lg-6"
                    style={{ textAlign: "justify" }}
                  >
                    <h6>What exactly is Metadata?</h6>
                    Managing metadata begins with accurately defining metadata.
                    Some people refer to metadata as "data about data," but that
                    is just part of the story. Some people think of metadata as
                    "what identifies data." Another partial definition. In
                    practice, metadata is much more than just a data
                    description. Every day, metadata takes on new functions as
                    data complexity grows. Metadata may be about the business
                    viewpoint of quarterly sales in some circumstances. In other
                    circumstances, it may define data warehouse source-to-target
                    mappings.
                    <br />
                    The context is then everything. The context metadata conveys
                    is both business and technical, which aids in understanding
                    and application of the data.
                    <br />
                    <br />
                    <ul>
                      <li>
                        Technical Metadata: Information on the data's format and
                        structure, such as data models, data lineage, or access
                        rights.
                      </li>
                      <li>
                        Table and column definitions, business rules, data
                        sharing rules, and data quality rules are examples of
                        business metadata.
                      </li>
                    </ul>
                    <h6>What is the significance of metadata management?</h6>
                    Metadata management is crucial because metadata may be used
                    to interpret, aggregate, group, and sort data for purpose.
                    Many data quality issues can also be traced back to
                    metadata. "Quality metadata helps everything we do easier,
                    from internal communications to designing new apps to making
                    smarter decisions," says the CEO of an energy company.
                    <br />
                    <br />
                    Because of the following, organizations must include
                    metadata management in their data management practices:
                    <br />
                    <br />
                    Data governance, legal and compliance standards, and data
                    enablement are becoming more and more important.
                    <br />
                    <br />
                    More urgent need to speed up transformational initiatives
                    including digitization, omnichannel support, and data
                    modernization
                    <br />
                    <br />
                    Higher data quality and reliable analytics are becoming
                    increasingly important for generating corporate value from
                    data.
                    <br />
                    <br />
                    <ul>
                      <li>
                        data complexity is increasing, and new sources are being
                        added to the traditional sources.
                      </li>
                      <li>more commercial users engaging with data actively</li>
                    </ul>
                    <br />
                  </div>
                  <div className="col-12 col-lg-6">
                    <img
                      style={{ marginTop: "30%" }}
                      className="infoImg"
                      src={logoPng}
                      alt="Metadata Management image"
                    ></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          type="button"
          class="btn btn-outline-primary addDeparmentButton"
          onClick={props.backButton}
        >
          Back
        </button>
      </div>
    </>
  );
};

export default MetadataManagement;
