import { useState } from "react";
import ConfirmationModal from "../../Modal/Confirmation.modal";
import DeparmentModal from "./Deparments.modal";
import MainGrid from "../../Grid/MainGrid.component";

const DepartmentsTab = (props) => {
  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <div className="row">
        <div className="col-lg-12 mb-12 order-0">
          <div class="card">
            {/* <h5 class="card-header">Deparments</h5> */}
            <div class="table-responsive text-nowrap">
              <MainGrid
                dataScr={props.dataScr}
                onEdit={props.onEdit}
                onDelete={props.onDelete}
                columnsList={props.columnsList}
              />
            </div>
          </div>
        </div>
      </div>
      {props.showModal && (
        <DeparmentModal
          dataDomains = {props.dataDomains}
          title={props.modalMode}
          name={props.selectedDepartment.DepartmentName}
          id={props.selectedDepartment.DepartmentId}
          onClose={() => {
            props.setShowModal(false);
          }}
          onChangeName={props.onChangeNameOnModal}
          onChangeDataDomain = {props.onChangeDataDomainOnModal}
          onSave={props.modalSaveButtonHandler}
          data-testid="department-modal"
          selectedDepartment = {props.selectedD}
        />
      )}
      {props.confirmationModal && (
        <ConfirmationModal
          onConfirm={props.deleteDepartment}
          onClose={() => {
            props.setConfirmationModal(false);
          }}
        />
      )}

      <button
        type="button"
        class="btn btn-outline-primary addDeparmentButton"
        onClick={props.addDepartment}
        data-testid="department-modal-appeared"
      >
        Add a Deparment
      </button>
    </div>
  );
};

export default DepartmentsTab;
