/**
 * @author Alexandros Andreou
 * @version 0.0.1
 * Data Quality Issues Page
 */

import { useState, useEffect } from "react";
import ConfirmationModal from "../../Modal/Confirmation.modal";
import Header from "../../Header/Header.component";
import AddNewDataQualityRuleModal from "./AddNewDataQualityRuleModal.component";
import DataQualityRuleItem from "./DataQualityRuleItem.component";
import DataQualityRulesFiltering from "./DataQualityRulesFiltering.component";
import { useContext } from "react";
import { BussinesRulesContext } from "../../BussinesRulesContext/BussinesRulesContext.component";
import LoadingSpinner from "../../isLoadingSpinner/LoadingSpinner";

const DataQualityRules = () => {
  const bussinesCtx = useContext(BussinesRulesContext);
  const [isLoading, setLoading] = useState(false);
  const [firstTimeComponentLoaded, setfirstTimeComponentLoaded] =
    useState(true);

  useEffect(() => {
    if (firstTimeComponentLoaded) {
      setLoading(true);
      bussinesCtx.refreshList();
      setfirstTimeComponentLoaded(false);
    }
  }, []);

  useEffect(() => {
    if (!firstTimeComponentLoaded && !bussinesCtx.refreshOnProgress) {
      setLoading(false);
    }
  }, [bussinesCtx.refreshOnProgress]);

  const onDeleteDataQualityRuleButtonClick = (id) => {
    bussinesCtx.setConfirmationModal(true);
    bussinesCtx.setDataQualityRuleToDelete(id);
  };
  const [addNewModal, setAddNewModal] = useState(false);
  return (
    <>
      <Header title="Data Quality Rules" left="40%" />
      {bussinesCtx.businessRulesItems.length >= 1 && (
        <div className="container-xxl flex-grow-1 container-p-y">
          <DataQualityRulesFiltering
            setAddNewModal={setAddNewModal}
            dataQualityRules={bussinesCtx.dataQualityRules}
          />
          {bussinesCtx.dataQualityRules.map((dqRule) => {
            return (
              <DataQualityRuleItem
                dataQualityRuleItem={dqRule}
                onDeleteDataQualityRuleButtonClick={
                  onDeleteDataQualityRuleButtonClick
                }
                RuleName={
                  bussinesCtx.businessRulesItems.filter(
                    (r) => r.Id == dqRule.Business_Rule_id
                  )[0].RuleName
                }
                DataDomain={
                  bussinesCtx.businessRulesItems.filter(
                    (r) => r.Id == dqRule.Business_Rule_id
                  )[0].Domain
                }
                Dimension={
                  bussinesCtx.businessRulesItems.filter(
                    (r) => r.Id == dqRule.Business_Rule_id
                  )[0].Dimension
                }
              />
            );
          })}
        </div>
      )}
      {bussinesCtx.businessRulesItems.length < 1 && (
        <div class="container-xxl container-p-y centerWrapper">
          <div class="misc-wrapper ">
            <h2 class="mb-2 mx-2 centerText">No Business Rules Found!</h2>
            <p class="mb-4 mx-2 centerText">
              Please create a Business Rule first to be able to create a Data
              Quality Rule as well !
            </p>
            <div class="mt-4">
              <img
                src="../static/assets/img/illustrations/Missing_Rule.png"
                alt="girl-doing-yoga-light"
                width="500"
                class="img-fluid centerImage"
                data-app-dark-img="illustrations/girl-doing-yoga-dark.png"
                data-app-light-img="illustrations/girl-doing-yoga-light.png"
              />
            </div>
          </div>
        </div>
      )}

      {isLoading && <LoadingSpinner />}

      {addNewModal && (
        <AddNewDataQualityRuleModal setAddNewModal={setAddNewModal} setLoading={setLoading} />
      )}
      {bussinesCtx.confirmationModal && (
        <ConfirmationModal
          onConfirm={() => {
            bussinesCtx.onDeleteDataQuality();
            setLoading(true);
          }}
          onClose={() => {
            bussinesCtx.setConfirmationModal(false);
          }}
        />
      )}
    </>
  );
};

export default DataQualityRules;
