/**
 * @author Alexandros Andreou
 * @version 0.0.1
 * App.js creates the navigation logic of the pages
 */

import Navigation from "./components/Navigation/Navigation.component";
import Information from "./components/Pages/Information/Information.component";
import Departments from "./components/Pages/Departments/Departments.component";
import Employees from "./components/Pages/Employees/Employees.component";
import Error404 from "./components/Pages/Error/Error404.component";
import BusinessMetadata from "./components/Pages/BusinessMetadata/BusinessMetadata.component";
import DataDictionary from "./components/Pages/DataDictionary/DataDictionary.component";
import DataQualityRules from "./components/Pages/DataQualityRules/DataQualityRules.component";
import BusinessRules from "./components/Pages/Business Rules/BusinessRules.component";
import Dashboard from "./components/Pages/Dashboard/Dashboard.component";
import Footer from "./components/Footer/Footer.component";
import DatabaseConnection from "./components/Pages/DatabaseConnection/DatabaseConnection.component";
import HomeScreenLP from "./components/LandingPage/HomeScreenLP.component";
import { HashRouter, Route, Routes, Navigate } from "react-router-dom";
import DataProfiling from "./components/Pages/DataProfiling/DataProfiling.component";
import { useEffect,  useContext } from "react";
import LoggedInScreen from "./components/LandingPage/LoggedInScreen.component";
import Cookies from "universal-cookie";
import LandingPageNavigation from "./components/LandingPageNavigation/LandingPageNavigation.component";
import AboutUsScreen from "./components/LandingPage/AboutUsScreen.component";
import PricingScreen from "./components/LandingPage/PricingScreen.component ";
import { AuthContext } from "./components/UserContenx/UserContext.component";
import EditBussineRule from "./components/Pages/Business Rules/EditBussineRule.component";
import EditDataQualityRule from "./components/Pages/DataQualityRules/EditDataQualityRule.component";

function App() {
  const cookies = new Cookies();
  const authCtx = useContext(AuthContext);
  useEffect(() => {
    let userLogin = cookies.get("isLoggedIn") === "true" ? true : false;
    authCtx.setLoggedIn(userLogin);
    authCtx.setUser(cookies.get("userNameDataName"));
  }, []);

  let mainClass =
    authCtx.loggedIn === "true" || authCtx.loggedIn === true
      ? "layout-wrapper layout-content-navbar"
      : "";
  let mainClass2 =
    authCtx.loggedIn === "true" || authCtx.loggedIn === true
      ? "layout-container"
      : "";
  return (
    <div className={authCtx.loggedIn ? "light-style layout-menu-fixed" : ""}>
      <div className={mainClass}>
        <div className={mainClass2}>
          <HashRouter>
            {authCtx.loggedIn === true || authCtx.loggedIn === "true" ? (
              <Navigation />
            ) : (
              <LandingPageNavigation userLoggedIn={authCtx.userLoggedIn} />
            )}
            <div className="layout-page">
              <div className="content-wrapper">
                <Routes>
                  <Route
                    path="/"
                    element={
                      !authCtx.loggedIn ? (
                        <HomeScreenLP />
                      ) : (
                        <Navigate to="/info" />
                      )
                    }
                    exact
                  />
                  <Route
                    path="/logIn"
                    element={
                      !authCtx.loggedIn ? (
                        <LoggedInScreen
                          loggedIn={authCtx.loggedIn}
                          userLoggedIn={authCtx.userLoggedIn}
                        />
                      ) : (
                        <Navigate to="/info" />
                      )
                    }
                  />
                  <Route
                    path="/aboutUs"
                    element={
                      !authCtx.loggedIn ? (
                        <AboutUsScreen />
                      ) : (
                        <Navigate to="/info" />
                      )
                    }
                    exact
                  />
                  <Route
                    path="/pricing"
                    element={
                      !authCtx.loggedIn ? (
                        <PricingScreen />
                      ) : 
                      (
                        <Navigate to="/logIn" />
                      )
                    }
                    exact
                  />
                  <Route
                    path="/info"
                    element={
                      authCtx.loggedIn === "true" ||
                      authCtx.loggedIn === true ? (
                        <Information loggedIn={authCtx.loggedIn} />
                      ) : (
                        <Navigate to="/logIn" />
                      )
                    }
                    exact
                  />

                  <Route
                    path="/databaseConnection"
                    element={
                      authCtx.loggedIn === "true" ||
                      authCtx.loggedIn === true ? (
                        <DatabaseConnection />
                      ) : (
                        <Navigate to="/logIn" />
                      )
                    }
                  />
                  <Route
                    path="/department"
                    element={
                      authCtx.loggedIn === "true" ||
                      authCtx.loggedIn === true ? (
                        <Departments />
                      ) : (
                        <Navigate to="/logIn" />
                      )
                    }
                  />
                  <Route
                    path="/employee"
                    element={
                      authCtx.loggedIn === "true" ||
                      authCtx.loggedIn === true ? (
                        <Employees />
                      ) : (
                        <Navigate to="/logIn" />
                      )
                    }
                  />
                  <Route
                    path="/businessMetadata"
                    element={
                      authCtx.loggedIn === "true" ||
                      authCtx.loggedIn === true ? (
                        <BusinessMetadata />
                      ) : (
                        <Navigate to="/logIn" />
                      )
                    }
                  />
                  <Route
                    path="/dataDictionary"
                    element={
                      authCtx.loggedIn === "true" ||
                      authCtx.loggedIn === true ? (
                        <DataDictionary />
                      ) : (
                        <Navigate to="/logIn" />
                      )
                    }
                  />
                  <Route
                    path="/dataQualityRules"
                    element={
                      authCtx.loggedIn === "true" ||
                      authCtx.loggedIn === true ? (
                        <DataQualityRules />
                      ) : (
                        <Navigate to="/logIn" />
                      )
                    }
                  />
                  <Route
                    path="/dataProfiling"
                    element={
                      authCtx.loggedIn === "true" ||
                      authCtx.loggedIn === true ? (
                        <DataProfiling />
                      ) : (
                        <Navigate to="/logIn" />
                      )
                    }
                  />
                  <Route
                    path="/businessRules"
                    element={
                      authCtx.loggedIn === "true" ||
                      authCtx.loggedIn === true ? (
                        <BusinessRules />
                      ) : (
                        <Navigate to="/logIn" />
                      )
                    }
                  />
                  <Route
                    path="/dashboard"
                    element={
                      authCtx.loggedIn === "true" ||
                      authCtx.loggedIn === true ? (
                        <Dashboard />
                      ) : (
                        <Navigate to="/logIn" />
                      )
                    }
                  />
                   <Route
                    path="/editBussineRule"
                    element={
                      authCtx.loggedIn === "true" ||
                      authCtx.loggedIn === true ? (
                        <EditBussineRule />
                      ) : (
                        <Navigate to="/logIn" />
                      )
                    }
                  />
                  <Route
                    path="/editDataQualityRule"
                    element={
                      authCtx.loggedIn === "true" ||
                      authCtx.loggedIn === true ? (
                        <EditDataQualityRule />
                      ) : (
                        <Navigate to="/logIn" />
                      )
                    }
                  />
                  <Route path="/*" element={<Error404 />} />
                </Routes>
                <Footer />
                <div className="content-backdrop fade"></div>
              </div>
            </div>
          </HashRouter>
        </div>
        <div className="layout-overlay layout-menu-toggle"></div>
      </div>
    </div>
  );
}

export default App;
