import { Routes, Route, useNavigate } from "react-router-dom";
import axios from "axios";
import { useState } from "react";
import PasswordInput from "../SmallComponents/PasswordInput.component";

const LoggedInScreen = (props) => {
  const navigate = useNavigate();
  const [invalidPass, setInvalidPass] = useState(false);
  if (props.loggedIn === true) {
    navigate("/info");
  }

  const submit = async (e) => {
    e.preventDefault();

    const { data } = await axios
      .post(
        "/login",
        {
          email: email,
          password: password,
        },
        { withCredentials: true }
      )
      .catch(() => {
        setInvalidPass(true);
      });

    axios.defaults.headers.common["Authorization"] = `Bearer ${data.token}`;
    if (data.token) props.userLoggedIn(true);
    else {
      setInvalidPass(true);
    }
  };

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  return (
    <div class="container-xxl">
      <div class="authentication-wrapper authentication-basic container-p-y">
        <div class="authentication-inner">
          <div class="card">
            <div class="card-body">
              <h4 class="mb-2">Welcome to ArtiDG! 👋</h4>
              <p class="mb-4">
                Please sign-in to your account and start the adventure
              </p>
              {invalidPass && (
                <div class="alert alert-danger alert-dismissible" role="alert">
                  Invalid username and/or password
                  <button
                    type="button"
                    class="btn-close"
                    aria-label="Close"
                    onClick={() => {
                      setInvalidPass(false);
                    }}
                  ></button>
                </div>
              )}
              <form onSubmit={submit}>
                <div id="formAuthentication" class="mb-3" method="POST">
                  <div class="mb-3">
                    <label for="email" class="form-label">
                      Email
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="email"
                      name="email-username"
                      placeholder="Enter your email"
                      autofocus
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </div>
                  <div class="mb-3 form-password-toggle">
                    <div class="d-flex justify-content-between">
                      <label class="form-label" for="password">
                        Password
                      </label>
                      {/* <a href="auth-forgot-password-basic.html">
                        <small>Forgot Password?</small>
                      </a> */}
                    </div>
                    <div class="input-group input-group-merge">
                      <PasswordInput
                        value={password}
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div class="mb-3">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="remember-me"
                      />
                      <label class="form-check-label" for="remember-me">
                        {" "}
                        Remember Me{" "}
                      </label>
                    </div>
                  </div>
                  <div class="mb-3">
                    <button
                      class="btn btn-primary d-grid w-100"
                      // onClick={() => {
                      //   //props.setLoggedIn(true);
                      // }}
                    >
                      Sign in
                    </button>
                  </div>
                </div>
              </form>
              {/* <p class="text-center">
                <span>New on our platform?</span>
                <a href="auth-register-basic.html">
                  <span>Create an account</span>
                </a>
              </p> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoggedInScreen;
