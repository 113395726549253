import { useState, useEffect, useRef } from "react";
import PasswordInput from "../../SmallComponents/PasswordInput.component";

const MYSQLConnection = (props) => {
  const passwordRef = useRef(null);
  const [mode, setMode] = useState("readOnly");
  const [currentSettings, setCurrentSettings] = useState({
    Database_Type: "MYSQL",
    Username: "",
    Password: "",
    Host: "",
    Database_Name: "",
    Raise_on_warnings: false,
  });



  const { selectedConnectionstring } = props;
  useEffect(() => {
    if (props.selectedConnectionstring) setCurrentSettingsAsProps();

    setMode("readOnly");
  }, [selectedConnectionstring]);

  const cancelEdit = () => {
    setCurrentSettingsAsProps();
    setMode("readOnly");
    passwordRef.current.forceHidePassword();
  };

  const setCurrentSettingsAsProps = () => {
    setCurrentSettings({
      Id: selectedConnectionstring.Id,
      Database_Type: selectedConnectionstring.Database_Type,
      Username: selectedConnectionstring.Username,
      Password: selectedConnectionstring.Password,
      Host: selectedConnectionstring.Host,
      Raise_on_warnings: selectedConnectionstring.Raise_on_warnings,
    });
  };

  const saveEditedDatabase = () => {
    setMode("readOnly");
    props.submitEditedDatabaseConnection(currentSettings);
    passwordRef.current.forceHidePassword();
    
  };

  return (
    <div class="row inputLayout">
      <div class="col-12">
        <div class="card col-12">
          <div class="row card-body demo-vertical-spacing2 demo-only-element">
            <div className="col-6">
              <div class="form-password-toggle">
                <label class="form-label" for="basic-default-password12">
                  Database Type
                </label>

                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    id="basic-default-password12"
                    aria-describedby="basic-default-password2"
                    disabled
                    value={currentSettings.Database_Type}
                    onChange={(e) => {
                      setCurrentSettings((prevState) => {
                        return {
                          ...prevState,
                          Database_Type: e.target.value,
                        };
                      });
                    }}
                  />
                  <i class="bx bx-chevron-down dropdownDatabaseTypeIcon"></i>
                </div>
              </div>

              <div class="form-password-toggle">
                <label class="form-label" for="basic-default-password12">
                  Username
                </label>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    id="basic-default-password12"
                    aria-describedby="basic-default-password2"
                    value={currentSettings.Driver}
                    onChange={(e) => {
                      setCurrentSettings((prevState) => {
                        return {
                          ...prevState,
                          Username: e.target.value,
                        };
                      });
                    }}
                  />
                </div>
              </div>

              <div class="form-password-toggle">
                <label class="form-label" for="basic-default-password12">
                  Host
                </label>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    id="basic-default-password12"
                    aria-describedby="basic-default-password2"
                    value={currentSettings.Server}
                    onChange={(e) => {
                      setCurrentSettings((prevState) => {
                        return {
                          ...prevState,
                          Host: e.target.value,
                        };
                      });
                    }}
                  />
                </div>
              </div>

              <div class="form-password-toggle">
                <div class="input-group">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    checked={currentSettings.Raise_on_warnings}
                    onClick={(e) => {
                      setCurrentSettings((prevState) => {
                        return {
                          ...prevState,
                          Raise_on_warnings: e.target.checked,
                        };
                      });
                    }}
                  />
                  <label class="form-check-label">Raise On Warnings</label>
                </div>
              </div>
            </div>

            <div className="col-6">
              <div class="form-password-toggle">
                <label class="form-label" for="basic-default-password12">
                  Password
                </label>
                <div class="input-group">
                  <PasswordInput
                    ref={passwordRef}
                    value={currentSettings.Password}
                    disabled={mode === "readOnly"}
                    canChange={mode == "edit"}                   
                    onChange={(e) => {
                      setCurrentSettings((prevState) => {
                        return {
                          ...prevState,
                          Password: e.target.value,
                        };
                      });
                    }}
                  />
                </div>
              </div>
              <div class="form-password-toggle">
                <label class="form-label" for="basic-default-password12">
                  Database
                </label>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    id="basic-default-password12"
                    aria-describedby="basic-default-password2"
                    value={currentSettings.Server}
                    onChange={(e) => {
                      setCurrentSettings((prevState) => {
                        return {
                          ...prevState,
                          Database: e.target.value,
                        };
                      });
                    }}
                  />
                </div>
              </div>

              <div className="actionsButtons">
                {mode == "readOnly" && (
                  <button
                    type="button"
                    class="btn btn-outline-primary bottom_right marginLeft"
                    onClick={() => setMode("edit")}
                  >
                    Edit
                  </button>
                )}
                {mode == "edit" && (
                  <button
                    type="button"
                    class="btn btn-outline-primary bottom_right marginLeft"
                    onClick={cancelEdit}
                  >
                    Cancel
                  </button>
                )}
                {mode == "edit" && (
                  <button
                    type="button"
                    class="btn btn-outline-primary bottom_right marginLeft"
                    onClick={saveEditedDatabase}
                  >
                    Save
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MYSQLConnection;
