/**
 * @author Alexandros Andreou
 * @version 0.0.1
 * Data Dictionary Modal
 */

import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";

const DataDictionaryModal = (props) => {
  const [dataDictionary, setdataDictionary] = useState({
    Id: "",
    // Entity: "",
    // Source_System: "",
    Table_Name: "",
    Column_Name: "",
    // Data_Type: "",
    Required: "",
    Unique: "",
    Description: "",
    Value_Example: "",
    // Primary_Key: "",
    // Foreign_Key: "",
    // Foreign_Key_Table: "",
    // Foreign_Key_Column: "",
  });
  useEffect(() => {
    setdataDictionary(props.dataDictionary);
  }, [props.dataDictionary]);

  return (
    <>
      <div id="BackDropModalLayout" />
      <div
        className="modal fade show"
        id="backDropModal"
        data-bs-backdrop="static"
        tabindex="-1"
        style={{ display: "block" }}
        aria-modal="true"
        role="dialog"
      >
        <div class="modal-dialog">
          <form class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="backDropModalTitle">
                {props.title} Data Dictionary
              </h5>
              <button
                onClick={props.onClose}
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="row g-2">
                {/* <div class="col mb-0">
                  <label for="nameBackdrop" class="form-label">
                    Entity
                  </label>
                  <input
                    type="text"
                    id="nameBackdrop"
                    class="form-control"
                    placeholder="Enter Entity"
                    value={dataDictionary.Entity}
                    onChange={(e) => {
                      setdataDictionary((prevState) => {
                        return {
                          ...prevState,
                          Entity: e.target.value,
                        };
                      });
                    }}
                  />
                </div> */}
                {/* <div class="col mb-0">
                  <label for="nameBackdrop" class="form-label">
                    Source System
                  </label>
                  <input
                    type="text"
                    id="nameBackdrop"
                    class="form-control"
                    placeholder="Enter Source System"
                    value={dataDictionary.Source_System}
                    onChange={(e) => {
                      setdataDictionary((prevState) => {
                        return {
                          ...prevState,
                          Source_System: e.target.value,
                        };
                      });
                    }}
                  />
                </div> */}
              </div>

              <div class="row g-2">
                <div class="col mb-0">
                  <label for="nameBackdrop" class="form-label">
                    Table Name
                  </label>
                  <input
                    type="text"
                    id="nameBackdrop"
                    class="form-control"
                    placeholder="Enter Table Name"
                    value={dataDictionary.Table_Name}
                    onChange={(e) => {
                      setdataDictionary((prevState) => {
                        return {
                          ...prevState,
                          Table_Name: e.target.value,
                        };
                      });
                    }}
                  />
                </div>
                <div class="col mb-0">
                  <label for="nameBackdrop" class="form-label">
                    Column Name
                  </label>
                  <input
                    type="text"
                    id="nameBackdrop"
                    class="form-control"
                    placeholder="Enter Column Name"
                    value={dataDictionary.Column_Name}
                    onChange={(e) => {
                      setdataDictionary((prevState) => {
                        return {
                          ...prevState,
                          Column_Name: e.target.value,
                        };
                      });
                    }}
                  />
                </div>
              </div>

              {/* <div class="row">
                <div class="col mb-3">
                  <label for="nameBackdrop" class="form-label">
                    Data Type
                  </label>
                  <input
                    onChange={(e) => {
                      setdataDictionary((prevState) => {
                        return {
                          ...prevState,
                          Data_Type: e.target.value,
                        };
                      });
                    }}
                    value={dataDictionary.Data_Type}
                    type="text"
                    id="nameBackdrop"
                    class="form-control"
                    placeholder="Enter Data Type"
                  />
                </div>
              </div> */}

              <div class="row g-2">
                <div class="col mb-0">
                  <label for="nameBackdrop" class="form-label">
                    Required
                  </label>

                  <input
                    type="text"
                    id="nameBackdrop"
                    class="form-control"
                    placeholder="Enter Required"
                    value={dataDictionary.Required}
                    onChange={(e) => {
                      setdataDictionary((prevState) => {
                        return {
                          ...prevState,
                          Required: e.target.value,
                        };
                      });
                    }}
                  />
                </div>
                <div class="col mb-0">
                  <label for="nameBackdrop" class="form-label">
                    Unique
                  </label>
                  <input
                    type="text"
                    id="nameBackdrop"
                    class="form-control"
                    placeholder="Enter Unique"
                    value={dataDictionary.Unique}
                    onChange={(e) => {
                      setdataDictionary((prevState) => {
                        return {
                          ...prevState,
                          Unique: e.target.value,
                        };
                      });
                    }}
                  />
                </div>
              </div>

              <div class="row">
                <div class="col mb-3">
                  <label for="nameBackdrop" class="form-label">
                    Description
                  </label>
                  <input
                    onChange={(e) => {
                      setdataDictionary((prevState) => {
                        return {
                          ...prevState,
                          Description: e.target.value,
                        };
                      });
                    }}
                    value={dataDictionary.Description}
                    type="text"
                    id="nameBackdrop"
                    class="form-control"
                    placeholder="Enter Description"
                  />
                </div>
              </div>

              <div class="row">
                <div class="col mb-3">
                  <label for="nameBackdrop" class="form-label">
                    Value Example
                  </label>
                  <input
                    onChange={(e) => {
                      setdataDictionary((prevState) => {
                        return {
                          ...prevState,
                          Value_Example: e.target.value,
                        };
                      });
                    }}
                    value={dataDictionary.Value_Example}
                    type="text"
                    id="nameBackdrop"
                    class="form-control"
                    placeholder="Enter Value Example"
                  />
                </div>
              </div>

              <div class="row g-2">
                {/* <div class="col mb-0">
                  <label for="nameBackdrop" class="form-label">
                    Primary Key
                  </label>
                  <input
                    type="text"
                    id="nameBackdrop"
                    class="form-control"
                    placeholder="Enter Primary Key"
                    value={dataDictionary.Primary_Key}
                    onChange={(e) => {
                      setdataDictionary((prevState) => {
                        return {
                          ...prevState,
                          Primary_Key: e.target.value,
                        };
                      });
                    }}
                  />
                </div>
                <div class="col mb-0">
                  <label for="nameBackdrop" class="form-label">
                    Foreign Key
                  </label>
                  <input
                    type="text"
                    id="nameBackdrop"
                    class="form-control"
                    placeholder="Enter Foreign Key"
                    value={dataDictionary.Foreign_Key}
                    onChange={(e) => {
                      setdataDictionary((prevState) => {
                        return {
                          ...prevState,
                          Foreign_Key: e.target.value,
                        };
                      });
                    }}
                  />
                </div>
              </div>

              <div class="row g-2">
                <div class="col mb-0">
                  <label for="nameBackdrop" class="form-label">
                    Foreign_Key_Table
                  </label>
                  <input
                    type="text"
                    id="nameBackdrop"
                    class="form-control"
                    placeholder="Enter Foreign_Key_Table"
                    value={dataDictionary.Foreign_Key_Table}
                    onChange={(e) => {
                      setdataDictionary((prevState) => {
                        return {
                          ...prevState,
                          Foreign_Key_Table: e.target.value,
                        };
                      });
                    }}
                  />
                </div>
                <div class="col mb-0">
                  <label for="nameBackdrop" class="form-label">
                    Foreign_Key_Column
                  </label>
                  <input
                    type="text"
                    id="nameBackdrop"
                    class="form-control"
                    placeholder="Enter Foreign_Key_Column"
                    value={dataDictionary.Foreign_Key_Column}
                    onChange={(e) => {
                      setdataDictionary((prevState) => {
                        return {
                          ...prevState,
                          Foreign_Key_Column: e.target.value,
                        };
                      });
                    }}
                  />
                </div> */}
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-outline-secondary"
                data-bs-dismiss="modal"
                onClick={props.onClose}
              >
                Close
              </button>
              <button
                type="button"
                class="btn btn-primary"
                onClick={() => {
                  props.onSave(dataDictionary);
                }}
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default DataDictionaryModal;
