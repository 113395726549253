const AboutUsScreen = () => {
  return (
    <section
      class="elementor-section elementor-top-section elementor-element elementor-element-893ddae elementor-section-boxed elementor-section-height-default elementor-section-height-default"
      data-id="893ddae"
      data-element_type="section"
    >
      <div class="elementor-container elementor-column-gap-default">
        <div
          class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-1ae9b17"
          data-id="1ae9b17"
          data-element_type="column"
        >
          <div class="elementor-widget-wrap elementor-element-populated">
            <div
              class="elementor-element elementor-element-839c601 elementor-widget elementor-widget-html"
              data-id="839c601"
              data-element_type="widget"
              data-widget_type="html.default"
            >
              <div class="elementor-widget-container">
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    height: 0,
                    paddingTop: "56.2500%",
                    paddingBottom: "48px",
                    boxShadow: "0 2px 8px 0 rgba(63,69,81,0.16)",
                    marginTop: "1.6em",
                    marginBottom: "0.9em",
                    overflow: "hidden",
                    borderRadius: "8px",
                    willChange: "transform",
                  }}
                >
                  {" "}
                  <iframe
                    loading="lazy"
                    style={{
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      top: 0,
                      left: 0,
                      border: "none",
                      padding: 0,
                      margin: 0,
                    }}
                    src="https://www.canva.com/design/DAE9hfiS5kE/view?embed"
                    allowfullscreen="allowfullscreen"
                    allow="fullscreen"
                  >
                    {" "}
                  </iframe>
                </div>{" "}
    
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUsScreen;
