import { useState } from "react";
import ConfirmationModal from "../../Modal/Confirmation.modal";
import DataDomainModal from "./DataDomains.modal";
import MainGrid from "../../Grid/MainGrid.component";

const DataDomainsTab = (props) => {
  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <div className="row">
        <div className="col-lg-12 mb-12 order-0">
          <div class="card">
            {/* <h5 class="card-header">Deparments</h5> */}
            <div class="table-responsive text-nowrap">
              <MainGrid
                dataScr={props.dataScr}
                onEdit={props.onEdit}
                onDelete={props.onDelete}
                columnsList={props.columnsList}
              />
            </div>
          </div>
        </div>
      </div>
      {props.showModal && (
        <DataDomainModal
          title={props.modalMode}
          name={props.selectedDataDomain.DataDomainName}
          id={props.selectedDataDomain.DataDomainsId}
          onClose={() => {
            props.setShowModal(false);
          }}
          onChangeName={props.onChangeNameOnModal}
          onSave={props.modalSaveButtonHandler}
          data-testid="datadomain-modal"
        />
      )}
      {props.confirmationModal && (
        <ConfirmationModal
          onConfirm={props.deleteDataDomain}
          onClose={() => {
            props.setConfirmationModal(false);
          }}
        />
      )}

      <button
        type="button"
        class="btn btn-outline-primary addDeparmentButton"
        onClick={props.addDataDomain}
        data-testid="datadomain-modal-appeared"
      >
        Add a Data Domain
      </button>
    </div>
  );
};

export default DataDomainsTab;
