/**
 * @author Alexandros Andreou
 * @version 0.0.1
 * Information Page
 */

import { useState } from "react";
import DataStewardship from "./DataStewardship.component";
import DataOwnership from "./DataOwnership.component";
import DataPolicies from "./DataPolicies.component";
import MetadataManagement from "./MetadataManagement.component";
import HomeGraph from "./HomeGraph.component";
import Header from "../../Header/Header.component";
const Information = (props) => {
  const [selectedType, setSelectedType] = useState(0);
  const [showMainComponent, setShowMainComponent] = useState(true);

  const handleLeafClick = (type) => {
    {
      setShowMainComponent(false);
    }
    {
      setSelectedType(type);
    }
  };

  const handleBack = () => {
    {
      setShowMainComponent(true);
    }
    {
      setSelectedType(0);
    }
  };

  return (
    <>
     <Header  title ="Information"/>
    <div>
      {showMainComponent && (
        <div>
          <HomeGraph handleLeafClick = {handleLeafClick}/>
        </div>
      )}

      {selectedType === "Data Stewardship" && (
        <DataStewardship backButton={handleBack} />
      )}
      {selectedType === "Data Ownership" && (
        <DataOwnership backButton={handleBack} />
      )}
      {selectedType === "Data Policies" && (
        <DataPolicies backButton={handleBack} />
      )}
      {selectedType === "MetaData Management" && (
        <MetadataManagement backButton={handleBack} />
      )}
    </div>
    </>
  );
};

export default Information;
