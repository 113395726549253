/**
 * @author Alexandros Andreou
 * @version 0.0.1
 * Deparments Page
 */

import { useState, useEffect } from "react";
import Header from "../../Header/Header.component";
import DepartmentsTab from "./DepartmentsTab.component";
import DataDomainsTab from "./DataDomainsTab.component";
import LoadingSpinner from "../../isLoadingSpinner/LoadingSpinner";

const Departments = (props) => {
  const [departments, setDepartments] = useState([]);
  const [dataDomains, setdataDomains] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setLoading] = useState(false)
  const [modalMode, setModalMode] = useState("");
  const [confirmationModal, setConfirmationModal] = useState(false);

  const [selectedDepartment, setSelectedDepartment] = useState({
    DepartmentId: "",
    DataDomain_id: "",
    DepartmentName: "",
  });

  const [selectedDataDomain, setSelectedDataDomain] = useState({
    DataDomainsId: "",
    DataDomainName: "",
  });

  const [activeTab, setActiveTab] = useState("Departments");

  useEffect(() => {
    setLoading(true)
    refreshList();
  }, []);

  const refreshList = () => {
    setLoading(true)
    const fetchReq1 = fetch(`/department`).then((res) =>
      res.json()
    );

    const fetchReq2 = fetch(`/dataDomains`).then((res) =>
      res.json()
    );
    const allData = Promise.all([fetchReq1, fetchReq2]);
    allData.then((res) => {
      var [departments, dataDomains] = res;

      for (let department of departments) {
        for (let dataDomain of dataDomains) {
          if (department.DataDomain_id == dataDomain.DataDomainsId)
            department.DataDomainName = dataDomain.DataDomainName;
        }
      }

      setDepartments(departments);
      setdataDomains(dataDomains); 
      setLoading(false);
    });
  };

  const onChangeNameOnModalDataDomain = (e) => {
    setSelectedDataDomain((prevState) => {
      return {
        DataDomainsId: prevState.DataDomainsId,
        DataDomainName: e.target.value,
      };
    });
  };

  const onChangeNameOnModalDepartment = (e) => {
    setSelectedDepartment((prevState) => {
      return {
        DepartmentId: prevState.DepartmentId,
        DepartmentName: e.target.value,
        DataDomain_id: prevState.DataDomain_id,
      };
    });
  };

  const onChangeDataDomainOnModalDepartment = (e) => {
    setSelectedDepartment((prevState) => {
      return {
        DepartmentId: prevState.DepartmentId,
        DepartmentName: prevState.DepartmentName,
        DataDomain_id: +e.target.value,
      };
    });
  };

  const addDepartment = () => {
    setShowModal(true);
    setModalMode("Add");
    setSelectedDepartment({
      DataDomainsId: 0,
      DepartmentName: "",
      DataDomain_id: "",
    });
  };

  const addDataDomain = () => {
    setShowModal(true);
    setModalMode("Add");
    setSelectedDataDomain({
      DepartmentId: 0,
      DataDomainName: "",
    });
  };

  const editDepartment = (id) => {
    setShowModal(true);
    const department = departments.filter((d) => d.DepartmentId === id);
    setModalMode("Edit");
    setSelectedDepartment({
      DepartmentId: department[0].DepartmentId,
      DataDomain_id: department[0].DataDomain_id,
      DepartmentName: department[0].DepartmentName,
    });
  };

  const editDataDomain = (id) => {
    setShowModal(true);
    const datadomain = dataDomains.filter((d) => d.DataDomainsId === id);
    setModalMode("Edit");
    setSelectedDataDomain({
      DataDomainsId: datadomain[0].DataDomainsId,
      DataDomainName: datadomain[0].DataDomainName,
    });
  };

  const deleteDepartmentMessage = (DepartmentId) => {
    setConfirmationModal(true);
    setSelectedDepartment(() => {
      return {
        DepartmentId: DepartmentId,
        DepartmentName: "",
      };
    });
  };

  const deleteDataDomainMessage = (DataDomainsId) => {
    setConfirmationModal(true);
    setSelectedDataDomain(() => {
      return {
        DataDomainsId: DataDomainsId,
        DataDomainName: "",
      };
    });
  };

  const deleteDepartment = () => {
    setConfirmationModal(false);
    fetch(
      "/department/" + selectedDepartment.DepartmentId,
      {
        method: "DELETE",
        header: {
          Accept: "application/json",
          "Content-Type": "applciation/json",
        },
      }
    ).then(() => {
      refreshList();
    });
  };

  const deleteDataDomain = () => {
    setConfirmationModal(false);
    fetch(
      "/dataDomains/" + selectedDataDomain.DataDomainsId,
      {
        method: "DELETE",
        header: {
          Accept: "application/json",
          "Content-Type": "applciation/json",
        },
      }
    ).then(() => {
      refreshList();
    });
  };

  const submitEditedDepartment = () => {
    fetch("/department", {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        DepartmentId: selectedDepartment.DepartmentId,
        DataDomain_id: selectedDepartment.DataDomain_id,
        DepartmentName: selectedDepartment.DepartmentName,
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          refreshList();
        },
        (error) => {
          alert("Failed");
        }
      );
  };

  const submitEditedDataDomain = () => {
    fetch("/dataDomains", {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        DataDomainsId: selectedDataDomain.DataDomainsId,
        DataDomainName: selectedDataDomain.DataDomainName,
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          refreshList();
        },
        (error) => {
          alert("Failed");
        }
      );
  };

  const submitNewDepartment = () => {
    fetch("/department", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        DepartmentId: null,
        DepartmentName: selectedDepartment.DepartmentName,
        DataDomain_id: selectedDepartment.DataDomain_id,
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result === "Added Successfully!") {
            refreshList();
          } else {
            // Request Error
          }
        },
        (error) => {
          // Server Error
          alert("Problem with the Server");
        }
      );
  };

  const submitNewDataDomain = () => {
    fetch("/dataDomains", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        DataDomainsId: null,
        DataDomainName: selectedDataDomain.DataDomainName,
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result === "Added Successfully!") {
            refreshList();
          } else {
            // Request Error
          }
        },
        (error) => {
          // Server Error
          alert("Problem with the Server");
        }
      );
  };

  const modalSaveButtonHandlerDepartments = () => {
    setShowModal(false);
    if (modalMode === "Edit") submitEditedDepartment();
    else submitNewDepartment();
  };

  const modalSaveButtonHandlerDataDomain = () => {
    setShowModal(false);
    if (modalMode === "Edit") submitEditedDataDomain();
    else submitNewDataDomain();
  };

  const columnsListDepartment = [
    {
      field: "DepartmentId",
      headerText: "Department Id",
      width: "60",
      visible: false,
      enableFilterSearch: true,
    },
    {
     // field: "DataDomain_id",
     field: "DataDomainName",
      headerText: "Data Domain",
      width: "150",
      visible: true,
      enableFilterSearch: true,
    },
    {
      field: "DepartmentName",
      headerText: "Deparment Name",
      width: "150",
      visible: true,
      enableFilterSearch: true,
    },
  ];

  const columnsListDataDomain = [
    {
      field: "DataDomainsId",
      headerText: "Department Id",
      width: "60",
      visible: false,
      enableFilterSearch: true,
    },
    {
      field: "DataDomainName",
      headerText: "Data Domain Name",
      width: "150",
      visible: true,
      enableFilterSearch: true,
    },
  ];

  return (
    <>
      <Header title="Departments" />
      <div className="container-xxl flex-grow-1 container-p-y">
        <div className="row">
          <div className="col-lg-12 mb-12 order-0">
            <div class="card">
              <div class="table-responsive text-nowrap layoutPadding">
                <div
                  className="row"
                  style={{ padding: "20px", border: "1px solid #D9DEE3" }}
                >
                  <div
                    className="col-12"
                    style={{ padding: "0px", marginBottom: "13px" }}
                  >
                    <span
                      className={
                        activeTab === "Departments"
                          ? "tabButtonActive"
                          : "tabButton"
                      }
                      onClick={() => {
                        setActiveTab("Departments");
                      }}
                    >
                      Departments
                    </span>

                    <span
                      className={
                        activeTab === "Data Domains"
                          ? "tabButtonActive"
                          : "tabButton"
                      }
                      onClick={() => {
                        setActiveTab("Data Domains");
                      }}
                    >
                      Data Domains
                    </span>
                  </div>
                  {activeTab === "Departments" && (
                    <DepartmentsTab
                      dataScr={departments}
                      onEdit={editDepartment}
                      onDelete={deleteDepartmentMessage}
                      columnsList={columnsListDepartment}
                      modalMode={modalMode}
                      selectedDepartment={selectedDepartment}
                      setShowModal={setShowModal}
                      setConfirmationModal={setConfirmationModal}
                      showModal={showModal}
                      modalSaveButtonHandler={modalSaveButtonHandlerDepartments}
                      confirmationModal={confirmationModal}
                      deleteDepartment={deleteDepartment}
                      addDepartment={addDepartment}
                      dataDomains={dataDomains}
                      selectedD={selectedDepartment}
                      onChangeNameOnModal={onChangeNameOnModalDepartment}
                      onChangeDataDomainOnModal={
                        onChangeDataDomainOnModalDepartment
                      }
                    />
                  )}
                  {activeTab === "Data Domains" && (
                    <DataDomainsTab
                      dataScr={dataDomains}
                      onEdit={editDataDomain}
                      onDelete={deleteDataDomainMessage}
                      columnsList={columnsListDataDomain}
                      modalMode={modalMode}
                      setShowModal={setShowModal}
                      setConfirmationModal={setConfirmationModal}
                      showModal={showModal}
                      onChangeNameOnModal={onChangeNameOnModalDataDomain}
                      modalSaveButtonHandler={modalSaveButtonHandlerDataDomain}
                      confirmationModal={confirmationModal}
                      selectedDataDomain={selectedDataDomain}
                      deleteDataDomain={deleteDataDomain}
                      addDataDomain={addDataDomain}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading && <LoadingSpinner />}
    </>
  );
};

export default Departments;
