/**
 * @author Alexandros Andreou
 * @version 0.0.1
 * Navigation Component
 */

import Logo from "./Logo.component";
import MenuItem from "./MenuItem.component";
import MenuLabel from "./MenuLabel.component";
const Navigation = (props) => {
  const MenuObj = [
    {
      order: 0,
      isMenuLabel: false,
      title: "Information",
      icon: "bx bx-info-circle",
      to: "/info",
    },
    {
      order: 0,
      isMenuLabel: false,
      title: "Database Connection",
      icon: "bx bx-home-circle",
      to: "/databaseConnection",
    },
    {
      order: 1,
      isMenuLabel: false,
      title: "Departments",
      icon: "bx bxs-business",
      to: "/department",
    },
    {
      order: 2,
      isMenuLabel: false,
      title: "Employees",
      icon: "bx bx-user",
      to: "/employee",
    },
    {
      order: 3,
      isMenuLabel: true,
      title: "Metadata Management",
    },
    {
      order: 4,
      isMenuLabel: false,
      title: "Business Metadata",
      icon: "bx bx-info-square",
      to: "/businessMetadata",
    },
    {
      order: 5,
      isMenuLabel: false,
      title: "Data Dictonary",
      icon: "bx bxs-book",
      to: "/dataDictionary",
    },
    {
      order: 6,
      isMenuLabel: true,
      title: "Data Quality Management",
    },
    {
      order: 7,
      isMenuLabel: false,
      title: "Data Profiling",
      icon: "bx bx-bar-chart-alt-2",
      to: "/dataProfiling",
    },
    {
      order: 7,
      isMenuLabel: false,
      title: "Business Rules",
      icon: "bx bx-spreadsheet",
      to: "/businessRules",
    },
    {
      order: 8,
      isMenuLabel: false,
      title: "Data Quality Rules",
      icon: "bx bx-tachometer",
      to: "/dataQualityRules",
    },
    {
      order: 9,
      isMenuLabel: false,
      title: "Dashboard",
      icon: "bx bxs-dashboard",
      to: "/dashboard",
    },
    {
      order: 10,
      isMenuLabel: true,
      title: "Master Data Management",
    },
    {
      order: 11,
      isMenuLabel: false,
      title: "MDM Hub",
      icon: "bx bx-cube",
      to: "/mdmHub",
    },
  ];
  return (
    <aside
      id="layout-menu"
      className="layout-menu menu-vertical menu bg-menu-theme ScrollViewMenu"
    >
      <Logo />
      <div className="menu-inner-shadow"></div>
      <ul className="menu-inner py-1">
        {MenuObj.map((menuItem) => {
          if (menuItem.isMenuLabel)
            return <MenuLabel key={menuItem.order} label={menuItem.title} />;
          else
            return (
              <MenuItem
                key={menuItem.order}
                label={menuItem.title}
                icon={menuItem.icon}
                to={menuItem.to}
              />
            );
        })}
        {/* <span onClick={() => {props.userLoggedIn(false)}} class="menu-item" href="/mdmHub" aria-current="page"><span class="menu-link"><i class="menu-icon tf-icons bx bx-log-out"></i><div>Logout</div></span></span> */}
      </ul>
    </aside>
  );
};

export default Navigation;
