import { useState, useEffect } from "react";
import { useContext } from "react";
import { BussinesRulesContext } from "../../BussinesRulesContext/BussinesRulesContext.component";
const AddNewRulesVariableComponent = (props) => {
  const bussinesCtx = useContext(BussinesRulesContext);
  const [newRule, setNewRule] = useState({
    Variable_id: 0,
    Operator: 0,
    Variable_Value: "",
  });
  useEffect(() => {
    fetch(`/dataDictionary`)
      .then((res) => res.json())
      .then((result) => {
        bussinesCtx.setVariableOptions(result);
      });
  }, []);
  return (
    <>
      <div class="row" style={{ marginTop: "10px" }}>
        <div class="col-sm-4 fontSize14" style={{ paddingLeft: "35px" }}>
          <select
            dis
            class="form-select form-select-display"
            id="exampleFormControlSelect1"
            aria-label="Default select example"
            onChange={(e) => {
              setNewRule({ ...newRule, Variable_id: e.target.value });
            }}
            style={{ width: "100%" }}
          >
            <option selected={newRule.Variable_id === 0} value="0">
              Select an option
            </option>
            {bussinesCtx.variablesOptions &&
              bussinesCtx.variablesOptions.map((op) => {
                return (
                  <option
                    selected={newRule.Variable_id === op.Id}
                    value={op.Id}
                    tableId={op.tableId}
                  >
                    {op.Table_Name}/{op.Column_Name}
                  </option>
                );
              })}
          </select>
        </div>
        <div class="col-sm-2 fontSize14">
          <select
            class="form-select form-select-display"
            id="exampleFormControlSelect1"
            aria-label="Default select example"
            onChange={(e) => {
              setNewRule({ ...newRule, Operator: e.target.value });
            }}
            style={{ width: "100%" }}
          >
            <option selected={newRule.Operator === 0} value="0">
              Select an option
            </option>
            <option
              selected={newRule.Operator === ">"}
              value=">"
            >{`>`}</option>
            <option
              selected={newRule.Operator === ">="}
              value=">="
            >{`>=`}</option>
            <option
              selected={newRule.Operator === "<"}
              value="<"
            >{`<`}</option>
            <option
              selected={newRule.Operator === "<="}
              value="<="
            >{`<=`}</option>
            <option
              selected={newRule.Operator === "=="}
              value="=="
            >{`==`}</option>
            <option
              selected={newRule.Operator === "!="}
              value="!="
            >{`!=`}</option>
            <option
              selected={newRule.Operator === "in"}
              value="in"
            >{`in`}</option>
            <option
              selected={newRule.Operator === "not in"}
              value="not in"
            >{`not in`}</option>
            <option
              selected={newRule.Operator === "like"}
              value="like"
            >{`like`}</option>
            <option
              selected={newRule.Operator === "not like"}
              value="not like"
            >{`not like`}</option>
          </select>
        </div>
        <div class="col-sm-4 fontSize14">
          <input
            type="text"
            class="form-control"
            id="basic-default-password12"
            aria-describedby="basic-default-password2"
            value={newRule.Variable_Value}
            onChange={(e) => {
              setNewRule({ ...newRule, Variable_Value: e.target.value });
            }}
          />
        </div>
        <div class="col-sm-2 fontSize14">
          <button
            type="button"
            class="btn btn-outline-primary bottom_right marginLeft"
            onClick={() => {
              if (
                newRule.Variable_id !== "0" &&
                newRule.Operator !== "0" &&
                newRule.Variable_Value !== ""
              ) {
                props.addNewVariable(newRule);
                setNewRule({
                  Variable_id: 0,
                  Operator: 0,
                  Variable_Value: "",
                });
              }
            }}
          >
            <i class="bx bxs-save"></i>
          </button>
        </div>
      </div>
    </>
  );
};

export default AddNewRulesVariableComponent;
