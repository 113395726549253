import axios from "axios";
import { useEffect } from "react";
import { useState, createContext } from "react";
import Cookies from "universal-cookie";
export const AuthContext = createContext(null);
export const ContextProvider = (props) => {
  const [user, setUser] = useState("");
  const [loggedIn, setLoggedIn] = useState(true);
  const cookies = new Cookies();

  const userLoggedIn = (isLoggedIn) => {
    setLoggedIn(isLoggedIn);
    cookies.set("isLoggedIn", isLoggedIn, { path: "/" });

    if (isLoggedIn) {
      (async () => {
        const { data } = await axios.get("/user");
        cookies.set("userNameDataName", data.first_name + " " + data.last_name, { path: "/" });
        setUser(data.first_name + " " + data.last_name);
      })();
    }
  };

  return (
    <AuthContext.Provider
      value={{ user, setUser, loggedIn, setLoggedIn, userLoggedIn }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
