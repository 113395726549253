/**
 * @author Alexandros Andreou
 * @version 0.0.1
 * Deparments Modal
 */

const DeparmentModal = (props) => {
  return (
    <>
      <div id="BackDropModalLayout" />
      <div
        className="modal fade show"
        id="backDropModal"
        data-bs-backdrop="static"
        tabindex="-1"
        style={{ display: "block" }}
        aria-modal="true"
        role="dialog"
      >
        <div class="modal-dialog">
          <form class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="backDropModalTitle">
                {props.title} Department
              </h5>
              <button
                onClick={props.onClose}
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col mb-3">
                  <label for="nameBackdrop" class="form-label">
                    Name
                  </label>
                  <input
                    onChange={props.onChangeName}
                    value={props.name}
                    type="text"
                    id="nameBackdrop"
                    class="form-control"
                    placeholder="Enter Name"
                  />
                </div>
              </div>
              <div class="row">
              <label for="nameBackdrop" class="form-label">
                    Data Domains
                  </label>
                <select
                  class="form-select form-select-display data_domains_select"
                  id="exampleFormControlSelect1"
                  aria-label="Default select example"
                  style={{ width: "50%" }}
                  onChange={props.onChangeDataDomain}
                >
                  <option selected>Select Data Domain</option>
                  {props.dataDomains &&
                    props.dataDomains.map((dd) => {
                      return (
                        <option
                          selected={props.selectedDepartment.DataDomain_id === dd.DataDomainsId}
                          value={dd.DataDomainsId}
                        >
                          {dd.DataDomainName}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-outline-secondary"
                data-bs-dismiss="modal"
                onClick={props.onClose}
              >
                Close
              </button>
              <button
                type="button"
                class="btn btn-primary"
                onClick={props.onSave}
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default DeparmentModal;
