/**
 * @author Alexandros Andreou
 * @version 0.0.1
 * Confirmation Modal that appears after each delete request by the user.
 */

const ConfirmationModal = (props) => {
  return (
    <>
      <div id="BackDropModalLayout" />
      <div
        className="modal fade show"
        id="backDropModal"
        data-bs-backdrop="static"
        tabindex="-1"
        style={{ display: "block" }}
        aria-modal="true"
        role="dialog"
      >
        <div class="modal-dialog">
          <form class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="backDropModalTitle">
                {props.confirmationModalMessage || "Are you sure?"}
              </h5>
              <button
                onClick={props.onClose}
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-primary"
                onClick={props.onConfirm}
              >
                YES
              </button>
              <button
                type="button"
                class="btn btn-outline-secondary"
                data-bs-dismiss="modal"
                onClick={props.onClose}
              >
                NO
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ConfirmationModal;
