import AddNewRulesVariableComponent from "./AddNewRulesVariableComponent.component";
import RulesVariableComponent from "./RulesVariableComponent.component";
import { useState } from "react";
import { useEffect } from "react";
const EditBussinesRuleVariablesArea = (props) => {
  const [variables, setVariables] = useState([]);
  useEffect(() => {
    if (props.selectedVersion.Variables)
      setVariables(props.selectedVersion.Variables);
  }, []);

  const addNewVariable = (variable) => {
    let selectedVersionId = props.selectedVersion.Id;
    fetch("/businessRuleVersionVariables", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        BusinessVariable_Rule_Version_id: selectedVersionId,
        Variable_id: variable.Variable_id,
        Operator: variable.Operator,
        Variable_Value: variable.Variable_Value,
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setVariables([...variables, { Id: result, ...variable }]);
        },
        (error) => {
          alert("Problem with the Server");
        }
      );
  };

  const deleteRule = (ruleId) => {
    fetch("/businessRuleVersionVariables/" + ruleId, {
      method: "DELETE",
      header: {
        Accept: "application/json",
        "Content-Type": "applciation/json",
      },
    }).then(() => {
      let _variables = variables.filter((variable) => ruleId != variable.Id);
      setVariables(_variables);
    });
  };

  const saveRule = (rule) => {
    fetch("/businessRuleVersionVariables", {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Id: rule.Id,
        BusinessVariable_Rule_Version_id: rule.BusinessVariable_Rule_Version_id,
        Variable_id: rule.Variable_id,
        Operator: rule.Operator,
        Variable_Value: rule.Variable_Value,
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {

        },
        (error) => {
          alert("Failed");
        }
      );
  };

  return (
    <div class="row">
      <div class="col-lg-12 mb-4 order-0">
        <div class="card">
          <div class="d-flex align-items-end row marginBottom">
            <div class="col-sm-12">
              <div class="row">
                <div class="col-sm-12">
                  <div
                    class="card-body bussinesType"
                    style={{ textAlign: "left" }}
                  >
                    <div>
                      Rules variables
                      <span
                        class="badge badge-center rounded-pill bg-label-primary"
                        style={{ marginLeft: "10px" }}
                      >
                       {variables.length}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <hr />

              <div class="row">
                <div
                  class="col-sm-4 fontSize14"
                  style={{ paddingLeft: "35px" }}
                >
                  <div>Variables</div>
                </div>
                <div class="col-sm-2 fontSize14">
                  <div>Operator</div>
                </div>
                <div class="col-sm-4 fontSize14">
                  <div>Variable Value</div>
                </div>
              </div>
              {variables.map((variable) => {
                return (
                  <RulesVariableComponent
                    variable={variable}
                    deleteRule={deleteRule}
                    saveRule={saveRule}
                  />
                );
              })}

              <AddNewRulesVariableComponent addNewVariable={addNewVariable} />
            </div>
          </div>
        </div>
      </div>

      <div class="floating-container">
        <div class="floating-button">
          <i class="bx bxs-save"></i>
        </div>
        <div class="element-container"></div>
      </div>
    </div>
  );
};

export default EditBussinesRuleVariablesArea;
