import { useState } from "react";
import { useEffect } from "react";
import { Link, Routes, Route, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { BussinesRulesContext } from "../../BussinesRulesContext/BussinesRulesContext.component";
const EditBussinesRuleInfoArea = (props) => {
  const bussinesCtx = useContext(BussinesRulesContext);
  console.log(props);
  const [status, setStatus] = useState("");
  const [description, setDescription] = useState("");
  const [mode, setMode] = useState("readOnly");
  const navigate = useNavigate();

  useEffect(() => {
    if (props.selectedVersion) {
      setStatus(props.selectedVersion.Status);
      setDescription(props.selectedVersion.Description);
    }
  }, []);

  const saveBussinesRule = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    today = yyyy + "-" + mm + "-" + dd;

    console.log(today);
    console.log(props.selectedVersion.Id);
    console.log(status);
    console.log(description);

    fetch("/businessRuleVersions", {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Id: props.selectedVersion.Id,
        Business_Rule_id: props.selectedBussinesRule.Id,
        Description: description || null,
        Status: status,
        IsActiveVersion: status == "Active" ? true : false,
        Created: props.selectedVersion.Created,
        Last_Updated: today,
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
   
        },
        (error) => {
          alert("Failed");
        }
      );
      setMode("readOnly")
  };

  return (
    <div class="row">
      <div class="col-lg-12 mb-4 order-0">
        <div class="card">
          <div class="d-flex align-items-end row marginBottom">
            <div class="col-sm-12">
              <div class="row">
                <div class="col-sm-4">
                  <div
                    class="card-body bussinesType"
                    style={{ textAlign: "left" }}
                  >
                    <div>
                      <i
                        class="bx bx-arrow-back"
                        style={{ marginRight: "15px" }}
                        onClick={() => {
                          bussinesCtx.refreshList();
                          setTimeout(() => {
                            navigate("/businessRules");
                          }, 100);
                        }}
                      ></i>
                      {props.name}
                    </div>
                    <div style={{ fontSize: "12px" }}>
                      Rule Id: {props.selectedBussinesRule.Id}
                      {/* <i
                        class="bx bx-copy-alt"
                        style={{ marginLeft: "10px" }}
                      ></i> */}
                    </div>
                  </div>
                </div>
                <div class="col-sm-8 ">
                  <div
                    class="card-body bussinesType"
                    style={{ float: "right" }}
                  >
                    {mode == 'Edit' && (<button
                      type="button"
                      class="btn btn-outline-primary bottom_right marginLeft"
                      onClick={saveBussinesRule}
                    >
                      Save Changes
                    </button>)}
                    
                    {mode == 'readOnly' && (<button
                      type="button"
                      class="btn btn-outline-primary bottom_right marginLeft"
                      onClick={()=> {setMode("Edit")}}
                    >
                      Edit
                    </button>)}
                    
                    {/* <button
                      type="button"
                      class="btn btn-outline-primary bottom_right marginLeft"
                      onClick={() => {
                        // props.setAddNewModal(true);
                      }}
                    >
                      Export
                    </button> */}
                    {/* <button
                      type="button"
                      class="btn btn-outline-primary bottom_right marginLeft"
                      onClick={() => {
                        // props.setAddNewModal(true);
                      }}
                    >
                      Delete
                    </button> */}
                  </div>
                </div>
              </div>
              <hr />
              <div class="row">
                <div
                  class="col-sm-4 fontSize14"
                  style={{ paddingLeft: "35px" }}
                >
                  <div>
                    <i class="bx bx-power-off iconMargin"></i>
                    <span style={{ marginRight: "20px" }}>Status:</span>
                    {/* <span
                      style={{
                        color:
                          props.selectedVersion.Status == "Active"
                            ? "#0ec80e"
                            : "#000000",
                        fontWeight: "bold",
                      }}
                    >
                      {props.selectedVersion && props.selectedVersion.Status}
                    </span> */}

                    <select
                      class="form-select form-select-display"
                      id="exampleFormControlSelect1"
                      aria-label="Default select example"
                      style={{ width: "62%" }}
                      onChange={(e) => {
                        setStatus(e.target.value);
                      }}
                      disabled = {mode == 'readOnly'}
                    >
                      <option selected>Select an option</option>
                      <option value="Active" selected={status === "Active"}>
                        Active
                      </option>
                      <option value="Inactive" selected={status === "Inactive"}>
                        Inactive
                      </option>
                      <option value="Disable" selected={status === "Disable"}>
                        Disable
                      </option>
                    </select>
                  </div>

                  <div style={{ marginTop: "20px" }}>
                    <div>
                      <i class="bx bxs-calendar iconMargin"></i>
                      Created:
                    </div>
                    <div>
                      <span style={{ fontSize: "13px", paddingLeft: "20px" }}>
                        {props.selectedVersion && props.selectedVersion.Created}
                      </span>
                    </div>
                  </div>
                </div>

                <div class="col-sm-2 fontSize14">
                  <div>
                    <i class="bx bx-power-off iconMargin"></i>
                    Version:{" "}
                    <span
                      style={{
                        color:
                          props.selectedVersion.Status == "Active"
                            ? "#0ec80e"
                            : "#000000",
                        fontWeight: "bold",
                      }}
                    >
                      {props.selectedVersion && props.selectedVersion.Id}
                    </span>
                  </div>

                  <div style={{ marginTop: "20px" }}>
                    <div>
                      <i class="bx bxs-calendar iconMargin"></i>
                      Last updated:
                    </div>
                    <div>
                      <span style={{ fontSize: "13px", paddingLeft: "20px" }}>
                        {props.selectedVersion &&
                          props.selectedVersion.Last_Updated}
                      </span>
                    </div>
                  </div>
                </div>

                {/* <div class="col-sm-2 fontSize14">
                  <i class="bx bxs-purchase-tag-alt"></i>
                  Tags  
                  <div>
                    <span
                      className="existingNewBussinesRuleTag"
                      style={{ background: "orange", color: "white" }}
                    >
                      Tag 1
                    </span>
                    <span
                      className="existingNewBussinesRuleTag"
                      style={{ background: "red", color: "white" }}
                    >
                      Tag 1
                    </span>
                    <span className="addNewBussinesRuleTag">+ Add Tag</span>
                  </div>
                </div> */}
                <div class="col-sm-6 fontSize14">
                  <i class="bx bxs-edit-alt"></i> Notes
                  <textarea
                    style={{ width: "95%" }}
                    onChange={(e) => {
                      setDescription(e.target.value);
                    }}
                    disabled = {mode == 'readOnly'}
                  >
                    {props.selectedVersion.Description}
                  </textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditBussinesRuleInfoArea;
