import { useLocation } from "react-router-dom";
import Header from "../../Header/Header.component";
import { useEffect, useContext, useState } from "react";
import { BussinesRulesContext } from "../../BussinesRulesContext/BussinesRulesContext.component";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BadDataQualityRows from "./BadDataQualityRows.component";

const EditDataQualityRule = () => {
  const location = useLocation();
  const bussinesCtx = useContext(BussinesRulesContext);
  const [selectedDataQualityRule, setSelectedDataQualityRule] = useState({});
  const [results, setResults] = useState("");
  const [last_executed, setLast_executed] = useState("");
  const [query, setQuery] = useState("");
  const [data, setData] = useState([]);
  const [rows, setRows] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    let _allDataQualityRules = [...bussinesCtx.dataQualityRules];
    let _selectedDataQualityRule = _allDataQualityRules.filter(
      (rule) => location.state.dataQualityRuleId == rule.Id
    );
    if (_selectedDataQualityRule?.length) {
      _selectedDataQualityRule = _selectedDataQualityRule[0];
      setSelectedDataQualityRule(_selectedDataQualityRule);
    }
    setQuery(selectedDataQualityRule.Query);
    setLast_executed(selectedDataQualityRule.Last_Executed);
    setResults(selectedDataQualityRule.Results);
  }, [selectedDataQualityRule]);

  const saveDataQualityRule = () => {
    fetch("/dataQualityRules", {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Id: selectedDataQualityRule.Id,
        Business_Rule_id: selectedDataQualityRule.Business_Rule_id,
        Query: query || null,
        Results: results,
        Last_Executed: last_executed,
      }),
    })
      .then((res) => res.json())
      .then(
        (res) => {
          toast.success("Changed Succesfully", {
            position: toast.POSITION.TOP_CENTER,
          });
        },
        (error) => {
          alert("Failed");
        }
      );
  };

  const executeDataQualityRule = () => {
    fetch(
      "/executeDataQualityRuleQuery/" +
        selectedDataQualityRule.Id
    )
      .then((response) => response.json())
      .then(
        (data) => {
          if (!Array.isArray(data)){
            toast.error("Incorrect Query!", {
              position: toast.POSITION.TOP_CENTER,
            });
          }
          else{
            toast.success("Query Executed Succesfully", {
              position: toast.POSITION.TOP_CENTER,
            });
            setData(data);
            setRows(data[0]["NumAllRows"]);
            if (data[0]["NumAllRows"] !== 0){
              setResults(100 - (data.length / data[0]["NumAllRows"]) * 100);
            }
            else{
              setResults(100);
            }
          }
        }
      );

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    today = yyyy + "-" + mm + "-" + dd;
    setLast_executed(today);
    setQuery(query);
  };

  let columns = [];
  let columnsList = [];
  if (data.length > 0) {
    columns = Object.keys(data[0]);
    for (let c of columns) {
      if (c !== "NumAllRows")
        columnsList.push({
          field: c,
          headerText: c,
          width: "60",
          visible: true,
          enableFilterSearch: true,
        });
    }
  }

  const columnsList2 = [
    {
      field: "customer_code",
      headerText: "customer_code",
      width: "60",
      visible: false,
      enableFilterSearch: true,
    },
    {
      field: "first_name",
      headerText: "first_name",
      width: "150",
      visible: true,
      enableFilterSearch: true,
    },
    {
      field: "last_name",
      headerText: "last_name",
      width: "150",
      visible: true,
      enableFilterSearch: true,
    },
    {
      field: "customer_id",
      headerText: "customer_id",
      width: "150",
      visible: true,
      enableFilterSearch: true,
      columnFilter: { type: "CheckBox" },
    },
    {
      field: "age",
      headerText: "age",
      width: "150",
      visible: true,
      enableFilterSearch: true,
      columnFilter: { type: "CheckBox" },
    },
    {
      field: "gender",
      headerText: "gender",
      width: "150",
      visible: true,
      enableFilterSearch: true,
    },
    {
      field: "profile",
      headerText: "profile",
      width: "150",
      visible: true,
      enableFilterSearch: true,
    },
  ];

  return (
    <>
      <Header
        title={`Data Quality Rule: ${selectedDataQualityRule.Id || "null"}`}
        left="40%"
      />
      <div className="container-xxl flex-grow-1 container-p-y">
        {+selectedDataQualityRule.Id > 0 && (
          <div class="row">
            <div class="col-lg-12 mb-4 order-0">
              <div class="card">
                <div class="d-flex align-items-end row marginBottom">
                  <div class="col-sm-12">
                    <div class="row">
                      <div class="col-sm-4">
                        <div
                          class="card-body bussinesType"
                          style={{ textAlign: "left" }}
                        >
                          <div>
                            <i
                              class="bx bx-arrow-back"
                              style={{ marginRight: "15px" }}
                              onClick={() => {
                                bussinesCtx.refreshList();
                                setTimeout(() => {
                                  navigate("/dataQualityRules");
                                }, 100);
                              }}
                            ></i>
                            {selectedDataQualityRule.Id}
                          </div>
                          <div style={{ fontSize: "12px" }}>
                            Rule Id: {selectedDataQualityRule.Id}
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-8 ">
                        <div
                          class="card-body bussinesType"
                          style={{ float: "right" }}
                        >
                          <button
                            type="button"
                            class="btn btn-outline-primary bottom_right marginLeft"
                            onClick={saveDataQualityRule}
                          >
                            Save Changes
                          </button>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div class="row">
                      <div
                        class="col-sm-3 fontSize14"
                        style={{ paddingLeft: "50px" }}
                      >
                        <div style={{ marginTop: "20px" }}>
                          <div>
                            <i class="bx bxs-calendar iconMargin"></i>
                            Last Executed:
                          </div>
                          <div>
                            <span
                              style={{ fontSize: "13px", paddingLeft: "20px" }}
                            >
                              {selectedDataQualityRule &&
                                selectedDataQualityRule.Last_Executed}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div class="col-sm-6 fontSize14">
                        <i class="bx bxs-edit-alt"></i> Suggested Query
                        <textarea
                          style={{ width: "95%" }}
                          onChange={(e) => {
                            setQuery(e.target.value);
                          }}
                        >
                          {selectedDataQualityRule.Query}
                        </textarea>
                      </div>
                      <div class="col-sm-2 ">
                        <div
                          class="card-body bussinesType"
                          style={{ float: "right", paddingTop: "40px" }}
                        >
                          <button
                            type="button"
                            class="btn btn-outline-primary bottom_right marginLeft"
                            onClick={executeDataQualityRule}
                          >
                            Execute
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ToastContainer />
          </div>
        )}

        {data.length > 0 && (
          <div class="row">
            <div class="col-lg-12 mb-4 order-0">
              <div class="card">
                <div class="d-flex align-items-end row marginBottom">
                  <div class="col-sm-12">
                    <div class="row">
                      <div class="col-sm-12">
                        <div
                          class="card-body bussinesType"
                          style={{ textAlign: "left" }}
                        >
                          <div>
                            <span
                              // class="badge badge-center rounded-pill bg-label-primary"
                              style={{ marginLeft: "4px" }}
                            >
                              Results : {results} %
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />

                    <BadDataQualityRows columns={columnsList} data={data} />
                  </div>
                </div>
              </div>
            </div>

            {/* <div class="floating-container">
            <div class="floating-button">
              <i class="bx bxs-save"></i>
            </div>
            <div class="element-container"></div>
          </div> */}
          </div>
        )}
      </div>
    </>
  );
};

export default EditDataQualityRule;
