import MainGridWithoutEditDel from "../../Grid/MainGridWithoutEditDel.component";

const BadDataQualityRows = (props) => {
  return (
    <div class="table-responsive text-nowrap">
      <MainGridWithoutEditDel
        dataScr={props.data}
        columnsList={props.columns}
      />
    </div>
  );
};

export default BadDataQualityRows;
