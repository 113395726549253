import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { BussinesRulesContext } from "../../BussinesRulesContext/BussinesRulesContext.component";
const AddNewDataQualityRuleModal = (props) => {
  const bussinesCtx = useContext(BussinesRulesContext);
  const [assignedRule, setAssignedRule] = useState("0");
  const navigate = useNavigate();

  const addDataQualityRule = () => {
    props.setLoading(true)
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    today = yyyy + "-" + mm + "-" + dd;
    fetch("/dataQualityRules", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Business_Rule_id: assignedRule,
        Query: "Select",
        Results: 100.0,
        Last_Executed: today
      }),
    })
    .then((res) => res.json())
    .then(
      (res) => {
      },
      (error) => {
      }
    );

    props.setAddNewModal(false);
    setTimeout(() => {
      bussinesCtx.refreshList()
    }, 100);
  };


  return (
    <>
      <div id="BackDropModalLayout" />
      <div
        className="modal fade show"
        id="backDropModal"
        data-bs-backdrop="static"
        tabindex="-1"
        style={{ display: "block" }}
        aria-modal="true"
        role="dialog"
      >
        <div class="modal-dialog">
          <form class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="backDropModalTitle">
                Add new Data Quality Rule
              </h5>
              <button
                onClick={() => {
                  props.setAddNewModal(false);
                }}
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              {bussinesCtx.businessRulesItems.filter((r) => bussinesCtx.dataQualityRules.map((dq)=>dq.Business_Rule_id).includes(r.Id)).length < bussinesCtx.businessRulesItems.length
               && (<div class="row">
                <div class="col mb-3">
                  <label for="exampleFormControlSelect1" class="form-label">
                  Business Rules
                  </label>
                  <div>
                    <select
                      dis
                      class="form-select form-select-display"
                      id="exampleFormControlSelect1"
                      aria-label="Default select example"
                      onChange={(e) => {
                        setAssignedRule(e.target.value);
                      }}
                      style={{ width: "100%" }}
                    >
                      <option selected value="0">
                        Select an option
                      </option>
                      {bussinesCtx.businessRulesItems &&
                        bussinesCtx.businessRulesItems.filter((r) => !bussinesCtx.dataQualityRules.map((dq)=>dq.Business_Rule_id).includes(r.Id)).map((op) => {
                          return (
                            <option
                              selected={assignedRule === op.Id}
                              value={op.Id}
                            >
                              {op.Id}: {op.RuleName}
                            </option>
                          );
                        })}
                    </select>
                    {/* <select
                      class="form-select form-select-display"
                      id="exampleFormControlSelect1"
                      aria-label="Default select example"
                      onChange={(e) => {
                        setDataQualityDimensions(e.target.value);
                      }}
                      style={{ width: "50%" }}
                    >
                      <option selected>Select an option</option>
                      <option value="Completeness">Completeness</option>
                      <option value="Validity">Validity</option>
                      <option value="Consistency">Consistency</option>
                      <option value="Accuracy">Accuracy</option>
                      <option value="Uniqueness">Uniqueness</option>
                      <option value="Timeliness">Timeliness</option>
                    </select> */}

  
                  </div>
                </div>
              </div>)}
              {bussinesCtx.businessRulesItems.filter((r) => bussinesCtx.dataQualityRules.map((dq)=>dq.Business_Rule_id).includes(r.Id)).length >= bussinesCtx.businessRulesItems.length
                  && (<div class="container-xxl container-p-y centerWrapper">
                  <div class="misc-wrapper ">
                    <h2 class="mb-2 mx-2 centerText">All Business Rules have been assigned to a Data Quality Rule</h2>
                    <p class="mb-4 mx-2 centerText">
                      Please create more Business Rules to be able to create more Data Quality Rules!
                    </p>
                    <div class="mt-4">
                      <img
                        src="../static/assets/img/illustrations/Missing_Rule.png"
                        alt="girl-doing-yoga-light"
                        width="500"
                        class="img-fluid centerImage"
                        data-app-dark-img="illustrations/girl-doing-yoga-dark.png"
                        data-app-light-img="illustrations/girl-doing-yoga-light.png"
                      />
                    </div>
                  </div>
                </div>
                  )}
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-outline-secondary"
                data-bs-dismiss="modal"
                onClick={() => {
                  props.setAddNewModal(false);
                }}
              >
                Close
              </button>
              <button
                type="button"
                class="btn btn-primary"
                onClick={addDataQualityRule}
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddNewDataQualityRuleModal;
