import { Link, useNavigate } from "react-router-dom";

const DataQualityRuleItem = (props) => {
  const navigate = useNavigate();
  return (
    <div class="row ">
      <div class="col-lg-12 mb-4 order-0">
        <div class="card">
          <div class="row padding20">
            <div class="col-8" style={{ marginBottom: "100px" }}>
              <div class="displayInlineBlock" style={{ marginTop: "10px" }}>
                <i class="bx bx-code-alt bookIcon"></i>
              </div>
              <div class="displayInlineBlock">
                <div
                  style={{
                    position: "absolute",
                    top: "0px",
                    marginLeft: "5px",
                    marginTop: "10px",
                  }}
                >
                  Data Quality Rule: {props.RuleName}
                </div>
              </div>
              <div
                class="displayInlineBlock"
                style={{
                  position: "absolute",
                  top: "40px",
                  left: "56px",
                  fontSize: "13px",
                }}
              >
                Data Domain: {props.DataDomain}{" "}
              </div>

              <div
                class="displayInlineBlock"
                style={{
                  position: "absolute",
                  top: "60px",
                  left: "56px",
                  fontSize: "13px",
                }}
              >
                Dimension: {props.Dimension}{" "}
              </div>
            <div
                class="displayInlineBlock"
                style={{
                  position: "absolute",
                  top: "80px",
                  left: "56px",
                  fontSize: "13px",
                }}
              >
                Results: {props.dataQualityRuleItem.Results} {" "}
              </div>
              <div
                class="displayInlineBlock"
                style={{
                  position: "absolute",
                  top: "100px",
                  left: "56px",
                  fontSize: "13px",
                }}
              >
                Last Executed: {props.dataQualityRuleItem.Last_Executed}{" "}
              </div>
            </div>
            <div class="col-4 textAlingRightDq">
              <div>
                <button
                  type="button"
                  class="btn btn-outline-primary"
                  style={{ marginLeft: "10px" }}
                  onClick={() => {
                    navigate("/editDataQualityRule", {
                      state: {
                        dataQualityRuleId: props.dataQualityRuleItem.Id
                      },
                    });
                  }}
                >
                  <span style={{ marginRight: "5px" }}>Edit</span>
                  <i class="bx bx-copy-alt"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-outline-primary"
                  style={{ marginLeft: "10px" }}
                  onClick={() => {
                    props.onDeleteDataQualityRuleButtonClick(props.dataQualityRuleItem.Id);
                  }}
                >
                  <span style={{ marginRight: "5px" }}>Delete</span>
                  <i class="bx bx-copy-alt"></i>
                </button>
              </div>
            </div>
          </div>
          
          


        </div>
      </div>
    </div>
  );
};

export default DataQualityRuleItem;
