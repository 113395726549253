/**
 * @author Alexandros Andreou
 * @version 0.0.1
 */

const Footer = () => {
  return (
    <footer className="content-footer footer bg-footer-theme">
      <div className="container-xxl d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
        <div className="mb-2 mb-md-0">
          © Powered by
          <a
            href="https://artificient.org/"
            className="footer-link fw-bolder"
          >
            &nbsp; A. A. Artificient LTD
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
