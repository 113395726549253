/**
 * @author Alexandros Andreou
 * @version 0.0.1
 * MenuLabel Component
 */

const MenuLabel = (props) => {
  const { label } = props;
  return (
    <li className="menu-header small text-uppercase">
      <span className="menu-header-text">{label}</span>
    </li>
  );
};

export default MenuLabel;
