/**
 * @author Alexandros Andreou
 * @version 0.0.1
 * Page that will appear in case where a page's link is not existed
 */

const Error404 = (props) => {
  return (
    <div class="container-xxl container-p-y centerWrapper">
      <div class="misc-wrapper ">
        <h2 class="mb-2 mx-2 centerText">Under Maintenance!</h2>
        <p class="mb-4 mx-2 centerText">
          Sorry for the inconvenience but we're performing some maintenance at
          the moment
        </p>
        <div class="mt-4">
          <img
            src="../static/assets/img/illustrations/girl-doing-yoga-light.png"
            alt="girl-doing-yoga-light"
            width="500"
            class="img-fluid centerImage"
            data-app-dark-img="illustrations/girl-doing-yoga-dark.png"
            data-app-light-img="illustrations/girl-doing-yoga-light.png"
          />
        </div>
      </div>
    </div>
  );
};

export default Error404;
