import { useState, useEffect, useContext } from "react";
import { BussinesRulesContext } from "../../BussinesRulesContext/BussinesRulesContext.component";
const RulesVariableComponent = (props) => {
  console.log(props)
  const bussinesCtx = useContext(BussinesRulesContext);
  useEffect(() => {
    setCurrentRule(props.variable);
    setUneditedRule(props.variable);
  }, []);

  const [currentRule, setCurrentRule] = useState({
    Variable_id: 0,
    Operator: 0,
    Variable_Value: "",
  });

  const [uneditedRule, setUneditedRule] = useState({
    Variable_id: 0,
    Operator: 0,
    Variable_Value: "",
  });

  const [editMode, setEditMode] = useState(false);
  return (
    <>
      <div class="row" style={{ marginTop: "10px" }}>
        <div class="col-sm-4 fontSize14" style={{ paddingLeft: "35px" }}>
          <select
            dis
            class="form-select form-select-display"
            id="exampleFormControlSelect1"
            aria-label="Default select example"
            onChange={(e) => {
              setCurrentRule({ ...currentRule, Variable_id: e.target.value });
            }}
            style={{ width: "100%" }}
          >
            <option selected={currentRule.Variable_id === 0} value="0">
              Select an option
            </option>
            {bussinesCtx.variablesOptions &&
              bussinesCtx.variablesOptions.map((op) => {  
                return (
                  <option
                    selected={currentRule.Variable_id == op.Id}
                    value={op.Id}
                    tableId={op.tableId}
                  >
                    {op.Table_Name}/{op.Column_Name}
                  </option>
                );
              })}
          </select>
        </div>
        <div class="col-sm-2 fontSize14">
          <select
            class="form-select form-select-display"
            id="exampleFormControlSelect1"
            aria-label="Default select example"
            onChange={(e) => {
              setCurrentRule({ ...currentRule, Operator: e.target.value });
            }}
            style={{ width: "100%" }}
          >
            <option selected={currentRule.Operator === 0} value="0">
              Select an option
            </option>
            <option selected={currentRule.Operator === ">"} value=">">{`>`}</option>
            <option
              selected={currentRule.Operator === ">="}
              value=">="
            >{`>=`}</option>
            <option
              selected={currentRule.Operator === "<"}
              value="<>"
            >{`<`}</option>
            <option
              selected={currentRule.Operator === "<="}
              value="<="
            >{`<=`}</option>
            <option
              selected={currentRule.Operator === "=="}
              value="=="
            >{`==`}</option>
            <option
              selected={currentRule.Operator === "!="}
              value="!="
            >{`!=`}</option>
            <option
              selected={currentRule.Operator === "in"}
              value="in"
            >{`in`}</option>
            <option
              selected={currentRule.Operator === "not in"}
              value="not in"
            >{`not in`}</option>
            <option
              selected={currentRule.Operator === "like"}
              value="like"
            >{`like`}</option>
            <option
              selected={currentRule.Operator === "not like"}
              value="not like"
            >{`not like`}</option>
          </select>
        </div>
        <div class="col-sm-4 fontSize14">
          <input
            disabled={!editMode}
            type="text"
            class="form-control"
            id="basic-default-password12"
            aria-describedby="basic-default-password2"
            value={currentRule.Variable_Value}
            onChange={(e) => {
              setCurrentRule({ ...currentRule, Variable_Value: e.target.value });
            }}
          />
        </div>
        <div class="col-sm-2 fontSize14">
          {!editMode && (
            <button
              type="button"
              class="btn btn-outline-primary bottom_right marginLeft"
              onClick={() => {
                setEditMode(true);
              }}
            >
              <i class="bx bx-edit-alt"></i>
            </button>
          )}

          {editMode && (
            <button
              type="button"
              class="btn btn-outline-primary bottom_right marginLeft"
              onClick={() => {
                setEditMode(false);
                props.saveRule(currentRule);
                setUneditedRule(currentRule);
              }}
            >
              <i class="bx bxs-save"></i>
            </button>
          )}
          {!editMode && (
            <button
              type="button"
              class="btn btn-outline-primary bottom_right marginLeft"
              onClick={() => {
                props.deleteRule(currentRule.Id);
              }}
            >
              <i class="bx bxs-trash-alt"></i>
            </button>
          )}
          {editMode && (
            <button
              type="button"
              class="btn btn-outline-primary bottom_right marginLeft"
              onClick={() => {
                setEditMode(false);
                setCurrentRule(uneditedRule);
              }}
            >
              <i class="bx bx-undo"></i>
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default RulesVariableComponent;
