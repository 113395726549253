/**
 * @author Alexandros Andreou
 * @version 0.0.1
 * Employees Page
 */

import { useState, useEffect } from "react";
import ConfirmationModal from "../../Modal/Confirmation.modal";
import Search from "../../Search/Search.component";
import EmployeeModal from "../Employees/Employees.modal";
import MainGrid from "../../Grid/MainGrid.component";
import Header from "../../Header/Header.component";
import LoadingSpinner from "../../isLoadingSpinner/LoadingSpinner";


const Employees = (props) => {
  const [employees, setEmployees] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [isLoading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState("");
  const [confirmationModal, setConfirmationModal] = useState(false);

  const [selectedEmployee, setSelectedEmployee] = useState({
    EmployeeId: "",
    EmployeeName: "",
    Department_id: "",
    DateOfJoining: ""
  });

  const [selectedDepartment, setSelectedDepartment] = useState({
    DepartmentId: "",
    DataDomain_id: "",
    DepartmentName: "",
  });

  useEffect(() => {
    setLoading(true)
    refreshList();
  }, []);

  const refreshList = () => {
    setLoading(true)
    const fetchReq1 = fetch(`/employee`).then((res) =>
      res.json()
    );

    const fetchReq2 = fetch(`/department`).then(
      (res) => res.json()
    );

    const allData = Promise.all([fetchReq1, fetchReq2]);
    allData.then((res) => {
      var [
        employees,
        departments
      ] = res;
      for (let employee of employees){
        for (let department of departments){
          if (employee.Department_id == department.DepartmentId)
          employee.DepartmentName = department.DepartmentName
        }
      }
    setEmployees(employees);
    setDepartments(departments);
    setLoading(false)
     });

  };

  const addEmployee = () => {
    setShowModal(true);
    setModalMode("Add");
    setSelectedEmployee({
      EmployeeId: 0,
      EmployeeName: "",
      Department_id: "",
      DateOfJoining: "",
    });
  };

  const editEmployee = (id) => {
    setShowModal(true);
    const employee = employees.filter((e) => e.EmployeeId === id);
    setModalMode("Edit");
    setSelectedEmployee({
      EmployeeId: employee[0].EmployeeId,
      EmployeeName: employee[0].EmployeeName,
      Department_id: employee[0].Department_id,
      DateOfJoining: employee[0].DateOfJoining,
    });
  };

  const deleteEmployeeMessage = (id) => {
    setConfirmationModal(true);
    setSelectedEmployee(() => {
      return {
        EmployeeId: id,
        EmployeeName: "",
        Department_id: "",
        DateOfJoining: ""
      };
    });
  };

  const modalSaveButtonHandler = (newEmployee) => {
    setShowModal(false);
    setSelectedEmployee(newEmployee);
    if (modalMode === "Edit") submitEditedEmployee(newEmployee);
    else submitNewEmployee(newEmployee);
  };

  const deleteEmployee = () => {
    setConfirmationModal(false);
    fetch("/employee/" + selectedEmployee.EmployeeId, {
      method: "DELETE",
      header: {
        Accept: "application/json",
        "Content-Type": "applciation/json",
      },
    }).then(() => {
      refreshList();
    });
  };

  const submitEditedEmployee = (newEmployee) => {
    fetch("/employee", {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        EmployeeId: newEmployee.EmployeeId,
        EmployeeName: newEmployee.EmployeeName,
        Department_id:  newEmployee.Department_id,
        DateOfJoining: newEmployee.DateOfJoining,
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          refreshList();
        },
        (error) => {
          alert("Failed");
        }
      );
  };

  const submitNewEmployee = (newEmployee) => {
    fetch("/employee", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        EmployeeId: null,
        EmployeeName: newEmployee.EmployeeName,
        Department_id:  newEmployee.Department_id,
        DateOfJoining: newEmployee.DateOfJoining,
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result === "Added Successfully!") {
            refreshList();
          } else {
            //error
          }
        },
        (error) => {
          alert("Failed");
        }
      );
  };

  const columnsList = [
    {
      field: "EmployeeId",
      headerText: "ID",
      width: "60",
      visible: false,
      enableFilterSearch: true
    },    
    {
      field: "Department_id",
      headerText: "Department Id",
      width: "60",
      visible: false,
      enableFilterSearch: true
    },
    {
      field: "DepartmentName",
      headerText: "Department Name",
      width: "120",
      visible: true,
      enableFilterSearch: true
    },
    {
      field: "EmployeeName",
      headerText: "Employee Name",
      width: "150",
      visible: true,
      enableFilterSearch: true
    },
    {
      field: "DateOfJoining",
      headerText: "Date Of Joining",
      width: "150",
      visible: true,
      enableFilterSearch: true
    }
  ]

  return (
    <>
      <Header title='Employees' />
      <div className="container-xxl flex-grow-1 container-p-y">
        <div className="row">
          <div className="col-lg-12 mb-12 order-0">
            <div class="card">
              {/* <h5 class="card-header">Employees</h5> */}
              <div class="table-responsive text-nowrap">
              <MainGrid
                  dataScr={employees}
                  onEdit={editEmployee}
                  onDelete={deleteEmployeeMessage}
                  columnsList={columnsList}
                />
              </div>
            </div>
          </div>
        </div>
        {showModal && (
          <EmployeeModal
            employee={selectedEmployee}
            title={modalMode}
            EmployeeName={selectedEmployee.EmployeeName}
            EmployeeId={selectedEmployee.EmployeeId}
            onClose={() => {
              setShowModal(false);
            }}
            onSave={modalSaveButtonHandler}
            departments = {departments}
            selectedEmployee = {selectedEmployee}
          />
        )}
        {confirmationModal && (
          <ConfirmationModal
            onConfirm={deleteEmployee}
            onClose={() => {
              setConfirmationModal(false);
            }}
          />
        )}

        <button
          type="button"
          class="btn btn-outline-primary addDeparmentButton"
          onClick={addEmployee}
        >
          Add Employee
        </button>
      </div>
      {isLoading && <LoadingSpinner />}
    </>
  );
};

export default Employees;
