import { useState, useEffect } from "react";

const OracleConnection = (props) => {
  const [mode, setMode] = useState("readOnly");
  const [isLocal, setIslocal] = useState(false);
  const [currentSettings, setCurrentSettings] = useState({
    Database_Type: "Oracle",
    Database_Name: "",
    Username: "",
    Password: "",
    DSN: "",
    Port: "",
    Encoding: "",
  });

  const [hidePassword, setHidePassword] = useState(true);

  const { selectedConnectionstring } = props;
  useEffect(() => {

    if (props.selectedConnectionstring) setCurrentSettingsAsProps();

    setMode("readOnly");
  }, [selectedConnectionstring]);

  const cancelEdit = () => {
    setCurrentSettingsAsProps();
    setMode("readOnly");
    setHidePassword(true);
  };

  const setCurrentSettingsAsProps = () => {

    setCurrentSettings({
      Id: selectedConnectionstring.Id,
      Database_Name: selectedConnectionstring.Database_Name,
      Database_Type: selectedConnectionstring.Database_Type,
      Username: selectedConnectionstring.Username,
      Password: selectedConnectionstring.Password,
      DSN: selectedConnectionstring.DSN,
      Port: selectedConnectionstring.Port,
      Encoding: selectedConnectionstring.Encoding,
    });
  };

  const saveEditedDepartment = () => {
    setMode("readOnly");
    props.submitEditedDatabaseConnection(currentSettings);
    setHidePassword(true);
  };

  return (
    <div class="row inputLayout">
      <div class="col-12">
        <div class="card col-12">
          <div class="row card-body demo-vertical-spacing2 demo-only-element">
            <div className="col-6">
              <div class="form-password-toggle">
                <label class="form-label" for="basic-default-password12">
                  Database Type
                </label>

                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    id="basic-default-password12"
                    aria-describedby="basic-default-password2"
                    disabled
                    value={currentSettings.Database_Type}
                    onChange={(e) => {
                      setCurrentSettings((prevState) => {
                        return {
                          ...prevState,
                          Database_Type: e.target.value,
                        };
                      });
                    }}
                  />
                  <i class="bx bx-chevron-down dropdownDatabaseTypeIcon"></i>
                </div>
              </div>

              <div class="form-password-toggle">
                <label class="form-label" for="basic-default-password12">
                  Database Name
                </label>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    id="basic-default-password12"
                    aria-describedby="basic-default-password2"
                    value={currentSettings.Database_Name}
                    disabled={mode == "readOnly"}
                    onChange={(e) => {
                      setCurrentSettings((prevState) => {
                        return {
                          ...prevState,
                          Database_Name: e.target.value,
                        };
                      });
                    }}
                  />
                </div>
              </div>

              <div class="form-password-toggle">
                <label class="form-label" for="basic-default-password12">
                  Username
                </label>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    id="basic-default-password12"
                    aria-describedby="basic-default-password2"
                    value={currentSettings.Username}
                    disabled={mode == "readOnly"}
                    onChange={(e) => {
                      setCurrentSettings((prevState) => {
                        return {
                          ...prevState,
                          Username: e.target.value,
                        };
                      });
                    }}
                  />
                </div>
              </div>

              <div class="form-password-toggle">
                <label class="form-label" for="basic-default-password12">
                  DSN
                </label>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    id="basic-default-password12"
                    aria-describedby="basic-default-password2"
                    value={currentSettings.DSN}
                    disabled={mode == "readOnly"}
                    onChange={(e) => {
                      setCurrentSettings((prevState) => {
                        return {
                          ...prevState,
                          DSN: e.target.value,
                        };
                      });
                    }}
                  />
                </div>
              </div>

              <div class="form-password-toggle">
                <label class="form-label" for="basic-default-password12">
                  Encoding
                </label>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    id="basic-default-password12"
                    aria-describedby="basic-default-password2"
                    value={currentSettings.Encoding}
                    disabled={mode == "readOnly"}
                    onChange={(e) => {
                      setCurrentSettings((prevState) => {
                        return {
                          ...prevState,
                          Encoding: e.target.value,
                        };
                      });
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="col-6">
              <div class="form-password-toggle">
                <label class="form-label" for="basic-default-password12">
                  Password
                </label>
                <div class="input-group">
                  <input
                    type={hidePassword ? "password" : "text"}
                    class="form-control"
                    id="basic-default-password12"
                    placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                    aria-describedby="basic-default-password2"
                    value={currentSettings.Password}
                    disabled={mode === "readOnly"}
                    onChange={(e) => {
                      setCurrentSettings((prevState) => {
                        return {
                          ...prevState,
                          Password: e.target.value,
                        };
                      });
                    }}
                  />
                  <span
                    id="basic-default-password2"
                    class="input-group-text cursor-pointer"
                    onClick={() => {
                      if (mode == "edit") setHidePassword(!hidePassword);
                    }}
                  >
                    <i
                      class={hidePassword ? "bx bx-compass" : "bx bxs-compass"}
                    ></i>
                  </span>
                </div>
              </div>

              <div class="form-password-toggle">
                <label class="form-label" for="basic-default-password12">
                  Port
                </label>
                <div class="input-group">
                  <input
                    type="number"
                    class="form-control"
                    id="basic-default-password12"
                    aria-describedby="basic-default-password2"
                    value={currentSettings.Port}
                    disabled={mode == "readOnly"}
                    onChange={(e) => {
                      setCurrentSettings((prevState) => {
                        return {
                          ...prevState,
                          Port: e.target.value,
                        };
                      });
                    }}
                  />
                </div>
              </div>

              <div className="actionsButtons">
                {mode == "readOnly" && (
                  <button
                    type="button"
                    class="btn btn-outline-primary bottom_right marginLeft"
                    onClick={() => setMode("edit")}
                  >
                    Edit
                  </button>
                )}
                {mode == "edit" && (
                  <button
                    type="button"
                    class="btn btn-outline-primary bottom_right marginLeft"
                    onClick={cancelEdit}
                  >
                    Cancel
                  </button>
                )}
                {mode == "edit" && (
                  <button
                    type="button"
                    class="btn btn-outline-primary bottom_right marginLeft"
                    onClick={saveEditedDepartment}
                  >
                    Save
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OracleConnection;
