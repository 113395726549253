import { useState } from "react";
import OverviewTab from "./OverviewTab.component";

const Overview = (props) => {
  const [activeTab, setActiveTab] = useState("Overview");
  return (
    <div className="row">
      <div className="col-lg-12 mb-12 order-0">
        <div class="card">
          <div class="row">
            <div className="col-4 col-xl-9">
              <h5 class="card-header">Overview</h5>
            </div>
            <div className="col-xl-3 col-8 dataProfilingDropdown">
              <select
                class="form-select form-select-display"
                id="exampleFormControlSelect1"
                aria-label="Default select example"
                onChange={(e) => {
                  props.changeSelectedTable(e.target.value);
                }}
                style={{ width: "170px" }}
              >
                <option selected value="0">
                  Select an option
                </option>
                {props.tables.map((table) => (
                  <option value={table.Id}>{table.Table_Name}</option>
                ))}
              </select>
            </div>
          </div>

          {!props.selectedTable.variablesData?.length &&
            props.selectedTable.Id > 0 && (
              <div
                className="noDataTitle"
                style={{ paddingLeft: "36px", paddingBottom: "35px" }}
              >
                No overview data
              </div>
            )}

          {props.selectedTable.Id !== 0 && props.overviewData && (
            <div class="table-responsive text-nowrap layoutPadding">
              <div
                className="row"
                style={{ padding: "20px", border: "1px solid #D9DEE3" }}
              >
                <div
                  className="col-12"
                  style={{ padding: "0px", marginBottom: "13px" }}
                >
                  <span
                    className={
                      activeTab === "Overview" ? "tabButtonActive" : "tabButton"
                    }
                    onClick={() => {
                      setActiveTab("Overview");
                    }}
                  >
                    Overview
                  </span>
                  {/* <span
                  className={
                    activeTab === "Alerts" ? "tabButtonActive" : "tabButton"
                  }
                  onClick={() => {
                    setrActiveTab("Alerts");
                  }}
                >
                  Alerts
                </span> */}
                </div>
                {activeTab === "Overview" && (
                  <OverviewTab overviewData={props.overviewData} />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Overview;
