/**
 * @author Alexandros Andreou
 * @version 0.0.1
 * Logo Component
 */
import logoPng from "../../img/mainLogo_blackFont.png";
import jQuery from "jquery";

const Logo = () => {

  const closeMenu = () => {
    jQuery('html').removeClass('layout-menu-expanded')
  }

  return (
    <div className="app-brand demo">
      <a href="/" class="app-brand-link">
        <img src={logoPng} alt="" width="209" height="156" />
      </a>
      <a
        onClick={closeMenu}
        class="layout-menu-toggle menu-link text-large ms-auto d-block d-xl-none"
      >
        <i class="bx bx-chevron-left bx-sm align-middle"></i>
      </a>
    </div>
  );
};

export default Logo;
