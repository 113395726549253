import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PricingScreen = () => {
  const [contactForm, setContactForm] = useState({
    fullName: "",
    companyName: "",
    emailAddress: "",
    phoneNumber: "",
    message: "",
  });

  const setDefualtValues = () => {
    setContactForm({
      fullName: "",
      companyName: "",
      emailAddress: "",
      phoneNumber: "",
      message: "",
    });
  };

  const submit = (e) => {
    e.preventDefault();
    console.log(contactForm);
    fetch("/contact", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(contactForm),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result == "Success") {
          toast.success(`Thank you for your email! We will contact you as soon as possible.`, {
            position: toast.POSITION.TOP_CENTER,
          });
          setDefualtValues();
        } else {
          toast.error("Something went wrong, please check your inputs", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      });
  };

  return (
    <div class="container-xxl">
      <ToastContainer />
      <div class="authentication-wrapper authentication-basic container-p-y">
        <div class="authentication-inner" style={{ minWidth: "600px" }}>
          <div class="card">
            <div class="card-body">
              <h4 class="mb-2">Pricing</h4>
              <p class="mb-4">
                For more information on pricing, please contact us and we will
                provide you with a quote based on your requirements.
              </p>

              <div id="formAuthentication" class="mb-3" method="POST">
                <form onSubmit={submit}>
                  <div class="mb-3">
                    <label class="form-label" for="basic-icon-default-fullname">
                      Full Name
                    </label>
                    <div class="input-group input-group-merge">
                      <span
                        id="basic-icon-default-fullname2"
                        class="input-group-text"
                      >
                        <i class="bx bx-user"></i>
                      </span>
                      <input
                        required
                        type="text"
                        class="form-control"
                        id="basic-icon-default-fullname"
                        placeholder="Full Name"
                        aria-describedby="basic-icon-default-fullname2"
                        value={contactForm.fullName}
                        onChange={(e) => {
                          setContactForm((prevState) => {
                            return {
                              ...prevState,
                              fullName: e.target.value,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div class="mb-3">
                    <label class="form-label" for="basic-icon-default-company">
                      Company
                    </label>
                    <div class="input-group input-group-merge">
                      <span
                        id="basic-icon-default-company2"
                        class="input-group-text"
                      >
                        <i class="bx bx-buildings"></i>
                      </span>
                      <input
                        type="text"
                        id="basic-icon-default-company"
                        class="form-control"
                        placeholder="Company"
                        aria-describedby="basic-icon-default-company2"
                        value={contactForm.companyName}
                        onChange={(e) => {
                          setContactForm((prevState) => {
                            return {
                              ...prevState,
                              companyName: e.target.value,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div class="mb-3">
                    <label class="form-label" for="basic-icon-default-email">
                      Email
                    </label>
                    <div class="input-group input-group-merge">
                      <span class="input-group-text">
                        <i class="bx bx-envelope"></i>
                      </span>
                      <input
                        required
                        type="email"
                        id="basic-icon-default-email"
                        class="form-control"
                        placeholder="Email"
                        aria-describedby="basic-icon-default-email2"
                        value={contactForm.emailAddress}
                        onChange={(e) => {
                          setContactForm((prevState) => {
                            return {
                              ...prevState,
                              emailAddress: e.target.value,
                            };
                          });
                        }}
                      />
                    </div>
                    <div class="form-text"></div>
                  </div>
                  <div class="mb-3">
                    <label class="form-label" for="basic-icon-default-phone">
                      Phone No
                    </label>
                    <div class="input-group input-group-merge">
                      <span
                        id="basic-icon-default-phone2"
                        class="input-group-text"
                      >
                        <i class="bx bx-phone"></i>
                      </span>
                      <input
                        type="text"
                        id="basic-icon-default-phone"
                        class="form-control phone-mask"
                        placeholder="Phone"
                        aria-describedby="basic-icon-default-phone2"
                        value={contactForm.phoneNumber}
                        onChange={(e) => {
                          setContactForm((prevState) => {
                            return {
                              ...prevState,
                              phoneNumber: e.target.value,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div class="mb-3">
                    <label class="form-label" for="basic-icon-default-message">
                      Message
                    </label>
                    <div class="input-group input-group-merge">
                      <span
                        id="basic-icon-default-message2"
                        class="input-group-text"
                      >
                        <i class="bx bx-comment"></i>
                      </span>
                      <textarea
                        required
                        id="basic-icon-default-message"
                        class="form-control"
                        placeholder="Your message"
                        value={contactForm.message}
                        onChange={(e) => {
                          setContactForm((prevState) => {
                            return {
                              ...prevState,
                              message: e.target.value,
                            };
                          });
                        }}
                      ></textarea>
                    </div>
                  </div>
                  <button
                    type="submit"
                    class="btn btn-primary"
                    onSubmit={submit}
                  >
                    Send
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingScreen;
