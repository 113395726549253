import { useLocation } from "react-router-dom";
import Header from "../../Header/Header.component";
import EditBussinesRuleInfoArea from "./EditBussinesRuleInfoArea.component";
import EditBussinesRuleVariablesArea from "./EditBussinesRuleVariablesArea.component";
import { useEffect } from "react";
import { useContext } from "react";
import { BussinesRulesContext } from "../../BussinesRulesContext/BussinesRulesContext.component";
import { useState } from "react";

const EditBussineRule = (props) => {
  const location = useLocation();
  const [selectedBussinesRule, setSelectedBussinesRule] = useState({});
  const [selectedVersion, setSelectedVersion] = useState({});
  const bussinesCtx = useContext(BussinesRulesContext);

  useEffect(() => {
    let _allBussinesRules = [...bussinesCtx.businessRulesItems];
    let _selectedBussinesRule = _allBussinesRules.filter(
      (rule) => location.state.bussinesRuleId == rule.Id
    );
    if (_selectedBussinesRule?.length){
      _selectedBussinesRule = _selectedBussinesRule[0];
      let _selectedVersion = _selectedBussinesRule.Versions.filter((version) => version.Id == location.state.versionId)
      if (_selectedVersion?.length){
        _selectedVersion = _selectedVersion[0];
        _selectedBussinesRule.Versions = null;
        setSelectedBussinesRule(_selectedBussinesRule);
        setSelectedVersion(_selectedVersion);
      }
    }

    fetch(`/dataDictionary`)
      .then((res) => res.json())
      .then((result) => {
        bussinesCtx.setVariableOptions(result);
      });



  }, []);
  return (
    <>
      <Header
        title={`Bussiness Rule: ${selectedBussinesRule.RuleName || 'null'}`}
        left="40%"
      />
      <div className="container-xxl flex-grow-1 container-p-y">
       {+selectedBussinesRule.Id > 0 && <EditBussinesRuleInfoArea selectedBussinesRule={selectedBussinesRule} selectedVersion={selectedVersion}/> }
       {+selectedBussinesRule.Id > 0 &&  <EditBussinesRuleVariablesArea selectedBussinesRule={selectedBussinesRule}  selectedVersion={selectedVersion}/> }
      </div>
    </>
  );
};

export default EditBussineRule;

